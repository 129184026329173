import { Button, Grid, Paper, Typography, FormHelperText, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormikErrors, useFormikContext, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { FormValues } from "../../components/FormWizard";
import { TravelTimeSelect } from "./travelTime/TravelTimeSelect";
import { DoctorSelect } from "./DoctorSelect";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { useTranslation, Trans } from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//   paperStyle: {
//     padding: theme.spacing(2),
//   },
//   doctorSelectTitle: {
//     paddingTop: theme.spacing(2),
//   },
// }));
interface TravelTimeProps {
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Validation function */
  validationFn: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => FormikErrors<FormValues>;
}

/**
 * Selection of the doctor and arrival estimate time in slot reservation process.
 */
export const TravelTime = ({ goToNextPage, goToPreviousPage }: TravelTimeProps) => {
  const { errors, touched, values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  // const classes = useStyles();

  // Check for display of <DoctorSelect>.
  const displayDoctorSelect =
    values.treatment?.employeeSelectable &&
    values.treatment?.availableEmployees.filter((employee) => employee.openForRemoteCheckIn).length > 0
      ? true
      : false;

  const stepInfoText = t("slot.travelTimeDoctorSelectionTitle");

  useEffect(() => {
    // on inital load scrolls to top in case user has scrolled down the list of treatments on prev step
    window.scrollTo(0, 0);
    if (values.treatment?.availableEmployees?.length === 1) {
      setFieldValue("selectedEmployee", values.treatment?.availableEmployees[0], false);
    }
  }, []);

  return (
    <>
      <Box>
        <TitleNavigationHeader
          goToPreviousPage={goToPreviousPage}
          isSlotReservation={true}
          stepInfoText={stepInfoText}
        />
        <Box py={2}>
          <Typography display="inline">{` ${t("patientData.treatment")}: `}</Typography>
          <Typography color="primary" display="inline">{` ${values.treatment?.name}`}</Typography>
        </Box>
      </Box>
      <Box
        component={Paper}
        // className={classes.paperStyle}
        p={2}
      >
        <Box>
          <Typography variant="h6">{t("travelTime.title")}</Typography>
        </Box>

        <Grid item container direction="row" spacing={1} alignItems="flex-end">
          <Trans i18nKey="travelTime.arrivalTime">
            <Typography gutterBottom>Ich kann den Termin ab</Typography>
            <TravelTimeSelect />
            <Typography gutterBottom>wahrnehmen.</Typography>
          </Trans>
        </Grid>

        <FormHelperText error>
          {errors.earliestArrivalTime && touched.earliestArrivalTime ? errors.earliestArrivalTime : ""}
        </FormHelperText>
        {values.treatment?.employeeSelectable && displayDoctorSelect && (
          <>
            <Box
              pt={2}
              // className={classes.doctorSelectTitle}
            >
              <Typography variant="h6">{t("doctorSelect.title")}</Typography>
            </Box>
            <Box>
              <DoctorSelect />
            </Box>
            <Box>
              {values.treatment?.availableEmployees.length === 1 && (
                <FormHelperText>{t("doctorSelect.defaultSelection")}</FormHelperText>
              )}
            </Box>
          </>
        )}
        <Grid item container justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary">
            OK
          </Button>
        </Grid>
      </Box>
    </>
  );
};
