import React, { useRef, useEffect } from "react";
import { Button, Typography, CircularProgress, Link, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Field, useFormikContext, Form } from "formik";
import { useSelector } from "react-redux";
import { FormValues } from "../../components/FormWizard";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import { getIsFetchingCodeSubmisson } from "../../modules/phoneVerification/selectors";
import { MaskedFormikTextField } from "../MaskedFormikTextField";

interface VerificationCodeInputProps {
  // Re-directs user to data input form with focus on phone field depending on isSlotReservation
  handleChangeNumber: () => void;
  // Handles verificaiton code reqest as the first step in phone verification process
  handleCodeRequest: () => void;
  // Server response 403 on code submission
  handleSubmit: () => void;
  wrongCode: boolean;
}

// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[1],
//     padding: theme.spacing(2),
//   },
//   containerItem: {
//     paddingBottom: theme.spacing(2),
//   },
//   formItem: {
//     padding: theme.spacing(2),
//   },
//   inputSize: {
//     fontSize: "2rem",
//   },
// }));

// Used for input validation
const inputRegex = /^[0-9][\s][0-9][\s][0-9][\s]-[\s][0-9][\s][0-9][\s][0-9]$/;

/**
 * Component validation fn, passed as a prop to Formik in FromWizard component.
 */
export const validatePhoneVerification = (t: TFunction) => (values: Pick<FormValues, "verificationCodeOTP">) => {
  const errors: { verificationCodeOTP?: string } = {};
  if (values.verificationCodeOTP === null || !inputRegex.test(values.verificationCodeOTP)) {
    errors.verificationCodeOTP = t("validation.phoneVerificationCode");
  }
  return errors;
};

export const VerificationCodeInput = ({
  handleChangeNumber,
  handleCodeRequest,
  handleSubmit,
  wrongCode,
}: VerificationCodeInputProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();

  // Data from previous steps stored and used from Formik.
  const {
    values: {
      clientAccountDetails: { phoneNumber },
    },
  } = useFormikContext<FormValues>();

  const listener = (evt: KeyboardEvent) => {
    if (evt.key === "Enter" || evt.key === "NumpadEnter") {
      handleSubmit();
    }
  };
  // ref for setting focus on code input field
  const verificationCodeInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    verificationCodeInputRef.current?.addEventListener("keydown", listener);
    return () => {
      verificationCodeInputRef.current?.removeEventListener("keydown", listener);
    };
  }, []);

  const parsedUserPhoneNumber = parsePhoneNumberFromString(phoneNumber ?? "", "AT")?.formatInternational() ?? "";
  const isFetchingCodeSubmission = useSelector(getIsFetchingCodeSubmisson);

  // specific properties for generic maskedFormikTextField component
  const maskSpecificProperties = {
    mask: "0 0 0{ - }0 0 0",
    sxFontSize: "2.5rem",
    sxWidth: "100%",
    autofocus: true,
  };

  return (
    <Box p={2} borderRadius={1} sx={{ boxShadow: 1, backgroundColor: "#fff" }}>
      <Box pb={2}>
        <Typography variant="h6">{t("phoneVerification.verificationCodeTitle")}</Typography>
      </Box>

      <Box pb={2}>
        <Typography variant="body2">
          {t("phoneVerification.verificationSMSSent")}
          {parsedUserPhoneNumber}.
        </Typography>
        {
          // Check to avoid double message
          !wrongCode && <Typography variant="body2">{t("phoneVerification.verificationSMSInput")}</Typography>
        }
      </Box>

      {wrongCode && (
        <Box pb={2}>
          <Typography variant="h6" color="error">
            {t("phoneVerification.wrongCodeTitle")}
          </Typography>
          <Typography variant="body2" color="error">
            {t("phoneVerification.wrongCodeMessage")}
          </Typography>
        </Box>
      )}

      <Box p={2}>
        <Field
          maskSpecificProperties={maskSpecificProperties}
          component={MaskedFormikTextField}
          fieldInputRef={verificationCodeInputRef}
          name="verificationCodeOTP"
          label={t("phoneVerification.verificationCode")}
          required
          placeholder={t("phoneVerification.placeholder")}
          variant="filled"
          id="verificationCode"
        />
      </Box>

      <Box pb={2}>
        <Typography display="inline" variant="body2">
          {t("phoneVerification.noCodeMessage")}{" "}
        </Typography>
        <Link href="#" onClick={handleCodeRequest} underline="always">
          {t("phoneVerification.reSendCode")}
        </Link>
      </Box>

      <Box pb={2}>
        <Typography display="inline" variant="body2">
          {t("phoneVerification.wrongNumber")}{" "}
        </Typography>
        <Link href="#" onClick={handleChangeNumber} underline="always">
          {t("phoneVerification.changeNumber")}
        </Link>
      </Box>

      <Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {isFetchingCodeSubmission ? <CircularProgress size={30} /> : t("phoneVerification.codeSubmission")}
        </Button>
      </Box>
    </Box>
  );
};
