import React from "react";
import { Grid, Typography, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormikErrors, useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { FormValues } from "../../components/FormWizard";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { useTranslation } from "react-i18next";
import { requestPhoneVerificationCode, resetVerificationCodeUUID } from "../../modules/phoneVerification/actions";
import { TFunction } from "i18next";
import { useLocation, useHistory } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import { getPhoneVerificationData } from "../../modules/phoneVerification/selectors";
import { ConfirmationCode } from "../../modules/phoneVerification/types";
import { green } from "@mui/material/colors";
import { VerificationCodeInput } from "./VerificationCodeInput";
import { VerificationCodeRequest } from "./VerificationCodeRequest";

interface PhoneVerificationProps {
  /** Flag for solt reservation process */
  isSlotReservation: boolean;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Validation function to be used by Formik. Never gets used inside of the component */
  validationFn: (
    values: Pick<FormValues, "verificationCodeOTP">
  ) => FormikErrors<Pick<FormValues, "verificationCodeOTP">>;
}

// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[1],
//     padding: theme.spacing(2),
//   },
//   root: {
//     justifyContent: "center",
//   },
//   headerContainer: {
//     paddingBottom: 40,
//   },
//   containerItem: {
//     paddingBottom: theme.spacing(2),
//   },
//   formItem: {
//     padding: theme.spacing(2),
//   },
//   inlineTitle: {
//     marginRight: theme.spacing(1),
//   },
//   confirmationMessage: {
//     color: green[700],
//   },
//   inputSize: {
//     fontSize: "2rem",
//   },
// }));

// Used for input validation
const inputRegex = /^[0-9][\s][0-9][\s][0-9][\s]-[\s][0-9][\s][0-9][\s][0-9]$/;

/**
 * Component validation fn, passed as a prop to Formik in FromWizard component.
 */
export const validatePhoneVerification = (t: TFunction) => (values: Pick<FormValues, "verificationCodeOTP">) => {
  const errors: { verificationCodeOTP?: string } = {};
  if (values.verificationCodeOTP === null || !inputRegex.test(values.verificationCodeOTP)) {
    errors.verificationCodeOTP = t("validation.phoneVerificationCode");
  }
  return errors;
};

/**
 * Last step in the form, where user confirms data and appointment/slot.
 * Verifying correct data input and privacy policy consent/COVID check.
 */
export const PhoneVerification = ({ goToPreviousPage, goToNextPage, isSlotReservation }: PhoneVerificationProps) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  // const location = useLocation();

  // Data from previous steps stored and used from Formik.
  const {
    values: {
      clientAccountDetails: { phoneNumber },
      treatment,
      clientAccountDetails,
      verificationCodeOTP,
    },
    handleSubmit,
    setFieldValue,
    setSubmitting,
  } = useFormikContext<FormValues>();

  // Server response to verificaiton code request && response code on verification code submission
  const { institutionPhoneNumber, verificationCodeUUID, codeConfirmation, codeSubmissionError, codeRequestError } =
    useSelector(getPhoneVerificationData);

  // Institution and user phone nmb in formatInternational format
  const parsedInstitutionPhoneNumber =
    parsePhoneNumberFromString(institutionPhoneNumber ?? "", "AT")?.formatInternational() ?? "";

  const parsedUserPhoneNumber = parsePhoneNumberFromString(phoneNumber ?? "", "AT")?.formatInternational() ?? "";

  const serverError = codeSubmissionError !== null || codeRequestError !== null;

  // Server response 204 on code submisson
  const codeConfirmed = codeConfirmation === ConfirmationCode.CONFIRMED;
  // Server response 403 on code submission
  const wrongCode = codeConfirmation === ConfirmationCode.UN_CONFIRMED;
  // If too many invalid codes sent by same ip, user gets blocked
  const blockedRequests = codeConfirmation === ConfirmationCode.BLOCKED;

  // const codeConfirmed = false;
  // const serverError = true;
  // const wrongCode = true;
  // const blockedRequests = true;

  // Handles verificaiton code reqest as the first step in phone verification process
  const handleCodeRequest = () => {
    dispatch(
      requestPhoneVerificationCode.request(
        {
          phoneNumber:
            parsePhoneNumberFromString(clientAccountDetails.phoneNumber, "AT")?.format("E.164").substring(1) || "",
          treatmentType: treatment?.publicId,
        },
        {
          setSubmitting: setSubmitting,
        }
      )
    );
  };

  // Re-directs user to data input form with focus on phone field depending on isSlotReservation
  const handleChangeNumber = () => {
    const orgUnitUUID = history.location?.pathname.split("/")[1];
    setFieldValue(verificationCodeOTP, "");
    dispatch(resetVerificationCodeUUID());
    if (isSlotReservation) {
      history.push(`/${orgUnitUUID}/reserve-slot?step=4&phoneFocus=true`);
    } else {
      history.push(`/${orgUnitUUID}/book-appointment?step=4&phoneFocus=true`);
    }
  };

  // Flag for no code request submission
  const noVerificationCode = verificationCodeUUID === null;

  // Header information that stays the same for entire verification process
  const stepInfoText = t("phoneVerification.stepInfoText");

  // Flag for display of regular interaction components without errors
  const errorFreeUserInteraction = !blockedRequests && !serverError && !codeConfirmed;

  // Conditional logic for value of displayed UI

  let displayedNode: React.ReactNode | null = null;
  // Verification code request UI with no server errors
  if (errorFreeUserInteraction && noVerificationCode) {
    displayedNode = (
      <VerificationCodeRequest handleChangeNumber={handleChangeNumber} handleCodeRequest={handleCodeRequest} />
    );
  }
  // Verification code input&submisson UI with no server errors
  else if (errorFreeUserInteraction && !noVerificationCode) {
    displayedNode = (
      <VerificationCodeInput
        handleChangeNumber={handleChangeNumber}
        handleCodeRequest={handleCodeRequest}
        handleSubmit={handleSubmit}
        wrongCode={wrongCode}
      />
    );
  }
  // UI about successful code confirmation, visible only if user goes back/forth with registartion steps
  else if (codeConfirmed) {
    displayedNode = (
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        p={2}
        sx={{
          backgroundColor: "#fff",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Box pb={2}>
          <Typography variant="h6">{t("phoneVerification.verificationCode")}</Typography>
        </Box>
        <Box pb={2}>
          <Typography
            variant="body1"
            sx={{
              color: green[700],
            }}
          >
            {t("phoneVerification.confirmationMessage")} {parsedUserPhoneNumber}
          </Typography>
        </Box>
      </Grid>
    );
  }
  // UI in case of server errors, either for code request or submission
  else if (serverError) {
    displayedNode = (
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        p={2}
        sx={{
          backgroundColor: "#fff",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Box pb={2}>
          <Typography variant="h6" color="error">
            {t("phoneVerification.serverErrorTitle")}
          </Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">{t("phoneVerification.serverErrorPart1")}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">
            {t("phoneVerification.serverErrorPart2", { phone: parsedInstitutionPhoneNumber })}
          </Typography>
        </Box>
      </Grid>
    );
  }
  // UI in case of blocked user over too many wrong code submissions
  else if (blockedRequests) {
    displayedNode = (
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        p={2}
        sx={{
          backgroundColor: "#fff",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Box pb={2}>
          <Typography variant="h6" color="error">
            {t("phoneVerification.blockedRequestTitle")}
          </Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">{t("phoneVerification.blockedRequestPart1")}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">
            {t("phoneVerification.blockedRequestPart2", { phone: parsedInstitutionPhoneNumber })}
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Box>
      {/** Header with navigation*/}
      <Box pb={2}>
        <TitleNavigationHeader
          goToPreviousPage={goToPreviousPage}
          nextStepHandler={goToNextPage}
          stepInfoText={stepInfoText}
          lastStep={!codeConfirmed}
          phoneVerification={true}
          serverError={serverError}
        />
      </Box>
      {/** UI displayed for various cases of interaction in code request/submission process */}
      {displayedNode}
    </Box>
  );
};
