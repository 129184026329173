import { Button, Grid, Paper, Typography, Skeleton, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { differenceInMinutes, format, parseISO } from "date-fns";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getWaitingQueueSlot } from "../../modules/waitingQueueSlots/selectors";
import { FormValues } from "../../components/FormWizard";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { Trans, useTranslation } from "react-i18next";
import { CheckInAvailabilityStatusCode } from "../../modules/waitingQueueSlots/types";
// const useStyles = makeStyles((theme) => ({
//   padding: { padding: theme.spacing(2) },
// }));

interface WaitTimeConfirmationProps {
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
}

/**
 * Confirmation of expected wait time, or warning message if slot is not available
 */
export const WaitTimeConfirmation = ({ goToNextPage, goToPreviousPage }: WaitTimeConfirmationProps) => {
  const { t } = useTranslation();
  // Data about slot status and availability fetched after selection of travel time
  const waitingQueueSlot = useSelector(getWaitingQueueSlot);
  const location = useLocation<{ waitTimeConfirmationDeltaError?: boolean }>();
  // const classes = useStyles();
  const {
    values: { treatment, earliestArrivalTime, selectedEmployee },
  } = useFormikContext<FormValues>();

  // Display skeleton if waitingQueueSlot has not already been fetched
  if (!waitingQueueSlot) {
    return (
      <Grid item container direction="column" component={Paper}>
        <Skeleton variant="rectangular" width="20%" height={10} />
      </Grid>
    );
  }
  const { checkInAvailabilityStatusCode, earliestStartTime } = waitingQueueSlot;
  // Display of the warning message based on server response on slot availability
  if (
    checkInAvailabilityStatusCode === CheckInAvailabilityStatusCode.REMOTE_CHECK_IN_CLOSED ||
    checkInAvailabilityStatusCode === CheckInAvailabilityStatusCode.NO_SLOT_AVAILABLE ||
    checkInAvailabilityStatusCode === CheckInAvailabilityStatusCode.OUT_OF_OPENING_HOURS
  ) {
    return (
      <Grid item container direction="column">
        <Grid
          item
          container
          direction="column"
          spacing={3}
          component={Paper}
          // className={classes.padding}
          p={4}
        >
          <Typography variant="h6">{t("slot.waitTimeConfirmation.noSlotAvailableTitle")}</Typography>
          <Typography>{t("slot.waitTimeConfirmation.noSlotAvailableText")}</Typography>
        </Grid>
      </Grid>
    );
  }

  const stepInfoTextError = t("slot.waitTimeConfirmation.stepInfoTextError");

  const stepInfoText = t("slot.waitTimeConfirmation.stepInfoText");

  // Currently left time to the start of the appointment
  const minutesToEarliestStartTime = differenceInMinutes(parseISO(earliestStartTime), new Date());

  const earliestStartTimeFormatted = format(parseISO(earliestStartTime), "HH:mm'h'");
  return (
    <>
      <Box>
        <TitleNavigationHeader
          goToPreviousPage={goToPreviousPage}
          isSlotReservation={true}
          stepInfoText={location.state?.waitTimeConfirmationDeltaError ? stepInfoTextError : stepInfoText}
          confirmationError={location.state?.waitTimeConfirmationDeltaError}
        />

        <Box py={2}>
          <Typography display="inline">{` ${t("patientData.treatment")}: `}</Typography>
          <Typography color="primary" display="inline">{` ${treatment?.name}`}</Typography>
        </Box>
        <Box pb={2}>
          <Typography display="inline">{` ${t("patientData.travelTime")}: `}</Typography>
          <Typography color="primary" display="inline">
            {earliestArrivalTime === "NOW"
              ? t("travelTime.value0")
              : ` ${format(new Date(earliestArrivalTime), "HH:mm'h'")}`}
          </Typography>
        </Box>

        <Box pb={2}>
          <Typography display="inline">{` ${t("patientData.selectedEmployee")}: `}</Typography>
          <Typography color="primary" display="inline">{` ${
            typeof selectedEmployee !== "string" && selectedEmployee?.name
              ? selectedEmployee?.name
              : t("doctorSelect.noSelection")
          }`}</Typography>
        </Box>
      </Box>
      <Box
        component={Paper}
        p={2}
        // className={classes.padding}
      >
        <Box>
          <Typography variant="h6">{t("slot.waitTimeConfirmation.timeEstimationTitle")}</Typography>
        </Box>
        <Box>
          <Typography>
            {/* {t("slot.waitTimeConfirmation.timeEstimationText", {
              time: format(parseISO(earliestStartTime), "HH:mm'h'"),
            })} */}
            <Trans i18nKey="slot.waitTimeConfirmation.timeEstimationText">
              Ihr {t("commonWords.termin")} findet voraussichtlich um{" "}
              <span style={{ fontSize: 24 }}>{{ time: earliestStartTimeFormatted }}</span>
              statt.
            </Trans>
          </Typography>
        </Box>
        <Grid item container justifyContent="flex-end">
          <Button variant="contained" color="primary" type="submit">
            OK
          </Button>
        </Grid>
      </Box>
    </>
  );
};
