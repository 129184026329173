import { all, spawn } from "redux-saga/effects";
import waitingQueueSlotSaga from "../modules/waitingQueueSlots/sagas";
import institutionDetailsAndTreatmentsSaga from "../modules/institution/sagas";
import fetchTimeSlotsSaga from "../modules/timeSlots/sagas";
import bookAppointmentSaga from "../modules/appointment/sagas";
import phoneVerificationSaga from "../modules/phoneVerification/sagas";

export default function* rootSaga() {
  yield all([
    spawn(waitingQueueSlotSaga),
    spawn(institutionDetailsAndTreatmentsSaga),
    spawn(fetchTimeSlotsSaga),
    spawn(bookAppointmentSaga),
    spawn(phoneVerificationSaga),
  ]);
}
