import React from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormValues } from "../FormWizard";
import { useTranslation } from "react-i18next";

// const useStyle = makeStyles((theme) => ({
//   formStyle: {
//     minWidth: 130,
//   },
// }));

/**
 * Form component for gender selection
 */
export const GenderSelect = ({ field: { name, onBlur, onChange, value }, form }: FieldProps<string, FormValues>) => {
  const errors = getIn(form.errors, name);
  const touched = getIn(form.touched, name);

  const { handleSubmit } = useFormikContext<FormValues>();

  // Selected value stored in Formik
  const keyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    switch (evt.key) {
      case "Enter":
        if (value !== "") handleSubmit();
        break;
      case "NumpadEnter":
        if (value !== "") handleSubmit();
        break;
      case "m":
        form.setFieldValue(name, "M");
        break;
      case "w":
        form.setFieldValue(name, "W");
        break;
      case "x":
        form.setFieldValue(name, "X");
        break;
      default:
        return;
    }
  };
  const { t } = useTranslation();
  return (
    <FormControl
      fullWidth
      error={errors && touched}
      required
      sx={{ minWidth: "130px" }}
      variant="filled"
      onKeyDown={keyDownHandler}
    >
      <InputLabel id="gender-label" htmlFor="gender">
        {t("patientData.gender")}
      </InputLabel>
      <Select
        labelId="gender-label"
        id="gender"
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{ "data-testid": "gender-select" }}
      >
        <MenuItem value="" disabled>
          {t("patientData.gender")}
        </MenuItem>
        <MenuItem value="M">{t("patientData.genderMale")}</MenuItem>
        <MenuItem value="W">{t("patientData.genderFemale")}</MenuItem>
        <MenuItem value="X">{t("patientData.genderNeutral")}</MenuItem>
      </Select>
      <FormHelperText error={errors && touched}>{errors && touched ? errors : " "}</FormHelperText>
    </FormControl>
  );
};
