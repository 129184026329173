import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./actions";
import { PhoneVerificationState } from "./types";

export type PhoneVerificationActions = ActionType<typeof actions>;

const initialState: PhoneVerificationState = {
  verificationCodeUUID: null,
  codeConfirmation: null,
  isFetching: {
    codeRequest: false,
    codeSubmission: false,
  },
  errorMessage: {
    codeRequestError: null,
    codeSubmissionError: null,
  },
};

export const verificationCodeUUID = (state = initialState.verificationCodeUUID, action: PhoneVerificationActions) => {
  switch (action.type) {
    case getType(actions.requestPhoneVerificationCode.success):
      return action.payload;
    case getType(actions.resetVerificationCodeUUID):
      return null;
    default:
      return state;
  }
};

/**
 * Server error for phone verification
 */
export const codeRequestError = (
  state = initialState.errorMessage.codeRequestError,
  action: PhoneVerificationActions
) => {
  switch (action.type) {
    case getType(actions.requestPhoneVerificationCode.failure):
      return action.payload;
    case getType(actions.requestPhoneVerificationCode.request):
    case getType(actions.requestPhoneVerificationCode.success):
      return null;
    default:
      return state;
  }
};

/**
 * Indicates submission of phone verification
 */
export const codeRequestFetching = (state = initialState.isFetching.codeRequest, action: PhoneVerificationActions) => {
  switch (action.type) {
    case getType(actions.requestPhoneVerificationCode.request):
      return true;
    case getType(actions.requestPhoneVerificationCode.success):
    case getType(actions.requestPhoneVerificationCode.failure):
      return false;
    default:
      return state;
  }
};

export const codeConfirmation = (state = initialState.codeConfirmation, action: PhoneVerificationActions) => {
  switch (action.type) {
    case getType(actions.submitVerificationCode.success):
      return action.payload;
    default:
      return state;
  }
};

export const codeConfirmationError = (
  state = initialState.errorMessage.codeSubmissionError,
  action: PhoneVerificationActions
) => {
  switch (action.type) {
    case getType(actions.submitVerificationCode.failure):
      return action.payload;
    case getType(actions.submitVerificationCode.request):
    case getType(actions.submitVerificationCode.success):
      return null;
    default:
      return state;
  }
};

export const codeConfirmationIsFetching = (
  state = initialState.isFetching.codeSubmission,
  action: PhoneVerificationActions
) => {
  switch (action.type) {
    case getType(actions.submitVerificationCode.request):
      return true;
    case getType(actions.submitVerificationCode.success):
    case getType(actions.submitVerificationCode.failure):
      return false;
    default:
      return state;
  }
};

export default combineReducers<PhoneVerificationState, PhoneVerificationActions>({
  verificationCodeUUID,
  codeConfirmation,
  errorMessage: combineReducers({
    codeRequestError,
    codeSubmissionError: codeConfirmationError,
  }),
  isFetching: combineReducers({
    codeRequest: codeRequestFetching,
    codeSubmission: codeConfirmationIsFetching,
  }),
});
