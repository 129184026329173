import { FormControl, Box, InputLabel, MenuItem, Select, FormHelperText, SelectChangeEvent } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { useFormikContext, useField } from "formik";
import React from "react";
import { FormValues } from "../../components/FormWizard";
import { useTranslation } from "react-i18next";
import { AvailableEmployee } from "../../modules/institution/types";

// const useStyle = makeStyles((theme) => ({
//   formControl: {
//     minWidth: 200,
//   },
// }));

/**
 * Used for employee selection in slot reservation process / TravleTime component.
 */
export const DoctorSelect = () => {
  const { t } = useTranslation();
  const {
    values: { treatment, selectedEmployee, slotProposal },
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<FormValues>();

  const [field, meta] = useField({ name: "selectedEmployee" });
  // const classes = useStyle();

  // Filtering employees opened for remote check in
  const availableEmployeesWithRemoteCheckIn = treatment?.availableEmployees.filter(
    (employee) => employee.openForRemoteCheckIn
  );

  /**
   * employee change handler storing value in the Formik without validation
   * @param event by default has value of "none"
   */

  const handleEmployeeChange = (event: SelectChangeEvent<string | AvailableEmployee | null>) => {
    const selectedEmployee = event.target.value as string;
    setFieldValue("selectedEmployee", selectedEmployee === "All" ? null : event.target.value);
  };

  return (
    <>
      <Box>
        <FormControl
          variant="filled"
          error={meta.error !== undefined && meta.touched}
          // className={classes.formControl}
          sx={{
            minWidth: "200px",
          }}
          size="small"
        >
          <InputLabel id="employee-select-label" htmlFor="employee-select">
            {t("doctorSelect.label")}
          </InputLabel>
          <Select
            disabled={treatment?.availableEmployees.length === 1}
            value={
              treatment?.availableEmployees.length === 1
                ? treatment?.availableEmployees[0]
                : selectedEmployee === "All"
                ? null
                : selectedEmployee
            }
            name={field.name}
            onChange={handleEmployeeChange}
            onBlur={field.onBlur}
            labelId="employee-select-label"
            id="employee-select"
          >
            <MenuItem value="none">{t("doctorSelect.noSelection")}</MenuItem>
            {availableEmployeesWithRemoteCheckIn?.map((employee, index) => (
              <MenuItem value={employee as any} key={index}>
                {employee.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{meta.error && meta.touched ? meta.error : " "}</FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};
