import { AxiosInstance } from "axios";
import { PhoneVerificationRequest, PhoneVerificationSubmissionRequest } from "./types";
import { baseURL } from "../services/api";

export const phoneVerificationAPI = (api: AxiosInstance) => ({
  phoneVerification: (phoneVerificationRequest: PhoneVerificationRequest) =>
    api.post(`${baseURL}/phone-verification`, phoneVerificationRequest),
});

export const codeSubmissionAPI = (api: AxiosInstance) => ({
  codeSubmission: (codeSubmissionRequest: PhoneVerificationSubmissionRequest) =>
    api.post(`${baseURL}/phone-verification/verify`, codeSubmissionRequest),
});
