import { Container, CssBaseline, Grid, responsiveFontSizes, ThemeProvider } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, { Suspense } from "react";
import { Footer } from "./branding/vanilla/Footer";
import { TopBar } from "./branding/vanilla/TopBar";
import { Routes } from "./branding/vanilla/Routes";
import { muiTheme } from "./branding/vanilla/theme";
import "moment/locale/de-at";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     flex: "1 0 100%",
//     flexDirection: "column",
//     minHeight: "calc(100vh - 64px)"
//   },
//   headerFooter: {
//     flex: "initial",
//   },
// }));

const theme = responsiveFontSizes(muiTheme);

const App = () => {
  // const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback="Loading...">
        <Grid container direction="column" wrap="nowrap">
          <CssBaseline />
          <Grid
            item
            sx={{
              flex: "initial",
            }}
          >
            <TopBar />
          </Grid>
          <Container
            maxWidth="md"
            sx={{
              display: "flex",
              flex: "1 0 100%",
              flexDirection: "column",
              minHeight: "calc(100vh - 64px)",
            }}
          >
            <Routes />
          </Container>
          <Grid
            item
            sx={{
              flex: "initial",
            }}
          >
            <Footer />
          </Grid>
        </Grid>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
