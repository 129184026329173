import { InstitutionDetailsState } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { combineReducers } from "redux";
import * as actions from "./actions";

type InstitutionDetailsActions = ActionType<typeof actions>;

export const initialState: InstitutionDetailsState = {
  error: null,
  institutionDetails: null,
  isFetching: false,
  lastFetch: null,
};

/**
 * Error description on institution details fetch
 */
export const error = (state = initialState.error, action: InstitutionDetailsActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.failure):
      return action.payload;
    case getType(actions.fetchInstitutionDetailsAndTreatments.request):
      return null;
    default:
      return state;
  }
};

/**
 * Details about institution based on orgUnitUUID from URL parmas.
 */
export const institutionDetails = (state = initialState.institutionDetails, action: InstitutionDetailsActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.success):
      return action.payload.institutionDetails;
    default:
      return state;
  }
};

/**
 * Relevant for loading spinner display on SlotAppointmentFork
 */
export const isFetching = (state = initialState.isFetching, action: InstitutionDetailsActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.request):
      return true;
    case getType(actions.fetchInstitutionDetailsAndTreatments.failure):
    case getType(actions.fetchInstitutionDetailsAndTreatments.success):
      return false;
    default:
      return state;
  }
};

/**
 * Upon successful request, time stamp created.
 * I don't think it's ever really used.
 */
export const lastFetch = (state = initialState.lastFetch, action: InstitutionDetailsActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.success):
      return new Date().getTime();
    default:
      return state;
  }
};

export default combineReducers({
  error,
  institutionDetails,
  isFetching,
  lastFetch,
});
