import { Grid, IconButton, Divider, LinearProgress } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import SwipeableViews from "react-swipeable-views";
import { WeekDaysHeader } from "../dateTimePicker/WeekDaysHeader";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Moment } from "moment";
import { getEndOfTimeframeWithoutSlots } from "../../../modules/timeSlots/selectors";
import { setEndOfTimeframeWithoutSlots } from "../../../modules/timeSlots/actions";
import { useDispatch, useSelector } from "react-redux";

// const useStyle = makeStyles((theme) => ({
//   gridFlex: {
//     flex: "0 0 40px",
//   },
//   iconBttn: {
//     paddingLeft: theme.spacing(1),
//   },
//   swipableViews: {
//     flex: 1,
//   },
// }));
interface DateTimePickerHeaderProps {
  /** Double array of all weeks in range and respective days */
  headerDatesFromMaxWeeksInFuture: Moment[][];
  /** Indicator for data fetching */
  isFetchingSlots: boolean;
  /** Indicator for last week of the range */
  isLastWeekOfSelection: boolean;
  /** Indicates the end of range is displayed */
  nextWeekButtonDisabled: boolean;
  /** Updates startOfSelectionWeek triggering data fetch for selected range  */
  nextWeekHandler: () => void;
  /** Indicates beginning of the range is displayed */
  prevWeekButtonDisabled: boolean;
  /** Updates startOfSelectionWeek triggering data fetch for selected range  */
  prevWeekHandler: () => void;
  /** Represents current week in week range.
   * Controls SwipableViews diplay.
   */
  swipeIndex: number;
  /** Indicates week in selected range has no data to display*/
  weekHasNoSlots: boolean;
}

/**
 * Display week dates for the selected week of the range, wrapped in SwipeableViews.
 * Week that should be displayed in SwipableViews is set with swipeIndex prop.
 */
export const DateTimePickerHeader = ({
  prevWeekButtonDisabled,
  nextWeekButtonDisabled,
  isFetchingSlots,
  isLastWeekOfSelection,
  swipeIndex,
  headerDatesFromMaxWeeksInFuture,
  weekHasNoSlots,
  prevWeekHandler,
  nextWeekHandler,
}: DateTimePickerHeaderProps) => {
  const dispatch = useDispatch();

  // const [isNotInitialMount, setIsNotInitialMount] = useState(false);

  // const hasReachedEndOfTimeframeWithoutSlots = useSelector(getEndOfTimeframeWithoutSlots);

  // useEffect(() => {
  //   if (isLastWeekOfSelection && weekHasNoSlots) {
  //     dispatch(setEndOfTimeframeWithoutSlots(true));
  //   }

  //   if (weekHasNoSlots && isNotInitialMount && !hasReachedEndOfTimeframeWithoutSlots) {
  //     nextWeekHandler();
  //   }
  //   setIsNotInitialMount(true);
  // }, [weekHasNoSlots]);

  return (
    <header style={{ display: "flex" }}>
      <Grid container direction="row" wrap="nowrap" justifyContent="center">
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          // className={classes.gridFlex}
          sx={{
            flex: "0 0 40px",
          }}
        >
          <IconButton
            size="small"
            onClick={prevWeekHandler}
            disabled={prevWeekButtonDisabled || isFetchingSlots}
            // className={classes.iconBttn}
            sx={{
              paddingLeft: 1,
            }}
            data-testid="backTimeRangeButton"
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Grid>

        <SwipeableViews
          resistance={true}
          index={swipeIndex}
          // className={classes.swipableViews}
          style={{
            flex: "1",
          }}
        >
          {headerDatesFromMaxWeeksInFuture.map((week, index) => {
            return (
              // Each Grid element in SwipableViews consists of days of weeks and is controlled with swipeIndex
              <Grid item container wrap="nowrap" key={index}>
                {week.map((dayOfWeek, index) => (
                  <WeekDaysHeader dayOfWeek={dayOfWeek} key={index} weekHasNoSlots={weekHasNoSlots} />
                ))}
              </Grid>
            );
          })}
        </SwipeableViews>

        <Grid
          item
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="center"
          // className={classes.gridFlex}
          sx={{
            flex: "0 0 40px",
          }}
        >
          <IconButton size="small" onClick={nextWeekHandler} disabled={nextWeekButtonDisabled || isFetchingSlots}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>

      {isFetchingSlots ? <LinearProgress /> : <Divider />}
    </header>
  );
};
