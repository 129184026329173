import { TextField } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { ErrorMessage, FieldProps, getIn } from "formik";
import { AsYouType } from "libphonenumber-js/mobile";
import React, { useState } from "react";

// const useStyles = makeStyles((theme) => ({
//   errorMessage: {
//     width: "100%",
//   },
// }));

interface IProps extends FieldProps {}

/**
 * Helper component hooking up Formik field props to the material-ui TextField component
 * @param fieldProps
 */
export const PhoneFormikTextFieldComponent = ({
  field,
  form: { errors, touched, setFieldValue, values },
  ...props
}: IProps) => {
  // const classes = useStyles();
  const fieldErrors = getIn(errors, field.name);
  const fieldTouched = getIn(touched, field.name);

  const [phoneNumber, setPhoneNumber] = useState(new AsYouType("AT").input(values.clientAccountDetails.phoneNumber));

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    const formatter = new AsYouType("AT");
    const input = formatter.input(evt.target.value);
    setPhoneNumber(input);
    setFieldValue(field.name, input.replace(/\s/g, ""));
  };

  return (
    <TextField
      multiline={field.name === "clientAccountDetails.comment"}
      {...field}
      {...props}
      error={fieldTouched && fieldErrors !== undefined}
      sx={{
        width: "100%",
      }}
      // FormHelperTextProps={{ classes: { root: classes.errorMessage } }}
      helperText={<ErrorMessage name={field.name} />}
      onChange={changeHandler}
      value={phoneNumber}
    />
  );
};
