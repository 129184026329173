import React from "react";
import { useField } from "formik";
import { FormControlLabel, Typography, Link, Checkbox, FormControl, FormHelperText } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { useTranslation, Trans } from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     marginTop: theme.spacing(2),
//   },
//   formHelperText: {
//     marginBottom: theme.spacing(2),
//     marginLeft: theme.spacing(4),
//   },
// }));

export const PrivacyPolicyConsent = () => {
  const [field, meta] = useField({ name: "acceptedPrivacyPolicy" });
  // const classes = useStyles();

  const { t } = useTranslation();

  const LinkNode = (
    <Typography>
      <Trans i18nKey="confirmation.privacyPolicy">
        Ich habe die{" "}
        {
          <Link href="/privacy-policy" target="_blank">
            Datenschutzbestimmungen
          </Link>
        }{" "}
        gelesen und akzeptiert.",
      </Trans>
    </Typography>
  );

  return (
    <FormControl
      error={meta.error !== undefined && meta.touched}
      sx={{
        marginTop: 2,
      }}
    >
      <FormControlLabel
        label={LinkNode}
        control={<Checkbox checked={meta.value} onChange={field.onChange} name={field.name} />}
        data-testid="privacyPolicyCheckbox"
      />
      <FormHelperText
        sx={{
          marginLeft: 4,
          paddingBottom: 2,
        }}
      >
        {meta.error && meta.touched ? meta.error : " "}
      </FormHelperText>
    </FormControl>
  );
};
