import { Button, Grid, Typography, CircularProgress, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormikErrors, useFormikContext } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormValues } from "../../components/FormWizard";
import { PrivacyPolicyConsent } from "../../branding/vanilla/PrivacyPolicyConsent";
import { CovidStatusConfirmation } from "../../components/CovidStatusConfirmation";
import { CancellationPolicy } from "../../components/CancellationPolicy";
import { getAppointmentConfirmationData } from "../../modules/appointment/selectors";
import moment from "moment";
import { Redirect, useLocation } from "react-router-dom";
import { UnavailableTimeDialog } from "../../components/WarningErrorMessages";
import { AppointmentSlotResponseCode } from "../../modules/appointment/types";
import { OverlineLabelValue } from "../../components/OverlineLabelValue";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";

interface AppointmentConfirmationProps {
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Validation function to be used by Formik. Never gets used inside of the component */
  validationFn: (
    values: Pick<FormValues, "acceptedPrivacyPolicy">
  ) => FormikErrors<Pick<FormValues, "acceptedPrivacyPolicy">>;
}

// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[1],
//     padding: theme.spacing(2),
//   },
//   root: {
//     justifyContent: "center",
//   },
//   headerContainer: {
//     paddingBottom: 40,
//   },
//   containerItem: {
//     paddingBottom: theme.spacing(2),
//   },
// }));

/**
 * Component validation fn, passed as a prop to Formik in FromWizard component.
 */
export const validateFormConfirmation = (t: TFunction) => (values: Pick<FormValues, "acceptedPrivacyPolicy">) => {
  const errors: { acceptedPrivacyPolicy?: string; readCoronaInfo?: string; cancellationPolicy?: string } = {};
  if (!values.acceptedPrivacyPolicy) {
    errors.acceptedPrivacyPolicy = t("validation.privacyPolicy");
  }
  // if (!values.readCoronaInfo) {
  //   errors.readCoronaInfo = t("validation.coronaInfo");
  // }
  // if (!values.cancellationPolicy) {
  //   errors.cancellationPolicy = t("validation.coronaInfo");
  // }
  return errors;
};
/**
 * Last step in the form, where user confirms data and appointment/slot.
 * Verifying correct data input and privacy policy consent/COVID check.
 */
export const AppointmentConfirmation = ({ goToPreviousPage, goToNextPage }: AppointmentConfirmationProps) => {
  // const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleRedirect = () => {
    setRedirect(true);
  };

  // Data from previous steps stored and used from Formik.
  const {
    values: {
      clientAccountDetails: { dateOfBirth, firstName, gender, lastName, phoneNumber, email },
      treatment,
      slotProposal,
      acceptedPrivacyPolicy,
      // readCoronaInfo,
      selectedEmployee,
    },
    isSubmitting,
  } = useFormikContext<FormValues>();

  // Data for error or warning display of unavailable appointment upon submission.
  const { appointmentTimeResponseCode, appointmentResponseStartTime, appointmentError } =
    useSelector(getAppointmentConfirmationData);

  // Controls open/close error dialog.
  const appointmentErrorMessage = appointmentError !== null ? appointmentError : "";

  // Check that all the data has been correctly selected, and unavailable status refers to selected slot proposal.
  const unavailableAppointmentWarning =
    appointmentTimeResponseCode === AppointmentSlotResponseCode.UNAVAILABLE &&
    appointmentResponseStartTime === slotProposal?.startTime &&
    acceptedPrivacyPolicy
      ? true
      : false // && readCoronaInfo
      ? true
      : false;

  // Eployee object from filtering employees by available publicId
  const availableEmployees = treatment?.availableEmployees ?? [];
  const selectedEmployeeName = availableEmployees?.find(
    (employee) => employee.publicId === (typeof selectedEmployee !== "string" && selectedEmployee?.publicId)
  );

  const stepInfoText = t("appointment.confirmationTitle");

  const displayEmail = email !== "";

  return (
    <>
      {/** Confirmation of the warning for unavailable time will trigger redirect to step 2 and time re selection. */}
      <UnavailableTimeDialog
        unavailableAppointmentWarning={unavailableAppointmentWarning}
        treatmentStartTime={slotProposal?.startTime}
        handleRedirect={handleRedirect}
      />
      {redirect && <Redirect to={{ search: "?step=2", pathname }} />}

      <Box pb={2}>
        {/** Header with navigation followed by display of previously selected data. */}
        <TitleNavigationHeader
          nextStepHandler={goToNextPage}
          goToPreviousPage={goToPreviousPage}
          stepInfoText={stepInfoText}
          // lastStep={true}
          errorMessage={appointmentErrorMessage}
        />
      </Box>

      <Box
        p={2}
        sx={{
          backgroundColor: "background.paper",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Box pb={2}>
          <Typography variant="h6">{t("confirmation.title")}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">{t("confirmation.infoText")}</Typography>
        </Box>

        <Grid container item spacing={4}>
          <OverlineLabelValue
            label={t("patientData.treatment")}
            displayValue={treatment ? treatment.name : ""}
            xs={6}
          />
          {treatment?.employeeSelectable && selectedEmployee ? (
            <OverlineLabelValue
              label={t("patientData.selectedEmployee")}
              displayValue={selectedEmployeeName?.name ?? ""}
              xs={6}
            />
          ) : null}
          <OverlineLabelValue
            label={t("patientData.startTime")}
            displayValue={slotProposal?.startTime ? moment(slotProposal?.startTime).format("LLL") : ""}
            xs={6}
          />
          <OverlineLabelValue label={t("patientData.firstName")} displayValue={firstName} xs={6} />
          <OverlineLabelValue label={t("patientData.lastName")} displayValue={lastName} xs={6} />
          <OverlineLabelValue label={t("patientData.gender")} displayValue={gender} xs={6} />
          <OverlineLabelValue label={t("patientData.birthDate")} displayValue={dateOfBirth} xs={6} />
          <OverlineLabelValue
            label={t("patientData.phoneNmb")}
            displayValue={parsePhoneNumberFromString(phoneNumber, "AT")?.formatInternational()}
            xs={6}
            phoneValue={true}
          />
          {displayEmail ? <OverlineLabelValue label={t("patientData.email")} displayValue={email} xs={6} /> : null}
        </Grid>
        {/** Covid status, PrivacyPolicyConsent and CancellationPolicy checkboxes */}
        <Box>{/* <CovidStatusConfirmation /> */}</Box>

        <Box>{/* <CancellationPolicy /> */}</Box>

        <Box>
          <PrivacyPolicyConsent />
        </Box>
        {appointmentErrorMessage && (
          <Grid item>
            <Typography color="error">{t("errors.submissionError")}</Typography>
          </Grid>
        )}
        <Box>
          <Button fullWidth color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={30} /> : t("confirmation.buttonConfirmation")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
