import { Grid, Typography, Paper, Divider, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import {
  fetchAvailableAppointmentTimes,
  setWeekNumberOfSelection,
  setStartOfSelectionWeek,
  nextWeekClick,
  addSwipeIndex,
  subtractSwipeIndex,
  setSwipeIndex,
  setEndOfTimeframeWithoutSlots,
} from "../../modules/timeSlots/actions";
import {
  getFromDateStringFormat,
  getToDateStringFormat,
  getWeekStepperRange,
  getHeaderDatesFromMaxWeeksInFuture,
} from "../../modules/timeSlots/utils";
import { FormikErrors, getIn, FormikValues, useField, useFormikContext } from "formik";
import { getNoProposalsAvailableForCurrentWeek, getDataForDateTimePicker } from "../../modules/timeSlots/selectors";
import { ErrorTimeSlot } from "../../components/WarningErrorMessages";
import { AvailableEmployee, SlotProposal } from "../../modules/timeSlots/types";
import { RootState } from "../../store/root-reducer";
import { EmployeeSelect } from "./EmployeeSelect";
import { DateTimePickerSection } from "./dateTimePicker/DateTimePickerSection";
import { DateTimePickerHeader } from "./dateTimePicker/DateTimePickerHeader";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { FormValues } from "../../components/FormWizard";
import { AppointmentSlotResponseCode } from "../../modules/appointment/types";

export const maxWeeksInFuture = 5;

// const useStyle = makeStyles((theme) => ({
//   componentWrap: {
//     height: "calc(100vh - 64px)",
//     flexWrap: "nowrap",
//     overflow: "hidden",
//   },
//   paperStyle: {
//     display: "flex",
//     flexDirection: "column",
//     maxWidth: "100%",
//     maxHeight: "100%",
//   },
//   paperWrap: { flex: 1, overflow: "hidden" },
// }));

interface DateTimePickerProps {
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Validation function to be used by Formik. Never gets used inside of the component */
  validationFn: (values: FormValues) => FormikErrors<FormikValues>;
}

export const validateTreatmentStartTime = (t: TFunction) => (values: FormValues) => {
  if (values.slotProposal === null) {
    return { slotProposal: { startTime: t("validation.slot") } };
  }
  if (values.slotProposal?.startTime === "") {
    return { slotProposal: { startTime: t("validation.slot") } };
  }
  return {};
};
/**
 * Provides layout for appointment time selection in dektop view.
 * Depending on fetched data, rendering message or columns with data/skeletons.
 * Option for filtering slot proposals by employees, if more than one employee is available.
 * If element is selected, it's available in setButtonRef for scrolling into view.
 */
export const DateTimePicker = ({ goToNextPage, goToPreviousPage }: DateTimePickerProps) => {
  // const classes = useStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  // Flag for slot deselection on emplyee change in case new employye doesn't have it.
  const [slotDeselected, setSlotDeselected] = useState(false);

  const [field, meta, helpers] = useField({ name: "slotProposal" });

  const { setFieldValue, validateForm, values, errors } = useFormikContext<FormValues>();

  // Keep scrollbar width so that content and header are aligned in case content is scrollable
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  // const [slotNoLongerAvailable, setSlotNoLongerAvailable] = useState(false);

  // Calculating scrollbar width
  const calculateScrollbarWidth = (elem: HTMLDivElement | null) => {
    if (slotDeselected && elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
    if (!elem) {
      return;
    }
    setScrollbarWidth(elem.offsetWidth - elem.clientWidth);
  };

  // Reference to previously selected element
  const setButtonRef = (elem: HTMLButtonElement | null) => {
    if (!elem) {
      return;
    } else {
      elem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  // Getting available employees from selected treatment out of formik state
  const availableEmployees = values.treatment?.availableEmployees ?? [];

  // swipeIndex "Page" we're on currently within swipeable views, startOfSelectionWeek is tart of ISO week of currently displayed week
  const {
    errorMessage,
    startOfSelectionWeek,
    isFetchingSlots,
    swipeIndex,
    appointmentResponseStartTime,
    appointmentResponseCode,
  } = useSelector(getDataForDateTimePicker);

  // Array consisting of first days of first and last isoWeek of the range
  const stepperWeekRange = getWeekStepperRange(maxWeeksInFuture);

  // Array of weeks where every weeks is an array of dates for respective week
  const headerDatesFromMaxWeeksInFuture = getHeaderDatesFromMaxWeeksInFuture(maxWeeksInFuture);

  // Getting first and last day of the selected week in "YYYY-MM-DD" format
  const fromDateStringFormat = getFromDateStringFormat(startOfSelectionWeek);
  const toDateStringFormat = getToDateStringFormat(startOfSelectionWeek);
  // Param object for request fetching available appointment times

  const timeFrame = {
    fromDate: fromDateStringFormat,
    toDate: toDateStringFormat,
    treatmentTypePublicId: values.treatment?.publicId ?? "",
  };

  const isFirstWeekOfSelection = swipeIndex === 0;
  const isLastWeekOfSelection = swipeIndex === maxWeeksInFuture;

  // Boolean indicator if week in range has data for display
  const weekHasNoSlots = useSelector((state: RootState) =>
    getNoProposalsAvailableForCurrentWeek(
      state,
      headerDatesFromMaxWeeksInFuture[swipeIndex],
      values.selectedEmployee ?? null,
      isFirstWeekOfSelection
    )
  );
  // Start of the last isoWeek in the range
  const lastPossibleFetchDate = moment().startOf("isoWeek").add(maxWeeksInFuture, "weeks");

  // Week after currently displayed week, possibly out of the range of stepperWeekRange
  const nextWeekStart = moment(startOfSelectionWeek).add(1, "w");
  const nextWeekButtonDisabled = nextWeekStart.isAfter(lastPossibleFetchDate);

  // Checking if the start of the currently selected week is same as start of the first week in the range
  const prevWeekButtonDisabled = moment(startOfSelectionWeek).isSame(moment(stepperWeekRange[0]));

  // validation error from Formik
  const error = getIn(errors, field.name);

  // Fetching data displayed in the component based on the selected week in range
  useEffect(() => {
    if (!values.treatment) {
      return;
    }
    dispatch(fetchAvailableAppointmentTimes.request(timeFrame, { selectedEmployee: values.selectedEmployee }));
  }, [startOfSelectionWeek]);

  /**
   * calling validation on the component when clicked from the next button in the header
   */
  const nextStepHandler = () => {
    validateForm();
  };

  /**
   * Called on time selection with slot proposal stored in the formik and local store(time only).
   * Then takes user to the next step.
   */
  const onTimeClickHandler = (slotProposal: SlotProposal) => {
    // checking that if employee is selected and its publicId exists in employee combination of slotProposal
    const employeeCombinations =
      values.selectedEmployee === null || typeof values.selectedEmployee === "string"
        ? null
        : slotProposal.employeeCombinations.find((employeeCombination) =>
            typeof values.selectedEmployee !== "string" &&
            values.selectedEmployee?.publicId !== null &&
            values.selectedEmployee?.publicId !== undefined
              ? employeeCombination.includes(values.selectedEmployee?.publicId)
              : false
          );
    // settting start time & employee combination in Formik
    helpers.setValue({
      startTime: slotProposal.startTime,
      employeeCombinations: employeeCombinations ?? null,
      originalEmployeeCombinations: slotProposal.employeeCombinations,
    });
    // setting start time in the local store

    dispatch(setWeekNumberOfSelection(slotProposal.startTime));
    if (goToNextPage) {
      goToNextPage();
    }

    if (goToNextPage) {
      validateForm();
    }
  };

  /**
   * employee change handler storing value in the Formik without validation
   * @param event by default has value of "NONE"
   */
  const handleEmployeeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setStartOfSelectionWeek(moment().startOf("isoWeek").toISOString()));
    dispatch(setSwipeIndex(0));
    /**
     * reseting the value to inital state, important if previously selected employee had no slots available, enables automatic swiping throught time frame on re selection
     */
    dispatch(setEndOfTimeframeWithoutSlots(false));
    // If selected employee doesn't have already selected slot, slot is deleted from Formik
    const selectedEmployee = event.target.value as AvailableEmployee | string;

    // Array of employee combinations in case selected employee is included in employee combinations of selected slot proposal
    const matchingEmployeeCombinations = values.slotProposal?.originalEmployeeCombinations?.find(
      (employeeCombination) =>
        typeof selectedEmployee !== "string" && employeeCombination.includes(selectedEmployee.publicId)
    );

    // Re-selecting all employees
    if (selectedEmployee === "NONE") {
      setSlotDeselected(false);
      helpers.setValue(
        {
          startTime: values.slotProposal?.startTime,
          employeeCombinations: null,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        false
      );
    }
    // Re-selected employee without previoulsy selected time slot, slot not included in slot's employee combination
    else if (selectedEmployee !== "NONE" && !matchingEmployeeCombinations && values.slotProposal?.startTime !== "") {
      setSlotDeselected(true);
      helpers.setValue(
        {
          startTime: "",
          employeeCombinations: null,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        true
      );
    }
    // Selected employee included in slot's employee combination
    else if (matchingEmployeeCombinations) {
      setSlotDeselected(false);
      helpers.setValue(
        {
          startTime: values.slotProposal?.startTime,
          employeeCombinations: matchingEmployeeCombinations,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        false
      );
    }
    // Selected employee not included but selection was previously made, all

    setFieldValue("selectedEmployee", selectedEmployee === "" ? null : selectedEmployee, false);
  };

  /**
   * nextWeekHandler and prevWeekHandler update startOfSelectionWeek that triggers fetching of the data through useEffect for a new range.
   */
  const nextWeekHandler = () => {
    // nextWeekClik updated for correct behavior of fetching the next range of data in timeSlot sagas, if a currently displayed range has no data.
    dispatch(nextWeekClick(true));
    if (!nextWeekButtonDisabled) {
      dispatch(setStartOfSelectionWeek(moment(startOfSelectionWeek).startOf("isoWeek").add(1, "week").toISOString()));
      // changes swipeIndex for correct display of swipable views.
      dispatch(addSwipeIndex());
    } else return;
  };

  const prevWeekHandler = () => {
    // nextWeekClik updated for correct behavior of fetching the previous range of data in timeSlot sagas, if a currently displayed range has no data.
    dispatch(nextWeekClick(false));
    if (!prevWeekButtonDisabled) {
      dispatch(
        setStartOfSelectionWeek(moment(startOfSelectionWeek).startOf("isoWeek").subtract(1, "week").toISOString())
      );
      dispatch(subtractSwipeIndex());
    } else return;
  };

  // Indicator that slot is no longer available on the server side
  const slotNoLongerAvailable =
    appointmentResponseCode === AppointmentSlotResponseCode.UNAVAILABLE &&
    appointmentResponseStartTime === values.slotProposal?.startTime;

  const stepInfoText = t("appointment.dateTimeSelectTitle");

  if (errorMessage) {
    return <ErrorTimeSlot errorMessage={errorMessage} />;
  }

  return (
    <>
      {values.treatment === null && <Redirect to={{ search: "?step=1", pathname }} />}
      <Grid
        item
        container
        direction="column"
        sx={{
          height: "calc(100vh - 64px)",
          flexWrap: "nowrap",
          overflow: "hidden",
        }}
      >
        <Grid item container direction="column">
          {/** Header with navigation followed by display of previously selected data */}
          <TitleNavigationHeader
            slotNoLongerAvailable={slotNoLongerAvailable}
            error={error}
            goToPreviousPage={goToPreviousPage}
            nextStepHandler={nextStepHandler}
            stepInfoText={stepInfoText}
          />
          <Box pb={2} pt={2}>
            <Grid item>
              <Typography display="inline">{` ${t("patientData.treatment")}: `}</Typography>
              <Typography color="primary" display="inline">{`${values.treatment?.name}`}</Typography>
            </Grid>
            {values.treatment?.employeeSelectable && availableEmployees !== null && availableEmployees.length > 1 && (
              <Grid item container direction="column" mt={"16px"}>
                <Grid item>
                  <Typography variant="h6">{t("doctorSelect.title")}</Typography>
                </Grid>
                <Grid item>
                  <EmployeeSelect
                    selectedEmployee={values.selectedEmployee}
                    availableEmployees={availableEmployees}
                    handleEmployeeChange={handleEmployeeChange}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid
          item
          container
          // className={classes.paperWrap}
          sx={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Paper
            // className={classes.paperStyle}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          >
            {/** Week days dates wrapped in SwipableViews */}
            <DateTimePickerHeader
              prevWeekButtonDisabled={prevWeekButtonDisabled}
              nextWeekButtonDisabled={nextWeekButtonDisabled}
              isFetchingSlots={isFetchingSlots}
              isLastWeekOfSelection={isLastWeekOfSelection}
              swipeIndex={swipeIndex}
              headerDatesFromMaxWeeksInFuture={headerDatesFromMaxWeeksInFuture}
              weekHasNoSlots={weekHasNoSlots}
              prevWeekHandler={prevWeekHandler}
              nextWeekHandler={nextWeekHandler}
            />
            <Divider />

            {/** Warnings for deselected slot and validation */}
            <Grid container justifyContent="center" alignItems="center" direction="column">
              {slotDeselected && (
                <Typography variant="caption" color="error" align="center">
                  {t("appointment.deSelectedSlotWarning")}
                </Typography>
              )}

              {/* <Typography variant="caption" color="error" align="center">
                {error && meta.touched ? error.startTime : " "}
              </Typography> */}
              <Typography variant="caption" color="error" align="center">
                {error ? error.startTime : " "}
              </Typography>

              <Typography variant="caption" color="error" align="center">
                {slotNoLongerAvailable ? t("warning.unavailableTime") : ""}
              </Typography>
            </Grid>

            {/** Section with columns of available times*/}
            <DateTimePickerSection
              scrollbarWidth={scrollbarWidth}
              calculateScrollbarWidth={calculateScrollbarWidth}
              slotProposal={values.slotProposal}
              selectedEmployee={values.selectedEmployee}
              selectedStartTime={values.slotProposal?.startTime}
              error={error}
              slotNoLongerAvailable={slotNoLongerAvailable}
              weekHasNoSlots={weekHasNoSlots}
              swipeIndex={swipeIndex}
              isLastWeekOfSelection={isLastWeekOfSelection}
              headerDatesFromMaxWeeksInFuture={headerDatesFromMaxWeeksInFuture}
              isFetchingSlots={isFetchingSlots}
              goToNextPage={goToNextPage}
              onTimeClickHandler={onTimeClickHandler}
              setButtonRef={setButtonRef}
              prevWeekHandler={prevWeekHandler}
              nextWeekHandler={nextWeekHandler}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
