import { Divider, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { CheckCircleOutline, ChevronRight } from "@mui/icons-material";
// import clsx from "clsx";
import React from "react";
import { Treatment } from "../../modules/treatments/types";
import { grey } from "@mui/material/colors";

interface TreatmentListItemProps {
  /** Indicator that appointment is disabled for booking*/
  isDisabled: boolean;
  /** Indicates selected treatment for correct display through booking process */
  selected: boolean;
  /** Handeler for treatment selection */
  selectTreatment: (treatment: Treatment) => void;
  /** Treatment object*/
  treatment: Treatment;
}

// const useStyles = makeStyles((theme) => ({
//   availableTreatment: {
//     "&:hover": {
//       backgroundColor: theme.palette.background.default,
//     },
//     cursor: "pointer",
//   },
//   highlightedTreatment: {
//     backgroundColor: "#EFF8FB",
//     "&:hover": {
//       backgroundColor: "#DEF1F7",
//     },
//   },
//   closedTreatment: {
//     color: theme.palette.grey[400],
//     cursor: "default",
//   },
//   responsiveWidth: {
//     maxWidth: "90%",
//   },
// }));

/**
 * Displays a single treatment to be selected
 */
export const TreatmentListItem = ({ treatment, selectTreatment, selected, isDisabled }: TreatmentListItemProps) => {
  // const classes = useStyles();
  const clickHandler = () => {
    selectTreatment(treatment);
  };
  const isDesktopDevice = useMediaQuery("(max-width:600px)");

  return (
    <Grid
      alignItems="center"
      container
      component={Paper}
      item
      key={treatment.publicId}
      onClick={isDisabled ? undefined : clickHandler}
      wrap="nowrap"
      mt={2}
      p={1}
      sx={{
        color: isDisabled ? grey[400] : undefined,
        cursor: isDisabled ? "default" : "pointer",
      }}
    >
      <Grid item sx={{ color: selected ? "green" : undefined }} p={1}>
        <CheckCircleOutline fontSize={isDesktopDevice ? "small" : "large"} />
      </Grid>
      <Divider orientation="vertical" />
      <Grid item container direction="column" p={1}>
        <Grid item container alignItems="center">
          <Grid item>
            <Typography variant="h6">{treatment.name}</Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Typography variant={isDesktopDevice ? "caption" : "body2"}>{treatment.description}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <ChevronRight fontSize={isDesktopDevice ? "small" : "large"} />
      </Grid>
    </Grid>
  );
};
