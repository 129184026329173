import { Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormikHelpers, useFormikContext } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parse } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import { SlotConfirmation, validateFormConfirmation } from "./slotReservation/SlotConfirmation";
import { FormValues, FormWizard } from "../components/FormWizard";
import { PatientForm, validatePatientFormData } from "../components/patientForm/PatientForm";
import { TravelTime } from "./slotReservation/TravelTime";
import { TreatmentSelect, validateTreatmentSelection } from "../components/treatments/TreatmentSelect";
import { getVerificationCodeUUID } from "../modules/phoneVerification/selectors";
import { PhoneVerification, validatePhoneVerification } from "../components/phoneVerification/PhoneVerification";
import { WaitTimeConfirmation } from "./slotReservation/WaitTimeConfirmation";
import { fetchQueueSlot, reserveSlot } from "../modules/waitingQueueSlots/actions";
import { getAllAvailableRemoteCheckInTreatments } from "../modules/treatments/selectors";
import { Treatment } from "../modules/treatments/types";
import { submitVerificationCode } from "../modules/phoneVerification/actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ContactPointSystem, ContactPointUse, HumanNameUse } from "../modules/appointment/types";

// const useStyle = makeStyles((theme) => ({
//   componentWrapper: {
//     flex: 1,
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(4),
//   },
// }));

interface SlotBookingFormProps {
  /** UUID of the orgUnit to book appointment from URL paramss */
  institutionIdentifier?: string;
}

/**
 * Wrapper component for the slot booking process. Data input is stored/retrieved from formik for all the process of booking, with data validation happening on an individual component level. When on the step 2, time stamp is created to prevent user from submitting outdated data.
 */
export const SlotBookingForm = ({ institutionIdentifier }: SlotBookingFormProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  // const classes = useStyle();
  const location = useLocation();
  const locale = i18n.language === undefined ? "de" : i18n.language;

  // Used for formatting dependent on locale
  const dateFormatLocale = {
    de: "dd.MM.yyyy",
    en: "yyyy-MM-dd",
  } as { [locale: string]: string };

  const waitTimeConfirmedDate = useRef<null | number>(null);

  // Server response to verificaiton code request
  const verificationCodeUUID = useSelector(getVerificationCodeUUID);

  const handleSubmission =
    (page: number, isLastPage: boolean, nextPage: () => void, previousPage: () => void) =>
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      if (!isLastPage) {
        // On the step 2 dispatching slot fetching with treatment and expected travel time selected.
        if (page === 1 && values.treatment) {
          dispatch(
            fetchQueueSlot.request(
              {
                treatmentPublicId: values.treatment.publicId,
                earliestPossibleArrivalTime: values.earliestArrivalTime,
                employeeCombination:
                  typeof values.selectedEmployee !== "string"
                    ? values.selectedEmployee?.publicId
                      ? [values.selectedEmployee.publicId]
                      : null
                    : null,
              }, // Meta object for passing data that we would otherwise not have access to inside of sagas.
              {
                history,
                pathName: location.pathname,
              }
            )
          );
        }
        if (page === 2) {
          waitTimeConfirmedDate.current = new Date().getTime();
        }
        // phone verification code submission
        if (page === 4) {
          if (values.verificationCodeOTP === "" || verificationCodeUUID === null) {
            return;
          }
          dispatch(
            submitVerificationCode.request(
              {
                otp: values.verificationCodeOTP.replace(/\s+/g, ""),
                uuid: verificationCodeUUID,
              },
              {
                goToNextPage: nextPage,
                setSubmitting: formikHelpers.setSubmitting,
                history,
                pathName: location.pathname,
                isSlotReservation: true,
              }
            )
          );
        }
        //logic for correct page display/re direction on phone verification
        if (page !== 4) {
          nextPage();
        }
        formikHelpers.setSubmitting(false);
        formikHelpers.setTouched({});
      } else {
        // Slot reservation dispatched on the last page.
        dispatch(
          reserveSlot.request(
            {
              formData: {
                acceptedPrivacyPolicy: values.acceptedPrivacyPolicy,
                clientAccountDetails: {
                  contactPoints: [
                    ...(values.clientAccountDetails.email !== ""
                      ? [
                          {
                            period: { start: moment().format("YYYY-MM-DD"), end: null },
                            rank: 1,
                            system: ContactPointSystem.EMAIL,
                            use: ContactPointUse.HOME,
                            value: values.clientAccountDetails.email,
                          },
                        ]
                      : []),
                  ],
                  dateOfBirth: format(
                    parse(values.clientAccountDetails.dateOfBirth!, dateFormatLocale[locale], new Date()),
                    "yyyy-MM-dd"
                  ),
                  gender: values.clientAccountDetails.gender,
                  names: [
                    {
                      family: values.clientAccountDetails.lastName,
                      given: [values.clientAccountDetails.firstName],
                      period: { start: moment().format("YYYY-MM-DD"), end: null },
                      prefix: null,
                      suffix: null,
                      text: null,
                      use: HumanNameUse.USUAL,
                    },
                  ],
                },
                comment: values.clientAccountDetails.comment === "" ? null : values.clientAccountDetails.comment,
                employeeCombination:
                  typeof values.selectedEmployee === "object" && values.selectedEmployee?.publicId
                    ? [values.selectedEmployee.publicId]
                    : null,
                verificationCode: {
                  otp: values.verificationCodeOTP.replace(/\s+/g, ""),
                  uuid: verificationCodeUUID,
                },
                treatment: values.treatment,
              },
              earliestPossibleArrivalTime: values.earliestArrivalTime,
            },
            // Meta object for passing data that we would otherwise not have access to inside of sagas.
            {
              history,
              setSubmitting: formikHelpers.setSubmitting,
              waitTimeConfirmedDate: waitTimeConfirmedDate.current,
              pathName: location.pathname,
            }
          )
        );
      }
    };

  return (
    <>
      <Box
        // className={classes.componentWrapper}
        mt={2}
        mb={4}
        sx={{
          flex: 1,
        }}
      >
        <FormWizard
          initialValues={{
            // readCoronaInfo: false,
            // cancellationPolicy: false,
            acceptedPrivacyPolicy: false,
            clientAccountDetails: {
              dateOfBirth: "",
              firstName: "",
              gender: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              comment: "",
            },
            treatment: null,
            selectedEmployee: "",
            employeeCombination: null,
            earliestArrivalTime: "",
            slotProposal: {
              startTime: "",
              employeeCombinations: null,
              originalEmployeeCombinations: null,
            },
            verificationCodeOTP: "",
          }}
          isSlot={true}
          handleSubmission={handleSubmission}
        >
          {
            /** Render props logic for FormWizard.
             *  Returned components that are booking steps are all "wrapped" in fragment and accessed as fragment children inside FormWizard.  */
            ({ goToNextPage, goToPreviousPage, page, lastPage }) => (
              <>
                <TreatmentSelect
                  isSlotReservation={true}
                  isTreatmentDisabled={({ openForRemoteCheckIn }: Treatment) => !openForRemoteCheckIn}
                  orgUnitUUID={institutionIdentifier}
                  goToNextPage={goToNextPage}
                  goToPreviousPage={goToPreviousPage}
                  getAllTreatments={getAllAvailableRemoteCheckInTreatments}
                  validationFn={validateTreatmentSelection(t)}
                />
                <TravelTime
                  validationFn={(values: FormValues) => {
                    const errors: {
                      earliestArrivalTime?: string;
                      selectedEmployee?: string;
                    } = {};

                    if (values.earliestArrivalTime === "") {
                      errors.earliestArrivalTime = t("validation.travelTime");
                    }

                    if (values.treatment?.employeeSelectable && values.selectedEmployee === "") {
                      errors.selectedEmployee = t("validation.noEmployeeSelected");
                    }

                    if (Object.keys(errors).length > 0) {
                      return errors;
                    }
                    return {};
                  }}
                  goToPreviousPage={goToPreviousPage}
                />
                <WaitTimeConfirmation goToNextPage={goToNextPage} goToPreviousPage={goToPreviousPage} />
                <PatientForm
                  validationFn={validatePatientFormData(t, locale)}
                  goToNextPage={goToNextPage}
                  goToPreviousPage={goToPreviousPage}
                  isSlotReservation={true}
                />
                <PhoneVerification
                  isSlotReservation={true}
                  validationFn={validatePhoneVerification(t)}
                  goToPreviousPage={goToPreviousPage}
                  goToNextPage={goToNextPage}
                />
                <SlotConfirmation validationFn={validateFormConfirmation(t)} goToPreviousPage={goToPreviousPage} />
              </>
            )
          }
        </FormWizard>
      </Box>
    </>
  );
};
