import React from "react";
import { Button, Typography, CircularProgress, Link, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { FormValues } from "../../components/FormWizard";
import { useTranslation } from "react-i18next";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import { getIsFetchingCodeRequest } from "../../modules/phoneVerification/selectors";

interface VerificationCodeRequestProps {
  // Re-directs user to data input form with focus on phone field depending on isSlotReservation
  handleChangeNumber: () => void;
  // Handles verificaiton code reqest as the first step in phone verification process
  handleCodeRequest: () => void;
}

// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[1],
//     padding: theme.spacing(2),
//   },
//   containerItem: {
//     paddingBottom: theme.spacing(2),
//   },
// }));

export const VerificationCodeRequest = ({ handleChangeNumber, handleCodeRequest }: VerificationCodeRequestProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();

  // Data from previous steps stored and used from Formik.
  const {
    values: {
      clientAccountDetails: { phoneNumber },
    },
  } = useFormikContext<FormValues>();

  const parsedUserPhoneNumber = parsePhoneNumberFromString(phoneNumber ?? "", "AT")?.formatInternational() ?? "";

  const isFetchingCodeRequest = useSelector(getIsFetchingCodeRequest);

  // backgroundColor: theme.palette.background.paper,

  return (
    <Box
      p={2}
      borderRadius={1}
      sx={{
        boxShadow: 1,
        backgroundColor: "#fff",
      }}
    >
      <Box pb={2}>
        <Typography variant="h6">{t("phoneVerification.verificationCodeTitle")}</Typography>
      </Box>

      <Box pb={2}>
        <Typography display="inline" variant="body2">
          {t("phoneVerification.verificationCodePhoneCheck")}
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography display="inline" variant="body2">
          {t("phoneVerification.phoneNmb")}{" "}
        </Typography>
        <Typography display="inline" variant="h6" color="primary">
          {parsedUserPhoneNumber}
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography display="inline" variant="body2">
          {t("phoneVerification.wrongPhoneNmb")}{" "}
        </Typography>
        <Link href="#" onClick={handleChangeNumber} underline="always">
          {t("phoneVerification.changeNmb")}
        </Link>
      </Box>

      <Box>
        <Button variant="contained" color="primary" fullWidth onClick={handleCodeRequest}>
          {isFetchingCodeRequest ? <CircularProgress size={30} /> : t("phoneVerification.codeRequest")}
        </Button>
      </Box>
    </Box>
  );
};
