import { TextField } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { ErrorMessage, FieldProps, getIn } from "formik";
import React from "react";

// const useStyles = makeStyles((theme) => ({
//   errorMessage: {
//     width: "100%",
//   },
// }));

interface IProps extends FieldProps {}

/**
 * Helper component hooking up Formik field props to the material-ui TextField component
 * @param fieldProps
 */
export const FormikTextFieldComponent = ({ field, form: { errors, touched }, ...props }: IProps) => {
  // const classes = useStyles();
  const fieldErrors = getIn(errors, field.name);
  const fieldTouched = getIn(touched, field.name);
  return (
    <TextField
      multiline={field.name === "clientAccountDetails.comment"}
      {...field}
      {...props}
      error={fieldTouched && fieldErrors !== undefined}
      sx={{
        width: "100%",
      }}
      // FormHelperTextProps={{ classes: { root: classes.errorMessage } }}
      helperText={<ErrorMessage name={field.name} />}
    />
  );
};
