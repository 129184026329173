import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import { AxiosResponse, AxiosError } from "axios";
import { InstitutionDetailsAndTreatments } from "./types";
import api from "../services/api";
import { getType } from "typesafe-actions";

export default function* institutionDetailsAndTreatmentsSaga() {
  yield all([takeLatest(getType(actions.fetchInstitutionDetailsAndTreatments.request), fetchDetailsAndTreatments)]);
}

/**
 * Saga for fetching institution details
 */
function* fetchDetailsAndTreatments(action: ReturnType<typeof actions.fetchInstitutionDetailsAndTreatments.request>) {
  try {
    const response: AxiosResponse<InstitutionDetailsAndTreatments> = yield call(
      api.institutionDetailsAndTreatments.fetchDetailsAndTreatments,
      action.payload
    );
    yield put(actions.fetchInstitutionDetailsAndTreatments.success(response.data));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 404) {
      yield put(actions.fetchInstitutionDetailsAndTreatments.failure("NOT_FOUND"));
    } else {
      yield put(actions.fetchInstitutionDetailsAndTreatments.failure(error.message));
    }
  }
}
