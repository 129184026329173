import { Box, Button, Grid, Paper, Typography, makeStyles, Chip } from "@mui/material";
import { add, differenceInYears, format, formatISO, isAfter, isBefore, isValid, parse, parseISO } from "date-fns";
import { Field, FormikErrors, useFormikContext } from "formik";
import React, { useRef, useEffect } from "react";
import { PatientData } from "../../modules/patients/types";
import { FormValues } from "../FormWizard";
import { GenderSelect } from "./GenderSelect";
import { FormikTextFieldComponent } from "./FormikTextField";
import { PhoneFormikTextFieldComponent } from "./PhoneTextField";
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useQuery } from "../../utils/queryParams";
import MaskedInput from "react-text-mask";
import { Treatment } from "../../modules/treatments/types";
import { rEmail } from "../../utils/regex";
import { consultationsMode } from "../../utils/consultationsMode";
import { useSelector } from "react-redux";
import { getWaitingQueueSlot } from "../../modules/waitingQueueSlots/selectors";
interface PatientFormProps {
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Indicates whether we're reserving a slot as opposed to booking an appointmen */
  isSlotReservation?: boolean;
  /** Validation function to be used by Formik. Never gets used inside of the component */
  validationFn: (values: {
    clientAccountDetails: PatientData;
    treatment: Treatment;
  }) => FormikErrors<Pick<FormValues, "clientAccountDetails" | "treatment">>;
}

/**
 * Component validation fn, passed as a prop to Formik in FromWizard component.
 */
export const validatePatientFormData =
  (t: TFunction, locale: string) =>
  ({
    clientAccountDetails: { firstName, dateOfBirth, gender, lastName, phoneNumber, comment, email },
    treatment,
  }: Pick<FormValues, "clientAccountDetails" | "treatment">): FormikErrors<
    Pick<FormValues, "clientAccountDetails" | "treatment">
  > => {
    const errors: {
      firstName?: string;
      dateOfBirth?: string;
      gender?: string;
      lastName?: string;
      phoneNumber?: string;
      comment?: string;
      email?: string;
    } = {};
    // Used for formatting dependent on locale
    const dateFormatLocale = {
      de: "dd.MM.yyyy",
      en: "yyyy-MM-dd",
    } as { [locale: string]: string };

    if (
      treatment?.videoConsultationMode !== undefined &&
      consultationsMode[treatment.videoConsultationMode] &&
      email === ""
    ) {
      errors.email = t("validation.emailInput");
    }
    if (email !== "" && !rEmail.test(email)) {
      errors.email = t("validation.emailFormat");
    }

    if (comment.length > 1000) {
      errors.comment = "Your comments is too long. Max length is 1000 characters.";
    }

    if (firstName === "") {
      errors.firstName = t("validation.firstName");
    }
    if (lastName === "") {
      errors.lastName = t("validation.lastName");
    }
    if (phoneNumber === "") {
      errors.phoneNumber = t("validation.noPhoneNumber");
    } else {
      // Checking phone input validity with libphonenumber-js
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "AT");

      if (!parsedPhoneNumber) {
        errors.phoneNumber = t("validation.wrongPhoneNumberFormat");
      } else if (!parsedPhoneNumber.isPossible() || parsedPhoneNumber.getType() !== "MOBILE") {
        errors.phoneNumber = t("validation.wrongPhoneNumberFormat");
      }
    }
    if (gender === "") {
      errors.gender = t("validation.gender");
    }
    // Checking if dateOfBirth exisits and is in expected format
    if (dateOfBirth === null || !isValid(parse(dateOfBirth, dateFormatLocale[locale], new Date()))) {
      errors.dateOfBirth = t("validation.dateOfBirth");
    } else {
      const parsedBirthDate = parse(dateOfBirth, dateFormatLocale[locale], new Date());
      const minimumDate = parse("01-01-1900", "MM-dd-yyyy", new Date());
      // Checking that date is not older than set day in the past
      if (isBefore(parsedBirthDate, minimumDate)) {
        errors.dateOfBirth = t("validation.dateOfBirth");
      }
      // Checking that date is not in the future
      if (isAfter(parsedBirthDate, new Date())) {
        errors.dateOfBirth = t("validation.dateOfBirth");
      }
      // Checking that user is older than 18
      // if (differenceInYears(new Date(), parsedBirthDate) < 18) {
      //   errors.dateOfBirth = t("validation.dateOfBirthDifference");
      // }
    }
    // Checking that user is older than 18
    // Disabled as of now for cust02
    // if (differenceInYears(new Date(), parsedBirthDate) < 18) {
    //   errors.dateOfBirth = t("validation.dateOfBirthDifference");
    // }
    //}
    if (Object.keys(errors).length > 0) {
      return {
        clientAccountDetails: errors,
      };
    }
    return {};
  };

// const useStyles = makeStyles((theme) => ({
//   formItem: {
//     padding: theme.spacing(2),
//   },
//   inlineTitle: {
//     marginRight: theme.spacing(1),
//   },
// }));

// Mask passed as input component on birth date Filed
interface MaskedInputBirthDateProps {
  inputRef: (instance: HTMLElement | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const MaskedInputBirthDate = React.forwardRef<HTMLElement, MaskedInputBirthDateProps>((props, ref) => {
  const { inputRef, ...other } = props;
  const { i18n } = useTranslation();
  // defining locale for the tests in the case it's undefined
  const selectedLocale = i18n.language === undefined ? "de" : i18n.language;
  // mask regex depending on selectedLocale
  const localeDateInputRegex = {
    de: [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/],
    en: [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/],
  } as { [locale: string]: (string | RegExp)[] };
  return (
    <MaskedInput
      mask={localeDateInputRegex[selectedLocale]}
      {...other}
      ref={(maskedInput) => {
        if (maskedInput?.inputElement) {
          if (typeof ref === "function") {
            ref(maskedInput?.inputElement);
          }
        }
      }}
    />
  );
});

/**
 * Form collecting patient information
 */
export const PatientForm = ({ goToPreviousPage, isSlotReservation }: PatientFormProps) => {
  const { t, i18n } = useTranslation();
  const selectedLocale = i18n.language;

  // Data from previous steps used stored and used from Formik
  const {
    values: { treatment, earliestArrivalTime, slotProposal, selectedEmployee },
  } = useFormikContext<FormValues>();
  const formattedTreatmentStartTime = moment(slotProposal?.startTime).format("LLL");
  const stepInfoText = t("appointment.patientFormTitle");

  const waitingQueueSlot = useSelector(getWaitingQueueSlot);

  // ref for setting focus on phone field when coming with "change number" link from PhoneVerification component
  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);

  // Flag for setting focus on phone field from params
  const query = useQuery();
  const highlightPhoneNumber = query.get("phoneFocus");

  useEffect(() => {
    if (highlightPhoneNumber) {
      phoneNumberInputRef.current?.focus();
    }
  }, [highlightPhoneNumber]);

  // Value for placeholder on birth date dependent on locale
  const birthDayPlaceholder = {
    de: "TT.MM.JJJJ",
    en: "YYY-MM-DD",
  } as { [locale: string]: string };

  // Email required for video consultations
  const emailRequired =
    treatment?.videoConsultationMode !== undefined && consultationsMode[treatment.videoConsultationMode];

  // mask regex for birthday input depending on selectedLocale
  const selectedLocaleKey = i18n.language === undefined ? "de" : i18n.language;
  const localeDateInputMask = {
    de: "00{.}00{.}0000",
    en: "0000{-}00{-}00",
  } as { [locale: string]: string };

  // specific properties for generic maskedFormikTextField component
  const maskSpecificProperties = {
    mask: localeDateInputMask[selectedLocaleKey],
    autofocus: false,
  };

  return (
    <>
      <Box pb={2}>
        {/** Header with navigation followed by display of previously selected data */}
        <TitleNavigationHeader
          goToPreviousPage={goToPreviousPage}
          stepInfoText={stepInfoText}
          isSlotReservation={isSlotReservation}
        />

        <Box py={2}>
          <Typography display="inline" mr={1}>
            {`${t("patientData.treatment")}: `}
          </Typography>
          <Typography color="primary" display="inline">
            {treatment?.name ?? ""}
          </Typography>
        </Box>
        {slotProposal?.startTime ? (
          <Box pb={2}>
            <Typography display="inline" mr={1}>
              {`${t("appointment.selectedStartTime")}: `}
            </Typography>
            <Chip color="primary" label={`${formattedTreatmentStartTime}`} />
          </Box>
        ) : null}
        {waitingQueueSlot?.earliestStartTime ? (
          <Box pb={2}>
            <Typography display="inline" mr={1}>
              {`${t("slot.waitTimeConfirmation.timeEstimationTitle")}: `}
            </Typography>
            <Typography color="primary" display="inline">
              {format(parseISO(waitingQueueSlot.earliestStartTime), "HH:mm'h'")}
            </Typography>
          </Box>
        ) : null}
        {treatment?.employeeSelectable && (
          <Grid item>
            <Typography display="inline">{` ${t("patientData.selectedEmployee")}: `}</Typography>
            <Typography color="primary" display="inline">{` ${
              typeof selectedEmployee !== "string" && selectedEmployee?.name
                ? selectedEmployee?.name
                : t("doctorSelect.noSelection")
            }`}</Typography>
          </Grid>
        )}
      </Box>
      <Grid item container direction="column" component={Paper} pt={0} pl={0}>
        <Grid item p={2}>
          <Typography variant="h6">{t("patientData.title")}</Typography>
        </Grid>
        <Grid item p={2}>
          <Typography variant="caption" color="textSecondary">
            {t("patientData.bookingForSomeoneElse")}
          </Typography>
        </Grid>
        {/** Form inputs wrapped in formik Field component */}
        <Grid item p={2}>
          <Field
            component={FormikTextFieldComponent}
            fullWidth
            name="clientAccountDetails.firstName"
            label={t("patientData.firstName")}
            required
            variant="filled"
            id="vorname"
            autoFocus={true}
          />
        </Grid>
        <Grid item p={2}>
          <Field
            component={FormikTextFieldComponent}
            fullWidth
            name="clientAccountDetails.lastName"
            label={t("patientData.lastName")}
            required
            variant="filled"
            id="nachname"
          />
        </Grid>
        <Grid item p={2}>
          <Field
            inputRef={phoneNumberInputRef}
            component={PhoneFormikTextFieldComponent}
            fullWidth
            name="clientAccountDetails.phoneNumber"
            placeholder={t("patientData.phonePlaceholder")}
            label={t("patientData.phoneNmb")}
            helperText={t("patientData.phoneHelperText")}
            required
            variant="filled"
            id="handynummer"
          />
        </Grid>
        <Grid item container>
          <Grid item xs={12} sm={6} p={2}>
            <Field
              component={FormikTextFieldComponent}
              name="clientAccountDetails.dateOfBirth"
              fullWidth
              label={t("patientData.birthDate")}
              required
              placeholder={birthDayPlaceholder[selectedLocale]}
              variant="filled"
              id="geburtstag"
              InputProps={{
                inputComponent: MaskedInputBirthDate as JSX.Element | unknown,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} p={2}>
            <Field component={GenderSelect} name="clientAccountDetails.gender" />
          </Grid>
        </Grid>
        <Grid item p={2}>
          <Field
            component={FormikTextFieldComponent}
            fullWidth
            name="clientAccountDetails.email"
            placeholder={t("patientData.emailPlaceholder")}
            label={emailRequired ? t("patientData.emailLabel") : t("patientData.optionalEmailLabel")}
            required={emailRequired}
            variant="filled"
            id="email"
          />
        </Grid>
        <Grid item p={2}>
          <Field
            component={FormikTextFieldComponent}
            fullWidth
            name="clientAccountDetails.comment"
            placeholder={t("patientData.commentPlaceholder")}
            label={t("patientData.commentLabel")}
            variant="filled"
            id="comment"
          />
        </Grid>
        <Grid item p={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {t("navigation.navNext")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
