import { Button, Box, Grid, Typography } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { CheckCircle } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { remoteWaitingURL } from "../modules/services/api";
import { green } from "@mui/material/colors";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     padding: theme.spacing(3, 0),
//   },
//   green: {
//     color: colors.green[400],
//     fontSize: "7rem",
//   },
//   orange: {
//     color: colors.orange[400],
//     fontSize: "7rem",
//   },
// }));

/**
 * Showing confirmation and feedback upon successful submision with reminder for the phone link.
 */
export const ReservationFinished = () => {
  // const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const params = useParams<{ token?: string }>();
  const isAppointmentBooking = location.pathname.includes("book-appointment-finished");
  history.block();

  return (
    <Grid container direction="column" alignItems="center" py={3} px={0} spacing={2}>
      <Box>
        <Typography variant="h4" align="center">
          {t("reservationFinished.feedbackTitle")}
        </Typography>
      </Box>
      <Box
        sx={{
          color: green[400],
          fontSize: "7rem",
        }}
      >
        <CheckCircle color="inherit" fontSize="inherit" />
      </Box>
      <Box>
        <Typography variant="h5" align="center">
          {isAppointmentBooking
            ? t("reservationFinished.feedbackTextAppointment")
            : t("reservationFinished.feedbackTextSlot")}
        </Typography>
      </Box>
      {/* <Box pt={"50px"}>
        <Button variant="contained" color="primary" fullWidth href={`${remoteWaitingURL}/${params.token}`}>
          {isAppointmentBooking
            ? t("reservationFinished.showAppointmentDetails")
            : t("reservationFinished.trackWaitingTime")}
        </Button>
      </Box> */}
    </Grid>
  );
};
