import * as actions from "./actions";
import { WaitingQueueSlot, WaitingQueueReserveSlotResponse, CheckInStatusCode } from "./types";
import { AxiosResponse, AxiosError } from "axios";
import api from "../services/api";
import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { isNetworkError } from "../../utils/api";
import { differenceInMinutes, differenceInSeconds, parse } from "date-fns";

export default function* waitingQueueSlotSaga() {
  yield all([
    takeEvery(getType(actions.fetchQueueSlot.request), fetchWaitingQueueSlot),
    takeLatest(getType(actions.reserveSlot.request), reserveWaitingQueueSlot),
  ]);
}

/**
 * Fetch of available slot upon travel time selection
 */
function* fetchWaitingQueueSlot(action: ReturnType<typeof actions.fetchQueueSlot.request>) {
  try {
    const now = new Date();
    const { earliestPossibleArrivalTime: formikEarliestPossibleArrivalTime, ...restPayload } = action.payload;

    const earliestPossibleArrivalTime =
      formikEarliestPossibleArrivalTime === "NOW" ? undefined : formikEarliestPossibleArrivalTime;
    const minutesToEarliestArrivalTime =
      formikEarliestPossibleArrivalTime === "NOW"
        ? undefined
        : action.payload.earliestPossibleArrivalTime !== undefined
        ? differenceInMinutes(new Date(action.payload.earliestPossibleArrivalTime), now) +
          (differenceInSeconds(new Date(action.payload.earliestPossibleArrivalTime), now) > 30 ? 1 : 0)
        : undefined;

    const response: AxiosResponse<WaitingQueueSlot> = yield call(api.waitingQueueSlots.fetch, {
      earliestPossibleArrivalTime,
      minutesToEarliestArrivalTime,
      ...restPayload,
    });
    yield put(actions.fetchQueueSlot.success(response.data));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.code === "404") {
      yield put(actions.fetchQueueSlot.failure("NOT_FOUND"));
    } else {
      yield put(actions.fetchQueueSlot.failure(error.message));
      action.meta.history.replace({
        pathname: `/${action.meta.pathName.split("/")[1]}`,
        state: { slotSubmissionError: true },
      });
    }
  }
}

/**
 * Slot submisson on the last step of the booking process.
 * Checks time since travel time selection, with possbile re direction if enought time has elapsed since.
 * Upon sccessful submission, redirection to SlotReservationFinished component.
 */
function* reserveWaitingQueueSlot(action: ReturnType<typeof actions.reserveSlot.request>) {
  if (action.meta.waitTimeConfirmedDate && new Date().getTime() - action.meta.waitTimeConfirmedDate >= 300_000) {
    action.meta.history.push({
      pathname: action.meta.pathName,
      search: "?step=3",
      state: { waitTimeConfirmationDeltaError: true },
    });
    if (action.payload.formData.treatment?.publicId) {
      yield put(
        actions.fetchQueueSlot.request(
          {
            treatmentPublicId: action.payload.formData.treatment.publicId,
            earliestPossibleArrivalTime: action.payload.earliestPossibleArrivalTime,
            employeeCombination: action.payload.formData.employeeCombination,
          },
          { history: action.meta.history, pathName: action.meta.pathName }
        )
      );
    }
    return;
  }
  try {
    const now = new Date();
    const { earliestPossibleArrivalTime: formikEarliestPossibleArrivalTime, ...restPayload } = action.payload;

    const earliestPossibleArrivalTime =
      formikEarliestPossibleArrivalTime === "NOW" ? undefined : formikEarliestPossibleArrivalTime;
    const minutesToEarliestArrivalTime =
      formikEarliestPossibleArrivalTime === "NOW"
        ? undefined
        : action.payload.earliestPossibleArrivalTime !== undefined
        ? differenceInMinutes(new Date(action.payload.earliestPossibleArrivalTime), now) +
          (differenceInSeconds(new Date(action.payload.earliestPossibleArrivalTime), now) > 30 ? 1 : 0)
        : undefined;

    const response: AxiosResponse<WaitingQueueReserveSlotResponse> = yield call(api.waitingQueueSlots.reserveSlot, {
      earliestPossibleArrivalTime,
      minutesToEarliestArrivalTime,
      ...restPayload,
    });

    if (response.data.checkInStatusCode === CheckInStatusCode.CREATED) {
      const remoteWaitingToken = response.data.remoteWaitingToken;
      action.meta.history.replace(`/reserve-slot-finished/${remoteWaitingToken}`);
    } else if (response.data.checkInStatusCode === CheckInStatusCode.NO_WAITING_QUEUE_SLOT_AVAILABLE) {
      action.meta.history.replace({
        pathname: `/${action.meta.pathName.split("/")[1]}`,
        state: { noSlotAvailableError: true },
      });
    } else if (
      response.data.checkInStatusCode === CheckInStatusCode.OUT_OF_OPENING_HOURS ||
      response.data.checkInStatusCode === CheckInStatusCode.REMOTE_CHECK_IN_CLOSED
    ) {
      action.meta.history.replace({
        pathname: `/${action.meta.pathName.split("/")[1]}`,
        state: { closedInstitutionError: true },
      });
    } else if (
      response.data.checkInStatusCode === CheckInStatusCode.ERROR_CREATING_CLIENT_ACCOUNT ||
      response.data.checkInStatusCode === CheckInStatusCode.INVALID_TREATMENT_TYPE ||
      response.data.checkInStatusCode === CheckInStatusCode.ERROR_CREATING_WAITING_QUEUE_SLOT
    ) {
      action.meta.history.replace({
        pathname: `/${action.meta.pathName.split("/")[1]}`,
        state: { slotSubmissionError: true },
      });
    } else {
      yield put(actions.reserveSlot.failure(response.data.checkInStatusCode));
    }
  } catch (error) {
    if (isNetworkError(error)) {
      yield put(actions.reserveSlot.failure("NETWORK_ERROR"));
    } else {
      yield put(actions.reserveSlot.failure(error.message));
    }
  }
}
