import { combineReducers } from "redux";
import treatmentReducers from "../modules/treatments/reducers";
import appointmentReducers from "../modules/appointment/reducers";
import waitingQueueSlotReducers from "../modules/waitingQueueSlots/reducers";
import institutionDetailsReducers from "../modules/institution/reducers";
import availableAppointmentTimeSlotsReducers from "../modules/timeSlots/reducers";
import phoneVerificationReducers from "../modules/phoneVerification/reducers";
import { StateType } from "typesafe-actions";
import { phoneVerificationAPI } from "../modules/phoneVerification/api";

const rootReducer = combineReducers({
  institutionDetails: institutionDetailsReducers,
  treatments: treatmentReducers,
  waitingQueueSlots: waitingQueueSlotReducers,
  appointmentTimeSlots: availableAppointmentTimeSlotsReducers,
  appointmentDetails: appointmentReducers,
  phoneVerification: phoneVerificationReducers,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
