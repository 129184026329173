import { createSelector } from "reselect";
import { RootState } from "../../store/root-reducer";
import { getInstitutionPhoneNumber } from "../institution/selectors";

const getState = (state: RootState) => state.phoneVerification;

export const getIsFetchingCodeRequest = (state: RootState) => {
  return getState(state).isFetching.codeRequest;
};

export const getIsFetchingCodeSubmisson = (state: RootState) => {
  return getState(state).isFetching.codeSubmission;
};

/**
 * VerificationCodeUUID string in server response to verification code request
 */
export const getVerificationCodeUUID = (state: RootState) => {
  return getState(state).verificationCodeUUID;
};

/**
 * Response on verification code submission
 */
export const getCodeConfirmation = (state: RootState) => {
  return getState(state).codeConfirmation;
};

export const getCodeSubmissionError = (state: RootState) => {
  return getState(state).errorMessage.codeSubmissionError;
};

export const getCodeRequestError = (state: RootState) => {
  return getState(state).errorMessage.codeRequestError;
};

export const getPhoneVerificationData = createSelector(
  getInstitutionPhoneNumber,
  getVerificationCodeUUID,
  getCodeConfirmation,
  getCodeSubmissionError,
  getCodeRequestError,
  (institutionPhoneNumber, verificationCodeUUID, codeConfirmation, codeSubmissionError, codeRequestError) => {
    return { institutionPhoneNumber, verificationCodeUUID, codeConfirmation, codeSubmissionError, codeRequestError };
  }
);
