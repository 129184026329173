import * as actions from "./actions";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse, AxiosError } from "axios";
import { ConfirmationCode } from "./types";
import { getType } from "typesafe-actions";
import api from "../services/api";
import { isMetaProperty } from "typescript";

export default function* phoneVerificationSaga() {
  yield all([
    takeEvery(getType(actions.requestPhoneVerificationCode.request), requestPhoneVerificationCode),
    takeEvery(getType(actions.submitVerificationCode.request), submitPhoneVerificationCode),
  ]);
}

export function* requestPhoneVerificationCode(action: ReturnType<typeof actions.requestPhoneVerificationCode.request>) {
  try {
    const response: AxiosResponse<string> = yield call(api.phoneVerification.phoneVerification, action.payload);
    yield put(actions.requestPhoneVerificationCode.success(response.data));
    yield action.meta.setSubmitting(false);
  } catch (err) {
    if (err.message) {
      yield put(actions.requestPhoneVerificationCode.failure(err.message));
    }
  }
}

export function* submitPhoneVerificationCode(action: ReturnType<typeof actions.submitVerificationCode.request>) {
  const orgUnitUUID = action.meta.history.location?.pathname.split("/")[1];

  try {
    const response: AxiosResponse = yield call(api.codeSubmissionAPI.codeSubmission, action.payload);
    if (response.status === 204) {
      yield put(actions.submitVerificationCode.success(ConfirmationCode.CONFIRMED));
      action.meta.setSubmitting(false);
      if (action.meta.goToNextPage) {
        action.meta.goToNextPage();
      }
    }
  } catch (err) {
    const axiosError = err as AxiosError;
    if (axiosError.response?.status === 403) {
      yield put(actions.submitVerificationCode.success(ConfirmationCode.UN_CONFIRMED));
      action.meta.setSubmitting(false);
    } else if (axiosError.response?.status === 429) {
      yield put(actions.submitVerificationCode.success(ConfirmationCode.BLOCKED));
      action.meta.setSubmitting(false);
    } else {
      yield put(actions.submitVerificationCode.failure(err.message));
    }
  }
}
