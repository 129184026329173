import { TreatmentState, Treatment } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { combineReducers } from "redux";
import * as actions from "./actions";

type TreatmentActions = ActionType<typeof actions>;

/**
 * Normalized data structure for fetched treatments
 */
const initialState: TreatmentState = {
  allIds: [],
  byId: {},
  lastFetch: null,
  isFetching: true,
  error: null,
};

/**
 * Id's of all treatments to indicate ordering
 */
export const allIds = (state = initialState.allIds, action: TreatmentActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.success):
      return action.payload.treatmentTypes.map(({ publicId }) => publicId);
    default:
      return state;
  }
};

/**
 * Individual treatments stored as objects with their ids' as keys
 */
export const byId = (state = initialState.byId, action: TreatmentActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.success):
      return action.payload.treatmentTypes.reduce((acc, curr) => {
        acc[curr.publicId] = curr;
        return acc;
      }, {} as { [id: string]: Treatment });
    default:
      return state;
  }
};

/**
 * Time stamp created on successful data fetch.
 * Used as a check in SlotAppointmentFork for correct message display.
 */
export const lastFetch = (state = initialState.lastFetch, action: TreatmentActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.success):
      return new Date().getTime();
    default:
      return state;
  }
};

/**
 * Used as trigger for loading spinner in SlotAppointmentFork
 */
export const isFetching = (state = initialState.isFetching, action: TreatmentActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.success):
    case getType(actions.fetchInstitutionDetailsAndTreatments.failure):
      return false;
    case getType(actions.fetchInstitutionDetailsAndTreatments.request):
      return true;
    default:
      return state;
  }
};

export const error = (state = initialState.error, action: TreatmentActions) => {
  switch (action.type) {
    case getType(actions.fetchInstitutionDetailsAndTreatments.failure):
      return action.payload;
    case getType(actions.fetchInstitutionDetailsAndTreatments.request):
      return null;
    default:
      return state;
  }
};

export default combineReducers<TreatmentState, TreatmentActions>({
  allIds,
  byId,
  lastFetch,
  isFetching,
  error,
});
