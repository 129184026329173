import React from "react";
import { Button, Grid, Typography, Dialog, DialogActions, DialogContent, Alert } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import { useTranslation } from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//   rootTimeDialog: {
//     justifyContent: "center",
//   },
//   button: {
//     marginTop: theme.spacing(2),
//     width: 200,
//   },
//   endIcon: { margin: theme.spacing(0), alignSelf: "center", color: "#fff", fontSize: "2em" },
//   root: { alignSelf: "center" },
//   alert: {
//     padding: theme.spacing(4),
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     fontSize: "1.2em",
//   },
//   componentWrap: { height: "100%" },
// }));

interface UnavailableTimeDialogProps {
  /** Redirection triggered by confirmation of dialog */
  handleRedirect: () => void;
  /** Selected treatment start time */
  treatmentStartTime: string | undefined;
  /** Controls open/close state of dialog */
  unavailableAppointmentWarning: boolean;
}

/** Dialog component with warning about unavailable time based on server response after submission */
export const UnavailableTimeDialog = ({
  unavailableAppointmentWarning,
  treatmentStartTime,
  handleRedirect,
}: UnavailableTimeDialogProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={unavailableAppointmentWarning}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography>
          {t("errors.unavailableTimeDialog1", { time: moment(treatmentStartTime).format("MMMM Do YYYY, h:mm a") })}
        </Typography>
        <Typography>{t("errors.unavailableTimeDialog2")}</Typography>
      </DialogContent>
      <DialogActions
        // classes={{ root: classes.rootTimeDialog }}
        sx={{
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            handleRedirect();
          }}
          color="primary"
          autoFocus
          variant="outlined"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ErrorDialogWithReloadProps {
  /** Controls open/close state of dialog */
  dialogOpen: boolean;
}

/** Component showing error message triggered by appointment submission */
export const ErrorDialogWithReload = ({ dialogOpen }: ErrorDialogWithReloadProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={dialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <Typography>{t("errors.confirmationErrorDialog")}</Typography>
      </DialogContent>
      <DialogActions
        // classes={{ root: classes.root }}
        sx={{
          alignSelf: "center",
        }}
      >
        <Button onClick={() => window.location.reload()} color="primary" autoFocus variant="outlined">
          {t("errors.confirmationErrorDialogReload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ErrorTimeSlotProps {
  /** Error message from the server */
  errorMessage: string | null;
}

/** Component for appointment time slots error display with reload button */
export const ErrorTimeSlot = ({ errorMessage }: ErrorTimeSlotProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      sx={{
        height: "100%",
      }}
    >
      <Alert
        severity="error"
        sx={{
          padding: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1.2em",
        }}
      >
        {t("errors.errorTimeSlot")}
        <Typography>{errorMessage}</Typography>
        <Button
          onClick={() => window.location.reload()}
          variant="contained"
          color="primary"
          sx={{
            marginTop: 2,
            width: "200px",
            "& .MuiButton-root": {
              alignSelf: "center",
            },
            "& .MuiButton-endIcon": {
              margin: 0,
              alignSelf: "center",
              color: "#fff",
              fontSize: "2em",
            },
          }}
          endIcon={<RefreshIcon color="primary" />}
        >
          {t("errors.confirmationErrorDialogReload")}
        </Button>
      </Alert>
    </Grid>
  );
};
