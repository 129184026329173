import { createAsyncAction, createAction } from "typesafe-actions";
import { AvailableAppointmentTimes, TimeSlotRequest } from "./types";
import { AvailableEmployee } from "../../modules/timeSlots/types";

/**
 * Dispatched from DateTimePicker/DateTimePickerMobile.
 * Fetches possible appointment times for selected treatment and time frame.
 * Time frame is part of the request and it represents currently displayed week in time range for desktop view, or all week that user wants to see on mobile view.
 */
export const fetchAvailableAppointmentTimes = createAsyncAction(
  "FETCH_AVAILABLE_APPOINTMENT_TIMES_REQUEST",
  "FETCH_AVAILABLE_APPOINTMENT_TIMES_SUCCESS",
  "FETCH_AVAILABLE_APPOINTMENT_TIMES_FAILURE"
)<
  [TimeSlotRequest, { selectedEmployee: string | AvailableEmployee | null }],
  AvailableAppointmentTimes,
  string | null
>();

/**
 * Dispatched from DateTimePicker/Mobile on selection of time.
 * Value kept in selectedDateTime in store.
 * Dispatched on treatment select too, setting value to "" as a reset in case of previous selection already in store (case of user changing treatment in booking process)
 */
export const setWeekNumberOfSelection = createAction("SET_WEEK_NUMBER_OF_SELECTION")<string>();
/**
 * Dispatched from DateTimePicker with prev/nextWeekHandler.
 * Sets value of day that is beginning of time range that data is fetched and displayed.
 */
export const setStartOfSelectionWeek = createAction("SET_START_OF_SELECTION_WEEK")<string>();

/**
 * Dispatched from nextWeekHandler/prevWeekHandler in DateTimePicker component.
 * Part of logic for fetching next range of data in timeSlot sagas, if a currently displayed range has no data saga can automatically move to next week range.
 * With this updated, saga knows either to move to next, or previous week range for data fetch.
 */
export const nextWeekClick = createAction("NEXT_WEEK_CLICK")<boolean>();

// not used
export const clearSlots = createAction("CLEAR_SLOTS")();

/**
 * Dispatched from nextWeekHandler in DateTimePicker.
 * Relevant for correct display of selected week i header (passed as index to SwipableViews that hold data for all possible weeks in range, but with index showing only selected ) and used for correct selection of data to on display as it determines current the week in array of all possible weeks.
 */
export const addSwipeIndex = createAction("ADD_SWIPE_INDEX")();
/**
 * Dispatched from prevWeekHandler in DateTimePicker.
 * Relevant for correct display of selected week in header (passed as index to SwipableViews that hold data for all possible weeks in range, but with index showing only selected ) and used for correct selection of data to on display as it determines current the week in array of all possible weeks.
 */
export const subtractSwipeIndex = createAction("SUBTRACT_SWIPE_INDEX")();

/**
 * In case users re selects treatment after changing index, this will re set it and display first week of the range.
 */
export const resetSwipeIndex = createAction("RESET_SWIPE_INDEX")();

/**
 * Dispatched on employee change, sets swipe index to 0 so available slots visible for selected employee from the start beginning
 */
export const setSwipeIndex = createAction("SET_SWIPE_INDEX")<number>();

/**
 * Dispatched from onShowNextWeekHandler in DateTimePickerMobile.
 * Adds a week to the range for data that is shown and fetched in mobile view.
 */
export const addMobileViewWeek = createAction("ADD_MOBILE_VIEW_WEEK")();

/**
 * Dispatched on reaching the end of time frame when looking for available slots to display in case non are found. Disptched to reset initial value on re selection of employee
 */
export const setEndOfTimeframeWithoutSlots = createAction("SET_END_OF_TIMEFRAME_WITHOUT_SLOTS")<boolean>();
