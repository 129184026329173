import * as actions from "./actions";
import { all, call, put, takeEvery, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { AvailableAppointmentTimes } from "./types";
import api from "../services/api";
import { getType } from "typesafe-actions";
import { getStartOfSelectionWeek, getNextWeekClick, getSwipeIndex } from "../../modules/timeSlots/selectors";
import moment from "moment";
import { maxWeeksInFuture } from "../../pages/appointmentBooking/DateTimePicker";
import { isSlotProposalEmpty } from "../../modules/timeSlots/utils";

// const URL = "https://dev1.rest.naboto.com/services/remotewaitingclientservice/api/public/get-appointment-proposals";

// yield call(api.institutionDetails.fetchDetails, action.payload)

export default function* timeSlotsSaga() {
  yield all([takeEvery(getType(actions.fetchAvailableAppointmentTimes.request), fetchTimeSlots)]);
}

/**
 * Saga for fetching time slots with logic to move on next/prev week range if current week has no data, depending on user navigation.
 */
export function* fetchTimeSlots(action: ReturnType<typeof actions.fetchAvailableAppointmentTimes.request>) {
  // action: ReturnType<typeof actions.fetchAvailableAppointmentTimes.request>

  try {
    const response: AxiosResponse<AvailableAppointmentTimes> = yield call(
      api.timeSlots.fetchTimesSlots,
      action.payload
    );

    yield put(actions.fetchAvailableAppointmentTimes.success(response.data));

    // 1. check if the response is empty
    // 2. if empty dispatch action to advance to next week range

    const swipeIndex = yield select(getSwipeIndex);
    // indicates in what direction data fetching will happen
    const nextWeekHandlerClicked = yield select(getNextWeekClick);
    // flag indicating no data availble for selected range & employee
    const isEmpty = isSlotProposalEmpty(response.data.slotProposals, action.meta.selectedEmployee);
    const startOfSelectionWeek = yield select(getStartOfSelectionWeek);

    const lastWeekInRange = moment(startOfSelectionWeek)
      .add(1, "w")
      .isAfter(moment().startOf("isoWeek").add(maxWeeksInFuture, "weeks"));

    // check if response is empty array and nextWeekHandler is cliked => will stop at first response with data going back in time as next week was not cliked, prev week was clicked
    if (isEmpty && !nextWeekHandlerClicked && swipeIndex > 0) {
      yield put(
        actions.setStartOfSelectionWeek(
          moment(startOfSelectionWeek).startOf("isoWeek").subtract(1, "week").toISOString()
        )
      );
      yield put(actions.subtractSwipeIndex());
    }
    // case for fetching data in future
    else if (isEmpty && !lastWeekInRange && swipeIndex !== 0) {
      yield put(
        actions.setStartOfSelectionWeek(moment(startOfSelectionWeek).startOf("isoWeek").add(1, "week").toISOString())
      );
      yield put(actions.addSwipeIndex());
    } else return;
  } catch (err) {
    return err.message ? yield put(actions.fetchAvailableAppointmentTimes.failure(err.message)) : null;
  }
}

export { fetchTimeSlots as fetchTimeSlotsSaga };
