import { RootState } from "../../store/root-reducer";
import moment, { Moment } from "moment";
import { AvailableEmployee } from "../../modules/treatments/types";
import { SlotProposal } from "./types";
import { createSelector } from "reselect";
import { getAppointmentResponseCode, getAppointmentResponseStartTime } from "../appointment/selectors";

const getState = (state: RootState) => state.appointmentTimeSlots;

/**
 *@returns slot proposals for a day of the week.
 * Filtering results if employee is selected.
 */
export const getSlotProposals = (state: RootState, dayOfWeek: Moment, selectedEmployee: string | null) => {
  const slotProposals = getState(state).availableAppointmentTimesInfo?.slotProposals ?? null;

  const dayOfWeekInFilterFormat = `${dayOfWeek.format("YYYY-MM-DD")}`;
  // If no data or no data for the specific day
  if (!slotProposals || !slotProposals[dayOfWeekInFilterFormat]) {
    return [];
  }
  // No employee selceted
  if (selectedEmployee === null) {
    return slotProposals[dayOfWeekInFilterFormat];
  }
  // Employee selected triggers filtering
  return slotProposals[dayOfWeekInFilterFormat].filter(({ employeeCombinations }) =>
    employeeCombinations.some((employeeCombination) => employeeCombination.includes(selectedEmployee))
  );
};

/**
 * @param selectedEmployeePublicId from Fromik, undefined if "NONE" employees selected
 * @param selectedStartTime selected slot start time from Formik
 * Checks that selected employee has previously selected time slot
 */
export const getIfSlotTimeExistForEmployee = (
  state: RootState,
  selectedEmployeePublicId: string | undefined,
  selectedStartTime: string
) => {
  // data with all time slots
  const allSlots = getState(state).availableAppointmentTimesInfo?.slotProposals;
  // creating single array with all data
  const allDataInOneArray = Object.values(allSlots ?? {}).reduce((acc: SlotProposal[], curr: SlotProposal[]) => {
    acc.push(...curr);
    return acc;
  }, []);
  // all time slots in selected range for selected doctor
  const allDataForSelectedDoctor = allDataInOneArray.filter((slotProposal: SlotProposal) =>
    slotProposal.employeeCombinations.some((employeeCombination) =>
      employeeCombination.includes(selectedEmployeePublicId ?? "")
    )
  );
  // check if previously selected time is included in time slots of selected employee
  const isSelectedTimeIncluded = allDataForSelectedDoctor
    .map((slotProposal: SlotProposal) => slotProposal.startTime)
    .includes(selectedStartTime);

  // returns true if all employees are selected
  if (selectedEmployeePublicId === undefined) {
    return true;
  }
  return isSelectedTimeIncluded;
};

export const getAllSlotProposalsForTimeRange = (state: RootState) =>
  getState(state).availableAppointmentTimesInfo?.slotProposals;

export const getFromDate = (state: RootState) => getState(state).availableAppointmentTimesInfo?.fromDate;

export const getToDate = (state: RootState) => getState(state).availableAppointmentTimesInfo?.toDate;

export const getAvailableAppointmentsErrorMessage = (state: RootState) => getState(state).errorMessage;

export const getSelectedTimeDate = (state: RootState) => getState(state).selectedTimeDate;

export const getAppointmentTimeSlotsError = (state: RootState) => getState(state).errorMessage;

export const getStartOfSelectionWeek = (state: RootState) => getState(state).startOfSelectionWeek;

export const getAvailableDataFromSlotProposals = (state: RootState) => {
  return getState(state).availableAppointmentTimesInfo?.slotProposals ?? null;
};

export const getNextWeekClick = (state: RootState) => {
  return getState(state).nextWeekClick;
};

export const getIsFetchingSlots = (state: RootState) => {
  return getState(state).isFetching;
};

export const getSwipeIndex = (state: RootState) => {
  return getState(state).swipeIndex;
};

export const getMobileViewWeeks = (state: RootState) => {
  return getState(state).mobileViewWeeks;
};

export const getEndOfTimeframeWithoutSlots = (state: RootState) => {
  return getState(state).endOfTimeframeWithoutSlots;
};

/**
 * @returns boolean for displaying message in case currently selected week has no slots for display/selection, considering selected employee.
 */
export const getNoProposalsAvailableForCurrentWeek = (
  state: RootState,
  datesOfWeek: Moment[],
  selectedEmployee: AvailableEmployee | null | string,
  isFirstWeekOfSelection: boolean = false
) => {
  const isFetchingSlots = getIsFetchingSlots(state);
  const selectedWeek = datesOfWeek.map((dayOfWeek) =>
    getSlotProposals(state, dayOfWeek, typeof selectedEmployee !== "string" ? selectedEmployee?.publicId ?? null : null)
  );

  if (isFetchingSlots) {
    return false;
  }
  // If the currently selected week is the first week of the range, data for days before "today" are not considered.
  return (isFirstWeekOfSelection ? selectedWeek.slice(moment().day()) : selectedWeek).every((day) => day.length === 0);
};

export const getDataForDateTimePicker = createSelector(
  getAppointmentTimeSlotsError,
  getStartOfSelectionWeek,
  getIsFetchingSlots,
  getSwipeIndex,
  getAppointmentResponseStartTime,
  getAppointmentResponseCode,
  (
    errorMessage,
    startOfSelectionWeek,
    isFetchingSlots,
    swipeIndex,
    appointmentResponseStartTime,
    appointmentResponseCode
  ) => {
    return {
      errorMessage,
      startOfSelectionWeek,
      isFetchingSlots,
      swipeIndex,
      appointmentResponseStartTime,
      appointmentResponseCode,
    };
  }
);

export const getDataForDateTimePickerMobile = createSelector(
  getMobileViewWeeks,
  getIsFetchingSlots,
  getAppointmentTimeSlotsError,
  getAppointmentResponseStartTime,
  getAppointmentResponseCode,
  (mobileViewWeeks, isFetchingSlots, errorMessage, appointmentResponseStartTime, appointmentResponseCode) => {
    return { mobileViewWeeks, isFetchingSlots, errorMessage, appointmentResponseStartTime, appointmentResponseCode };
  }
);
