import { Grid } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { PatientData } from "../modules/patients/types";
import { Treatment } from "../modules/treatments/types";
import { AvailableEmployee } from "../modules/treatments/types";
import { getPhoneVerificationData } from "../modules/phoneVerification/selectors";
import { useSelector } from "react-redux";

export interface FormWizardChildProps {
  validate: () => void;
}

/** */
export interface FormValues {
  acceptedPrivacyPolicy: boolean;
  clientAccountDetails: PatientData;
  employeeCombination: string[] | null;
  // readCoronaInfo: boolean;
  // cancellationPolicy: boolean;
  selectedEmployee: AvailableEmployee | null | string;
  slotProposal: {
    startTime: string;
    employeeCombinations: string[] | null;
    originalEmployeeCombinations: string[][] | null;
  } | null;
  earliestArrivalTime: string;
  treatment: Treatment | null;
  verificationCodeOTP: string;
}

// interface for props passed into fragment children
interface FormWizardRenderProps {
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  page?: number;
  lastPage?: number;
}
interface FormWizardProps {
  /** Booking components that are rendered as fragment children */
  children: (props: FormWizardRenderProps) => React.ReactElement<{ children: React.ReactElement[] }>;
  /** Handler for submission called on every step of the booking process */
  handleSubmission: (
    page: number,
    isLastPage: boolean,
    nextPage: () => void,
    previousPage: () => void
  ) => (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  /** Definition of values stored in Fromik */
  initialValues: FormValues;
  /** Indicates whether we're reserving a slot as opposed to booking an appointmen */
  isSlot: boolean;
}

/**
 * Provides functionality of stepping through components for appointment/slot reservation.
 *
 */
export const FormWizard = (props: FormWizardProps) => {
  const { children } = props;
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const step = params.get("step");
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const { institutionPhoneNumber, verificationCodeUUID, codeConfirmation, codeSubmissionError, codeRequestError } =
    useSelector(getPhoneVerificationData);

  useEffect(() => {
    // Setting page value on the change of step, page is zero based
    if (step) {
      setPage(Number(step) - 1);
    }
  }, [step]);

  /** Handler for going to next page */
  const nextPage = () => {
    // updating pg value by adding 1, unless already on the last child element, Math.min returns smalles passed values.
    const pg = Math.min(page + 1, React.Children.toArray(children({}).props.children).length - 1);
    setLastPage(page);
    setPage(pg);
    history.push(`${location.pathname}?step=${pg + 1}`);
  };

  /** Handler for going to previous page */
  const previousPage = () => {
    // updating pg value by subtracting 1, unless already on the last child element.

    const pg = Math.max(page - 1, 0);
    setLastPage(page);
    setPage(pg);
    history.push(`${location.pathname}?step=${pg + 1}`);
  };

  // Value containing direct children of FormWizard(fragmet) from props.
  // Params passed for render props fn that will be available inside of everthing enclosed with fragment.
  const fragment = children({ goToNextPage: nextPage, goToPreviousPage: previousPage, page, lastPage });

  // Elements passed into fragment for rendering/components for individual steps.
  const fragmentChildren = fragment.props.children;

  const isLastPage = page === React.Children.toArray(fragmentChildren).length - 1;

  const submissionHandler = props.handleSubmission(page, isLastPage, nextPage, previousPage);

  const activePage = React.Children.toArray(fragmentChildren)[page];

  // Running checks and getting validation fn passed as a prop to each component that is runned when validating "active page"
  const validate = (values: FormValues) => {
    if (typeof activePage === "object") {
      if ("props" in activePage) {
        if ("validationFn" in activePage.props) {
          return activePage.props.validationFn(values);
        }
      }
    }
  };

  return (
    <Formik onSubmit={submissionHandler} initialValues={props.initialValues} validate={validate}>
      {({ values }) => {
        return (
          <Grid container direction="column" component={Form}>
            {activePage}
            {values.treatment === null && <Redirect to={{ search: "?step=1", pathname }} />}
          </Grid>
        );
      }}
    </Formik>
  );
};
