import { AxiosInstance } from "axios";
import { WaitingQueueSlotFetchOptions, WaitingQueueReserveSlotOptions } from "./types";

export const waitingQueueSlotAPI = (api: AxiosInstance) => ({
  checkStatus: (publicId: string) => api.get(`/is-waiting-queue-slot-valid/${publicId}`),
  fetch: ({
    employeeCombination,
    treatmentPublicId,
    minutesToEarliestArrivalTime,
    earliestPossibleArrivalTime,
  }: WaitingQueueSlotFetchOptions) =>
    api.post(
      `/treatment-types/${treatmentPublicId}/next-waiting-queue-slot`,
      { employeeCombination },
      {
        params: { minutesToEarliestArrivalTime, earliestPossibleArrivalTime },
        headers: { "Content-Type": "application/json" },
      }
    ),
  reserveSlot: ({
    formData: { treatment, ...rest },
    minutesToEarliestArrivalTime,
    earliestPossibleArrivalTime,
  }: WaitingQueueReserveSlotOptions) =>
    api.post(`/treatment-types/${treatment?.publicId}/remote-join-waiting-queue`, rest, {
      params: { minutesToEarliestArrivalTime, earliestPossibleArrivalTime },
      headers: { "Content-Type": "application/json" },
    }),
});
