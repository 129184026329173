import { DeepReadonly } from "utility-types";
import { InstitutionDetails } from "../institution/types";

export enum TreatmentErrorCode {
  NOT_FOUND = "Request failed with status code 404",
  BAD_REQUEST = "Request failed with status code 400",
}
export interface Treatment {
  /**
   * Id used for time slots fetching
   */
  publicId: string;
  name: string;
  description: string;
  duration: string;
  /**
   * Opening hours of selected treatment, used for time selection within open hours of the treatment
   */
  openingHoursOfToday: Readonly<openingHoursOfToday[]>;
  /**
   * Treatment available for slot reservation
   */
  availableForRemoteCheckIn: boolean;
  /**
   * Treatment shown as available but might be closed/open
   */
  openForRemoteCheckIn: boolean;
  /**
   * Treatment available for appointment booking
   */
  availableForOnlineAppointmentBooking: boolean;
  /**
   * Possible consultation options video/local
   */
  videoConsultationMode: string;

  employeeSelectable: boolean;
  /**
   * All employees for specific treatment
   */
  availableEmployees: Readonly<AvailableEmployee[]>;
}

export interface openingHoursOfToday {
  startTime: string;
  endTime: string;
}

export interface AvailableEmployee {
  /**
   * Id used for filtering
   */
  publicId: string;
  /**
   * Displayed name
   */
  name: string;
  /**
   * Indicates if employee is available for slot reservation
   */
  openForRemoteCheckIn: boolean;
}
interface TreatmentStateNonReadonly {
  /**
   * Id's of all treatments to indicate ordering
   */
  allIds: string[];
  /**
   * Individual treatments stored as objects with their ids' as keys
   */
  byId: {
    [id: string]: Treatment;
  };
  /** Time stamp of last successful treatments fetch  */
  lastFetch: number | null;
  isFetching: boolean;
  error: string | null;
}

export type TreatmentState = DeepReadonly<TreatmentStateNonReadonly>;

export interface InstitutionDetailsAndTreatments {
  institutionDetails: InstitutionDetails | null;
  treatmentTypes: Treatment[];
}
