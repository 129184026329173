import React from "react";
import { Grid, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

interface WeekDaysHeaderProps {
  /** Moment representation of day */
  dayOfWeek: Moment;
  /** Indicates week in selected range has no data to display*/
  weekHasNoSlots: boolean;
}
/**
 * Formatted date for a single day of the week in the displayed header on desktop view.
 */
export const WeekDaysHeader = ({ dayOfWeek }: WeekDaysHeaderProps) => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  return (
    <Grid item xs>
      <Grid container direction="column" alignItems="center">
        {" "}
        <Typography>{dayOfWeek.format("ddd")}</Typography>
        <Typography>{dayOfWeek.format("DD.MM.")}</Typography>{" "}
      </Grid>
    </Grid>
  );
};
