import * as actions from "./actions";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { AppointmentRequest, AppointmentReserveResponse, AppointmentSlotResponseCode } from "./types";
import { getType } from "typesafe-actions";
import api from "../services/api";

export default function* bookAppointmentSaga() {
  yield all([
    takeEvery(getType(actions.getAppointmentSlot.request), bookAppointment),
    // fork(checkAppointmentValidityWatcher),
  ]);
}

/**
 * Submission of appointment on the last booking step.
 * If successful, redirection to AppointmentReservationFinished component, otherwise setting appropriate error message or re direction to step 2.
 */
export function* bookAppointment(action: ReturnType<typeof actions.getAppointmentSlot.request>) {
  // orgUnitUUID for redirection on step 2
  // location? for the testing
  const orgUnitUUID = action.meta.history.location?.pathname.split("/")[1];

  try {
    const response: AxiosResponse<AppointmentReserveResponse> = yield call(
      api.appointment.appointmentDetails,
      action.payload
    );
    if (response.data.responseCode === AppointmentSlotResponseCode.CONFIRMED) {
      const remoteWaitingToken = response.data.remoteWaitingToken;
      action.meta.history.replace(`/book-appointment-finished/${remoteWaitingToken}`);
    } else if (response.data.responseCode === AppointmentSlotResponseCode.UNAVAILABLE) {
      action.meta.setSubmitting(false);
      action.meta.history.push(`/${orgUnitUUID}/book-appointment?step=2`);
    } else if (
      response.data.responseCode === AppointmentSlotResponseCode.PENDING ||
      response.data.responseCode === AppointmentSlotResponseCode.INTERNAL_ERROR ||
      response.data.responseCode === AppointmentSlotResponseCode.ERROR_CREATING_CLIENT
    ) {
      action.meta.setSubmitting(false);
      action.meta.history.replace({
        pathname: `/${action.meta.pathName.split("/")[1]}`,
        state: { slotSubmissionError: true },
      });
    } else {
      action.meta.setSubmitting(false);
      yield put(actions.getAppointmentSlot.failure(response.data.responseCode));
    }
  } catch (err) {
    if (err.message) {
      yield put(actions.getAppointmentSlot.failure(err.message));
    }
  }
}

export { bookAppointment as bookAppointmentSaga };
