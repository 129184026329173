import { Grid, Button, Skeleton, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import moment, { Moment } from "moment";
import { getSlotProposals, getIsFetchingSlots } from "../../../modules/timeSlots/selectors";
import { RootState } from "../../../store/root-reducer";
import { sortByStartTime } from "../../../modules/timeSlots/utils";
import { isSafari } from "../../../utils/safariCheck";
// import clsx from "clsx";
import { AvailableEmployee } from "../../../modules/treatments/types";

// const useStyles = makeStyles((theme) => ({
//   buttonStyle: {
//     padding: "18px",
//     backgroundColor: grey[100],
//     marginBottom: "4px",
//   },
//   skeletonButtonStyle: {
//     padding: theme.spacing(0),
//     marginBottom: theme.spacing(1),
//   },
//   safariCorrection: {
//     paddingTop: theme.spacing(3.5),
//     paddingBottom: theme.spacing(3.5),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   skeleton: { marginBottom: 4 },
// }));

interface TimeSelectionDayColumnProps {
  isFetchingSlots: boolean;
  /** Moment representation of displayed day */
  dayOfWeek: Moment;
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for time selection */
  onTimeClickHandler: (slotProposal: {
    startTime: string;
    /** Possible employee combinations for selected treatment*/
    employeeCombinations: string[][];
  }) => void;
  /** Selected emplyee representation */
  selectedEmployee: AvailableEmployee | null | undefined | string;
  /** Already selected start time */
  selectedStartTime: string;
  /** Ref to selected element */
  setButtonRef: (elem: HTMLButtonElement | null) => void;
  /** Handler for setting slot availability */
  slotProposal: {
    startTime: string;
    employeeCombinations: string[] | null;
    originalEmployeeCombinations: string[][] | null;
  } | null;
}
/**
 * Renders column of Buttons for a time selection of the appointment or skeletons, depending on fetched data.
 */
export const TimeSelectionDayColumn = ({
  selectedEmployee,
  slotProposal,
  dayOfWeek,
  onTimeClickHandler,
  selectedStartTime,
  setButtonRef,
  isFetchingSlots,
}: TimeSelectionDayColumnProps) => {
  // const classes = useStyles();

  const slotProposals = useSelector((state: RootState) =>
    getSlotProposals(state, dayOfWeek, typeof selectedEmployee !== "string" ? selectedEmployee?.publicId ?? null : null)
  );

  const skeletonBackbone = [1, 2, 3, 4, 5, 6, 7];

  if (!slotProposals) {
    return null;
  }

  return (
    <Grid item container direction="column" alignItems="center" xs={2} wrap="nowrap">
      {isFetchingSlots
        ? skeletonBackbone.map((_singleSkeleton, index) => (
            <Skeleton
              variant="rectangular"
              animation="pulse"
              width={70}
              height={57}
              style={{ marginBottom: "4px" }}
              key={index}
            />
          ))
        : /** Days of week before "today" have skeleton representation  */ dayOfWeek.isSameOrBefore(moment())
        ? skeletonBackbone.map((_singleSkeleton, index) => (
            <Skeleton
              variant="rectangular"
              animation={false}
              width={70}
              height={57}
              style={{ marginBottom: "4px" }}
              key={index}
            />
          ))
        : /** Days of week without data have skeleton representation */
        slotProposals.length > 0
        ? sortByStartTime(slotProposals, "startTime").map(({ startTime, employeeCombinations }, index) => {
            /** Parsing time to correct dispaly format */
            const [hours, minutes] = moment(startTime).format("HH:mm").split(":");
            /** Check if time has already been selected */
            const selectedTime = selectedStartTime === startTime;

            return (
              <Box key={index}>
                <Button
                  ref={selectedTime ? setButtonRef : null}
                  style={{
                    color: selectedTime ? "#fff" : "inherit",
                    backgroundColor: selectedTime ? "green" : "inherit",
                    padding: "18px",
                    marginBottom: "4px",
                  }}
                  onClick={() => {
                    onTimeClickHandler({ startTime, employeeCombinations });
                  }}
                  variant="contained"
                  // className={clsx({ [classes.buttonStyle]: true, [classes.safariCorrection]: isSafari })}
                  sx={{
                    padding: "18px",
                    backgroundColor: grey[100],
                    marginBottom: "4px",
                    paddingTop: isSafari ? 3.5 : undefined,
                    paddingBottom: isSafari ? 3.5 : undefined,
                    display: isSafari ? "flex" : undefined,
                    flexDirection: isSafari ? "column" : undefined,
                    alignItems: isSafari ? "center" : undefined,
                  }}
                  key={index}
                >
                  {`${hours}:${minutes}`}
                </Button>
              </Box>
            );
          })
        : skeletonBackbone.map((_singleSkeleton, index) => (
            <Box key={index}>
              <Skeleton
                variant="rectangular"
                animation={false}
                width={70}
                height={57}
                // className={classes.skeleton}
                style={{
                  marginBottom: "4px",
                }}
                key={index}
              />
            </Box>
          ))}
    </Grid>
  );
};
