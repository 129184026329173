import { Button, Grid, Typography, CircularProgress, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormikErrors, useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { errorStatusToMessageMap } from "../../modules/waitingQueueSlots/constants";
import { getWaitingQueueSlot, getWaitingQueueSlotError } from "../../modules/waitingQueueSlots/selectors";
import { FormValues } from "../../components/FormWizard";
import { PrivacyPolicyConsent } from "../../branding/vanilla/PrivacyPolicyConsent";
import { CovidStatusConfirmation } from "../../components/CovidStatusConfirmation";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { OverlineLabelValue } from "../../components/OverlineLabelValue";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";
import { CancellationPolicy } from "../../components/CancellationPolicy";
import { format, parseISO } from "date-fns";
interface SlotConfirmationProps {
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Validation function to be used by Formik. Never gets used inside of the component */
  validationFn: (
    values: Pick<FormValues, "acceptedPrivacyPolicy">
  ) => FormikErrors<Pick<FormValues, "acceptedPrivacyPolicy">>;
}

// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[1],
//     padding: theme.spacing(2),
//   },
//   header: {
//     paddingBottom: 40,
//   },
//   confirmationTextWrap: {
//     paddingBottom: theme.spacing(2),
//   },
// }));

/**
 * Component validation fn, passed as a prop to Formik in FromWizard component.
 */
export const validateFormConfirmation = (t: TFunction) => (values: Pick<FormValues, "acceptedPrivacyPolicy">) => {
  const errors: { acceptedPrivacyPolicy?: string; readCoronaInfo?: string; cancellationPolicy?: string } = {};
  if (!values.acceptedPrivacyPolicy) {
    errors.acceptedPrivacyPolicy = t("validation.privacyPolicy");
  }
  // if (!values.readCoronaInfo) {
  //   errors.readCoronaInfo = t("validation.coronaInfo");
  // }
  // if (!values.cancellationPolicy) {
  //   errors.cancellationPolicy = t("validation.coronaInfo");
  // }
  return errors;
};

/**
 * Verifying correct data input and privacy policy consent/COVID check
 */
export const SlotConfirmation = ({ goToPreviousPage }: SlotConfirmationProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();
  // Data from previous steps stored and used from Formik
  const {
    values: {
      clientAccountDetails: { dateOfBirth, firstName, gender, lastName, phoneNumber, email },
      treatment,
      selectedEmployee,
    },
    isSubmitting,
  } = useFormikContext<FormValues>();

  // Checking if there is waiting queue slot error in the state and finding correct message for it with errorStatusToMessageMap.
  const errorCode = useSelector(getWaitingQueueSlotError);
  const errorMessage =
    errorCode && errorCode in errorStatusToMessageMap ? errorStatusToMessageMap[errorCode] : undefined;

  const availableEmployees = treatment?.availableEmployees ?? [];
  const selectedEmployeeName = availableEmployees?.find(
    (employee) => employee.publicId === (typeof selectedEmployee !== "string" && selectedEmployee?.publicId)
  );

  const waitingQueueSlot = useSelector(getWaitingQueueSlot);

  const stepInfoText = t("slot.confirmationTitle");

  const displayEmail = email !== "";

  return (
    <>
      <Box pb={2}>
        {/** Header with navigation followed by display of previously selected data */}
        <TitleNavigationHeader
          goToPreviousPage={goToPreviousPage}
          isSlotReservation={true}
          stepInfoText={stepInfoText}
          lastStep={true}
          errorMessage={errorMessage}
        />
      </Box>
      <Box
        p={2}
        sx={{
          backgroundColor: "background.paper",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Box pb={2}>
          <Typography variant="h6">{t("confirmation.title")}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">{t("confirmation.infoText")}</Typography>
        </Box>
        <Grid container item spacing={4}>
          <OverlineLabelValue
            label={t("patientData.treatment")}
            displayValue={treatment ? treatment.name : ""}
            xs={6}
          />
          {treatment?.employeeSelectable && selectedEmployee !== "none" ? (
            <OverlineLabelValue
              label={t("patientData.selectedEmployee")}
              displayValue={selectedEmployeeName?.name ?? ""}
              xs={6}
            />
          ) : null}
          <OverlineLabelValue
            label={t("slot.waitTimeConfirmation.timeEstimationTitle")}
            displayValue={
              waitingQueueSlot?.earliestStartTime
                ? format(parseISO(waitingQueueSlot.earliestStartTime), "HH:mm'h'")
                : ""
            }
            xs={6}
          />
          <OverlineLabelValue label={t("patientData.firstName")} displayValue={firstName} xs={6} />
          <OverlineLabelValue label={t("patientData.lastName")} displayValue={lastName} xs={6} />
          <OverlineLabelValue label={t("patientData.gender")} displayValue={gender} xs={6} />
          <OverlineLabelValue label={t("patientData.birthDate")} displayValue={dateOfBirth} xs={6} />
          <OverlineLabelValue
            label={t("patientData.phoneNmb")}
            displayValue={parsePhoneNumberFromString(phoneNumber, "AT")?.formatInternational()}
            phoneValue={true}
            xs={6}
          />
          {displayEmail ? <OverlineLabelValue label={t("patientData.email")} displayValue={email} xs={6} /> : null}
        </Grid>
        {/** Covid status, PrivacyPolicyConsent and CancellationPolicy checkboxes */}
        <Box>{/* <CovidStatusConfirmation /> */}</Box>

        <Box>{/* <CancellationPolicy /> */}</Box>

        <Box>
          <PrivacyPolicyConsent />
        </Box>
        {errorMessage && (
          <Grid item>
            <Typography color="error">{t("errors.submissionError")}</Typography>
          </Grid>
        )}
        <Box>
          <Button fullWidth color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={30} /> : t("confirmation.buttonConfirmation")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
