import { createAsyncAction } from "typesafe-actions";
import { InstitutionDetailsAndTreatments } from "./types";

/**
 * Dispatched on load of SlotAppointmentFork and TreatmentSelect with  useFetchInstitutionDetailsAndAllTreatments hook.
 * Triggers request to get institution details from the server.
 * Uses orgUnitUUID from URL parmas in payload to get correct data for the institution.
 */

export const fetchInstitutionDetailsAndTreatments = createAsyncAction(
  "INSTITUTIONDETAILSANDTREATMENTS_FETCH_REQUEST",
  "INSTITUTIONDETAILSANDTREATMENTS_FETCH_SUCCESS",
  "INSTITUTIONDETAILSANDTREATMENTS_FETCH_FAILURE"
)<string, InstitutionDetailsAndTreatments, string>();
