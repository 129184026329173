import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivacyPolicy } from "../../components/PrivacyPolicy";
import { AppointmentReservationFinished } from "../../pages/AppointmentReservationFinished";
import { SlotReservationFinished } from "../../pages/SlotReservationFinished";
import { SlotAppointmentFork } from "../../pages/SlotAppointmentFork";
import { AppointmentBookingForm } from "../../pages/AppointmentBooking";

import { SlotBookingForm } from "../../pages/SlotReservation";
import { MissingOrgUnitIdentifier } from "../../pages/MissingOrgUnitIdentifier";

/**
 * The purpose of the app is to enable a user to book an appointment sometime in the future or reserve a slot for an appointment on the day of reservation.
 * On the App loading check for orgUnitUUID from URL params.
 *
 * SlotAppointmentFork is the entry point for the user, Loading available treatments and providing links for appointment booking and slot reservation.
 * Appointment booking starts with available treatment selection, followed by time doctor/slot selection and personal data input.
 * Upon appointment submission and server response about submission status, the user is prompted to confirm the booking on a mobile device.
 *
 * Slot reservation start with available treatment selection, followed by the travel time/doctor selection. This triggers a request to the server checking if a slot with selected travel time is available and creates a timestamp that is used before final submission of slot reservation to check time elapsed between travel time selection and slot submission, possibly taking the user back to the step for travel time selection so that submission is not done with outdated data.
 * After personal data input and confirmation, the user submits slot reservation, and depending on the server response is prompted to confirm the booking on a mobile device.
 */

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/privacy-policy" render={() => <PrivacyPolicy />} />
      <Route exact path="/:orgUnitUUID" component={SlotAppointmentFork} />
      <Route
        path="/:orgUnitUUID/book-appointment"
        children={({ match }) => <AppointmentBookingForm orgUnitUUID={match ? match.params.orgUnitUUID : undefined} />}
      ></Route>

      <Route
        path="/:orgUnitUUID/reserve-slot"
        children={({ match }) => (
          <SlotBookingForm institutionIdentifier={match ? match.params.orgUnitUUID : undefined} />
        )}
      ></Route>
      <Route path="/reserve-slot-finished/:token" component={SlotReservationFinished} />
      <Route path="/book-appointment-finished/:token" component={AppointmentReservationFinished} />
      <Route component={MissingOrgUnitIdentifier} />
    </Switch>
  );
};
