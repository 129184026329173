import { Treatment } from "../institution/types";

export function sortTreatmentsByName({ name: name1 }: Treatment, { name: name2 }: Treatment) {
  if (name1 < name2) {
    return -1;
  } else if (name1 === name2) {
    return 0;
  } else {
    return 1;
  }
}
