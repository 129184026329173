import { _DeepReadonlyObject } from "utility-types/dist/mapped-types";
import { RootState } from "../../store/root-reducer";
import { getInstitutionPhoneNumber } from "../institution/selectors";
import { Treatment } from "../treatments/types";
import { createSelector } from "reselect";
import { sortTreatmentsByName } from "./utils";

const getState = (state: RootState) => state.treatments;

export const getAllTreatments = (state: RootState) => {
  const { allIds, byId } = getState(state);
  return allIds.map((id) => byId[id]);
};

export const getTreatmentsIsFetching = (state: RootState) => getState(state).isFetching;

/**
 * Gets all treatments for remote check-in with highlighted treatment at index 0
 * @returns array of treatments available for remote check sorted by highlight and openForRemoteCheckIn properties
 */
export const getAllAvailableRemoteCheckInTreatments = (state: RootState) => {
  const sortedAvailableTreatments = getAllTreatments(state).reduce(
    (acc, curr) => {
      // skipping treatments unavailable for remote check in
      if (!curr.availableForRemoteCheckIn) {
        return acc;
      }
      // treatments available for remote check-in, open and with/without publicId included in highlightedPublicIds array
      else if (curr.availableForRemoteCheckIn && curr.openForRemoteCheckIn) {
        acc.open.push(curr);
      }
      // closed treatments available for remote check-in
      else {
        acc.closed.push(curr);
      }
      return acc;
    },
    { open: [], closed: [] } as {
      open: Treatment[];
      closed: Treatment[];
    }
  );
  // returning array with open treatments followed by closed
  return [
    ...sortedAvailableTreatments.open.sort(sortTreatmentsByName),
    ...sortedAvailableTreatments.closed.sort(sortTreatmentsByName),
  ];
};

/**
 * Get all treatments open for remote check-in
 * Important for correct display of links on the fork page.
 * @returns array of treatments relevant for slot booking and have openForRemoteCheckIn === true.
 */
export const getAllOpenTreatmentsAvailableForRemoteCheckIn = (state: RootState) => {
  return getAllTreatments(state)
    .filter((treatment) => treatment.availableForRemoteCheckIn)
    .filter(({ openForRemoteCheckIn }) => openForRemoteCheckIn)
    .sort(sortTreatmentsByName);
};

/**
 * Gets all treatments for appointment booking
 * Important for correct display of links on the fork page.
 * @returns array of treatments relevant for appointment booking
 */
export const getAllAvailableAppointmentBookingTreatments = (state: RootState) => {
  const sortedAvailableTreatments = getAllTreatments(state).reduce(
    (acc, curr) => {
      // treatments available for appointment booking and with publicId included in highlightedPublicIds array
      if (curr.availableForOnlineAppointmentBooking) {
        acc.open.push(curr);
      }
      return acc;
    },
    { open: [] } as {
      open: Treatment[];
    }
  );

  return sortedAvailableTreatments.open.sort(sortTreatmentsByName);
};

export const getLastFetch = (state: RootState) => getState(state).lastFetch;

export const getIsFetching = (state: RootState) => getState(state).isFetching;

export const getTreatmentsError = (state: RootState) => getState(state).error;

export const getSlotAppointmentForkData = createSelector(
  getAllAvailableAppointmentBookingTreatments,
  getAllOpenTreatmentsAvailableForRemoteCheckIn,
  getLastFetch,
  getIsFetching,
  getInstitutionPhoneNumber,
  (
    appointmentTreatmentsAvailable,
    allOpenTreatmentsAvailableForRemoteCheckIn,
    treatmentsLastFetch,
    isFetchingTreatments,
    institutionPhoneNumber
  ) => {
    return {
      appointmentTreatmentsAvailable,
      allOpenTreatmentsAvailableForRemoteCheckIn,
      treatmentsLastFetch,
      isFetchingTreatments,
      institutionPhoneNumber,
    };
  }
);

export const getTreatmentSelectData = createSelector(
  getTreatmentsError,
  getTreatmentsIsFetching,
  (treatmentsError, treatmentsIsFetching) => {
    return { treatmentsError, treatmentsIsFetching };
  }
);
