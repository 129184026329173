import { Grid, Button, Typography, Paper, FormHelperText, Skeleton } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  fetchAvailableAppointmentTimes,
  setWeekNumberOfSelection,
  addMobileViewWeek,
} from "../../modules/timeSlots/actions";
import { useDispatch } from "react-redux";
import { getMobileTimeFrame, getDaysShowingAppointments } from "../../modules/timeSlots/utils";
import { Field, FormikErrors, getIn, FormikValues, useField, useFormikContext } from "formik";
import {
  getNoProposalsAvailableForCurrentWeek,
  getDataForDateTimePickerMobile,
} from "../../modules/timeSlots/selectors";
import { ErrorTimeSlot } from "../../components/WarningErrorMessages";
import { SingleDayAccordion } from "./dateTimePickerMobile/SingleDayAccordion";
import { SlotProposal, AvailableEmployee } from "../../modules/timeSlots/types";
import { RootState } from "../../store/root-reducer";
import { EmployeeSelect } from "./EmployeeSelect";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { AppointmentSlotResponseCode } from "../../modules/appointment/types";
import { FormValues } from "../../components/FormWizard";

export const MAX_WEEKS_IN_FUTURE = 5;

// const useStyle = makeStyles((theme) => ({
//   buttonWrap: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(2),
//   },
//   columnsWrap: {
//     overflowY: "scroll",
//     paddingTop: theme.spacing(1),
//     height: 430,
//     margin: 0,
//   },
//   textArea: {
//     paddingTop: theme.spacing(1),
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
//   textField: {
//     margin: theme.spacing(2),
//     minWidth: 200,
//   },
//   skeletonStyle: {
//     marginBottom: 3,
//   },
//   componentContainer: {
//     padding: theme.spacing(1),
//   },
//   paperStyle: {
//     maxWidth: "100%",
//   },
//   showMoreBttn: { marginTop: 20, width: "100%" },
// }));

interface DateTimePickerProps {
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Validation function to be used by Formik. Never gets used inside of the component */
  validationFn: (values: FormValues) => FormikErrors<FormikValues>;
}

export const validateTreatmentStartTime = (t: TFunction) => (values: FormValues) => {
  if (values.slotProposal?.startTime === "") {
    return { slotProposal: { startTime: t("validation.slot") } };
  }
  return {};
};
/**
 * Provides layout for appointment time selection in mobile view .
 * Depending on the fetched data, rendering accordions with selectable buttons or skeletons.
 * Option for filtering slot proposals by employees, if more than one employee is available.
 * If element is selected, it's available in setButtonRef for scrolling into view when coming back on the step from .
 */
export const DateTimePickerMobile = ({ goToNextPage, goToPreviousPage }: DateTimePickerProps) => {
  const dispatch = useDispatch();
  // const classes = useStyle();
  const { t } = useTranslation();

  // Flag for slot deselection on emplyee change in case new employye doesn't have it.
  const [slotDeselected, setSlotDeselected] = useState(false);

  // Using Formik hook to expose field and helpers for slot proposal
  const [field, meta, helpers] = useField({ name: "slotProposal" });

  // Using FormikContext for setting value of field property other than slotProposal
  const { setFieldValue, errors, validateForm, values } = useFormikContext<FormValues>();

  // mobile weeks is nmb of weeks displayed for mobile view, appointmentResponseStartTime & appointmentResponseCode is data relevant after submission attemp for correct continuation of booking
  const { mobileViewWeeks, isFetchingSlots, errorMessage, appointmentResponseStartTime, appointmentResponseCode } =
    useSelector(getDataForDateTimePickerMobile);

  // Adding additionl week to the range
  const onShowNextWeekHandler = () => dispatch(addMobileViewWeek());
  const treatmentTypePublicId = values.treatment?.publicId ?? "";

  // Upadates correct data range with change of mobilViewWeeks
  useEffect(() => {
    if (!values.treatment) {
      return;
    }
    const timeFrame = getMobileTimeFrame(mobileViewWeeks, treatmentTypePublicId);
    dispatch(fetchAvailableAppointmentTimes.request(timeFrame, { selectedEmployee: values.selectedEmployee }));
  }, [mobileViewWeeks]);

  // Add days visible in mobile view
  const daysShowingAppointments = getDaysShowingAppointments(mobileViewWeeks);

  // Flag for message dispaly in case of no data in selected range
  const timeFrameHasNoSlots = useSelector((state: RootState) =>
    getNoProposalsAvailableForCurrentWeek(state, daysShowingAppointments, values.selectedEmployee ?? null)
  );

  // Indicator that slot is no longer available on the server side
  const slotNoLongerAvailable =
    appointmentResponseCode === AppointmentSlotResponseCode.UNAVAILABLE &&
    appointmentResponseStartTime === values.slotProposal?.startTime;

  // Callback that takes button element and scrolls it into view when re-rendering component after selection of the time.
  const setButtonRef = (elem: HTMLDivElement | null) => {
    if (!elem) {
      return;
    } else {
      elem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  const availableEmployees = values.treatment?.availableEmployees ?? [];

  // Defining number of skeleton elements
  const skeletonBackbone = [1, 2, 3, 4, 5, 6, 7];

  // Formink validation error
  const error = getIn(errors, field.name);

  const nextStepHandler = () => {
    validateForm();
  };

  /**
   * Called on time selection with values about time of selected treatment and employees stored in the formik and local store(time only). Takes user to the next step.
   */
  const onTimeClickHandler = (slotProposal: SlotProposal) => {
    // checking that if employee is selected, its publicId exists in employee combination of slotProposal
    const employeeCombinations =
      values.selectedEmployee === null
        ? null
        : typeof values.selectedEmployee !== "string"
        ? null
        : slotProposal.employeeCombinations.find((employeeCombination) =>
            typeof values.selectedEmployee !== "string" &&
            values.selectedEmployee?.publicId !== null &&
            values.selectedEmployee?.publicId !== undefined
              ? employeeCombination.includes(values.selectedEmployee?.publicId)
              : false
          );
    helpers.setValue(
      {
        startTime: slotProposal.startTime,
        employeeCombinations: employeeCombinations ?? null,
        originalEmployeeCombinations: slotProposal.employeeCombinations,
      },
      true
    );
    // setting start time in the local store

    dispatch(setWeekNumberOfSelection(slotProposal.startTime));

    if (goToNextPage) {
      goToNextPage();
    }
  };
  /**
   * Employee change handler storing value in the Formik without validation
   * @param event by default has value of "NONE"
   */
  const handleEmployeeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // If selected employee doesn't have already selected slot, slot is deleted from Formik
    const selectedEmployee = event.target.value as AvailableEmployee | string;

    // Array of employee combinations in case selected employee is included in employee combinations of selected slot proposal
    const matchingEmployeeCombinations = values.slotProposal?.originalEmployeeCombinations?.find(
      (employeeCombination) =>
        typeof selectedEmployee !== "string" && employeeCombination.includes(selectedEmployee.publicId)
    );

    // Re selecting all before selecting time slot, without triggering validation
    if (selectedEmployee === "NONE" && values.slotProposal?.startTime === "") {
      setSlotDeselected(false);
      helpers.setValue(
        {
          startTime: values.slotProposal?.startTime,
          employeeCombinations: null,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        false
      );
    }
    // Re-selecting all employees
    else if (selectedEmployee === "NONE") {
      setSlotDeselected(false);
      helpers.setValue(
        {
          startTime: values.slotProposal?.startTime,
          employeeCombinations: null,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        true
      );
    }
    // Re-selected employee without previoulsy selected time slot, slot not included in slot's employee combination
    else if (selectedEmployee !== "NONE" && !matchingEmployeeCombinations && values.slotProposal?.startTime !== "") {
      setSlotDeselected(true);
      helpers.setValue(
        {
          startTime: "",
          employeeCombinations: null,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        true
      );
    }
    // Selected employee included in slot's employee combination
    else if (matchingEmployeeCombinations) {
      setSlotDeselected(false);
      helpers.setValue(
        {
          startTime: values.slotProposal?.startTime,
          employeeCombinations: matchingEmployeeCombinations,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        true
      );
    }

    setFieldValue("selectedEmployee", selectedEmployee === "" ? null : selectedEmployee, false);
  };

  if (errorMessage) {
    return <ErrorTimeSlot errorMessage={errorMessage} />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      // className={classes.componentContainer}
      // sx={{
      //   padding: 1,
      // }}
    >
      <Grid item container direction="column" pt={0}>
        <TitleNavigationHeader
          slotNoLongerAvailable={slotNoLongerAvailable}
          error={error}
          goToPreviousPage={goToPreviousPage}
          nextStepHandler={nextStepHandler}
        />
        <Grid item>
          <Typography display="inline">{` Behandlung: `}</Typography>
          <Typography color="primary" display="inline">{` ${values.treatment?.name}`}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="space-between"
        // className={classes.buttonWrap}
        my={2}
      >
        {/** Employees select only visible if there is more than one employee to select from */}
        {values.treatment?.employeeSelectable && availableEmployees !== null && availableEmployees.length > 0 && (
          <Grid item container direction="column" mt={2}>
            <Grid item>
              <Typography variant="h6">{t("doctorSelect.title")}</Typography>
            </Grid>
            <EmployeeSelect
              isMobile
              selectedEmployee={values.selectedEmployee}
              availableEmployees={availableEmployees}
              handleEmployeeChange={handleEmployeeChange}
            />
            <Grid item>
              {availableEmployees.length === 1 && <FormHelperText>{t("doctorSelect.defaultSelection")}</FormHelperText>}
            </Grid>
          </Grid>
        )}
      </Grid>

      <section>
        <Grid item container justifyContent="center">
          {slotDeselected && (
            <Typography variant="caption" color="error" align="center">
              {t("appointment.deSelectedSlotWarning")}
            </Typography>
          )}
          <Typography variant="caption" color="error" align="center">
            {error ? error.startTime : " "}
          </Typography>
          <Typography variant="caption" color="error" align="center">
            {slotNoLongerAvailable ? t("warning.unavailableTime") : ""}
          </Typography>
          <Typography variant="caption" color="primary" align="center">
            {timeFrameHasNoSlots ? t("warning.noDataForRange") : null}
          </Typography>
        </Grid>
        <Paper
          // className={classes.paperStyle}
          sx={{
            maxWidth: "100%",
          }}
        >
          {isFetchingSlots
            ? skeletonBackbone.map((_singleSkeleton, index) => (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={52}
                  width={340}
                  // className={classes.skeletonStyle}
                  sx={{ marginBottom: "3px" }}
                  key={index}
                />
              ))
            : daysShowingAppointments.map((dayOfWeek, index) => {
                return dayOfWeek.isSameOrBefore(moment()) ? null : (
                  <Field
                    component={SingleDayAccordion}
                    dayOfWeek={dayOfWeek}
                    key={index}
                    onTimeClickHandler={onTimeClickHandler}
                    setButtonRef={setButtonRef}
                  />
                );
              })}
          <Button
            onClick={onShowNextWeekHandler}
            disabled={mobileViewWeeks < 4 ? false : true}
            variant="contained"
            color="primary"
            // className={classes.showMoreBttn}
            sx={{
              marginTop: 20,
              width: "100%",
            }}
          >
            {` ${t("appointment.showMore")}`}
          </Button>
        </Paper>
      </section>
    </Grid>
  );
};
