import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { useFormikContext, useField } from "formik";
import React from "react";
import { getInstitutionDetails } from "../../../modules/institution/selectors";
import { FormValues } from "../../../components/FormWizard";
import { useTranslation } from "react-i18next";
import { format, isBefore, isAfter, parse, formatISO } from "date-fns";
import { useSelector } from "react-redux";
import { createTimeIntervals } from "../../../modules/timeSlots/utils";
import { utcToZonedTime } from "date-fns-tz";

// const useStyle = makeStyles((theme) => ({
//   formControl: {
//     minWidth: 200,
//   },
// }));

/** Form component for travel time selection */
export const TravelTimeSelect = () => {
  const { t } = useTranslation();
  // const classes = useStyle();
  const {
    values: { treatment },
  } = useFormikContext<FormValues>();

  // Institution zone Id for correct time rendering
  const institutionZoneId = useSelector(getInstitutionDetails)?.zoneId ?? "";

  // Selected time stored in Formik
  const [field, meta] = useField({ name: "earliestArrivalTime" });

  // Handles time selection and dispatches queue slot fetch to check if slot is available
  // const handleChange = (evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
  //   field.onChange(evt);
  // };

  const handleChange = (evt: SelectChangeEvent<string | null>) => {
    field.onChange(evt);
  };

  // time and array of objects that is filled with 15 min intervals with in open times for selected treatment
  const now = new Date();
  const timeOptions: {
    value: string;
    label: string;
  }[] = [
    {
      value: "NOW",
      label: t("travelTime.value0"),
    },
  ];

  const openingHoursOfToday = treatment?.openingHoursOfToday ?? [];

  // For each open time of a treatment in a single day, intervals created and pushed into timeOptions array
  for (let i = 0; i < openingHoursOfToday.length; i++) {
    const startTime = parse(openingHoursOfToday[i].startTime, "H:mm:ss", new Date());
    const endTime = parse(openingHoursOfToday[i].endTime, "H:mm:ss", new Date());

    // if the start time is before 'new Date()', it's replaced with value of 'new Date()' as the beggining for creation of time intervals
    let isNowAfterEarliestDate = isAfter(utcToZonedTime(new Date(), institutionZoneId), startTime);

    let startTimeChecked = isNowAfterEarliestDate ? utcToZonedTime(new Date(), institutionZoneId) : startTime;

    // skipping itteration in case end time of the slot is before 'new Date()'
    if (isAfter(utcToZonedTime(new Date(), institutionZoneId), endTime)) {
      continue;
    }

    createTimeIntervals(startTimeChecked, endTime, 15)?.forEach((date) => {
      const zonedTime = utcToZonedTime(new Date(), institutionZoneId);
      timeOptions.push({
        // "now" format in the time zone of institution
        // differenceInMinutes:
        //   differenceInMinutes(date, zonedTime) + (differenceInSeconds(date, zonedTime) > 30 ? 1 : 0),
        value: formatISO(date),
        label: format(date, "HH:mm'h'"),
      });
    });
  }

  // start times sorted in ascending order
  timeOptions.sort(({ label: startTimeA }, { label: startTimeB }) => {
    if (isBefore(parse(startTimeA, "H:mm:ss", new Date()), parse(startTimeB, "H:mm:ss", new Date()))) {
      return -1;
    }
    if (isAfter(parse(startTimeA, "H:mm:ss", new Date()), parse(startTimeB, "H:mm:ss", new Date()))) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <>
      <Grid item>
        <FormControl
          variant="filled"
          error={meta.error !== undefined && meta.touched}
          // className={classes.formControl}
          sx={{
            minWidth: "200px",
          }}
          size="small"
        >
          <InputLabel id="travelTime-label" htmlFor="travelTime">
            {t("travelTime.title")}
          </InputLabel>
          <Select
            value={field.value}
            name={field.name}
            onChange={handleChange}
            onBlur={field.onBlur}
            labelId="travelTime-label"
            id="travelTime"
          >
            {timeOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
