import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Skeleton,
  Box,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { FieldProps } from "formik";
import { FormValues } from "../../../components/FormWizard";
import { getIsFetchingSlots } from "../../../modules/timeSlots/selectors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RootState } from "../../../store/root-reducer";
import { getSlotProposals } from "../../../modules/timeSlots/selectors";
import { getSlotSplitSortedByTime } from "../../../modules/timeSlots/utils";
import { grey } from "@mui/material/colors";
import { SlotProposal } from "../../../modules/timeSlots/types";
import { AvailableEmployee } from "../../../modules/treatments/types";
import { useTranslation } from "react-i18next";

// const useStyle = makeStyles((theme) => ({
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightBold,
//   },
//   buttonStyle: {
//     padding: theme.spacing(2),
//     backgroundColor: grey[100],
//     marginBottom: theme.spacing(0.5),
//   },
//   showMoreBttn: {
//     marginTop: 10,
//   },
//   skeleton: {
//     marginBottom: 4,
//   },
// }));

interface SingleDayAccordionProps extends FieldProps<never, FormValues> {
  /** Moment representation of day */
  dayOfWeek: Moment;
  /** Stores selected time in formik, takes user to next step */
  onTimeClickHandler: (slotProposal: SlotProposal) => void;
  /** Selected employee id from Formik */
  selectedEmployee?: AvailableEmployee | null;
  /** Ref set to bttn of selected time */
  setButtonRef: () => HTMLDivElement | null;
  /** Selected slot proposal from Fromik */
  slotProposals?: { startTime: string; employeeCombinations: string[] | null };
}
/**
 * Display of the day with available appointments in mobile view.
 * Initially showing 8 slots, with "show more" option if data exists.
 */
export const SingleDayAccordion = React.memo(
  ({ dayOfWeek, onTimeClickHandler, setButtonRef, form: { values } }: SingleDayAccordionProps) => {
    // const classes = useStyle();
    const { t } = useTranslation();

    // Check if accordion has previously selected time slot
    const shouldDefaultExpand = values.slotProposal?.startTime
      ? moment(values.slotProposal.startTime).isSame(dayOfWeek, "day")
      : false;

    // Control of accordion open/close state with default value from shouldDefaultExpand
    const [openAccordion, setOpenAccordion] = useState(shouldDefaultExpand);

    // Handels open/close accordian state
    const openAccordionHandler = () => setOpenAccordion(!openAccordion);

    const isFetchingSlots = useSelector(getIsFetchingSlots);

    const slotProposals = useSelector((state: RootState) =>
      getSlotProposals(
        state,
        dayOfWeek,
        typeof values.selectedEmployee !== "string" ? values.selectedEmployee?.publicId ?? null : null
      )
    );

    const skeletonBackbone = [1, 2, 3, 4, 5, 6, 7];

    const [showMoreSlots, setShowMoreSlots] = useState(false);

    // Data is devided in two arrays for partial display.
    const [slotProposalsFirstPart, slotProposalsSecondPart] = getSlotSplitSortedByTime(slotProposals);

    const showMoreSlotsHandler = () => setShowMoreSlots(true);

    // Controls display of all the data when rendering Accordion with already selected time in Formik from that matches time in slotProposalsSecondPart array.
    const isSelectedTimeInSecondArray = slotProposalsSecondPart.some(
      (slotProposal) => slotProposal.startTime === values.slotProposal?.startTime
    );

    /**
     * Bttn is displayed only if:
     * - btn has been NOT been clicked
     * - there actually is more data to be displayed in second array
     * - isSelectedTimeInSecondArray is false, meaning either no time is selected yet or selected time reffers to data in first array
     */
    const displayShowMoreButton =
      !showMoreSlots && slotProposalsSecondPart.length > 0 && !isSelectedTimeInSecondArray ? true : false;

    // const shouldDefaultExpand = values.slotProposal?.startTime
    //   ? moment(values.slotProposal.startTime).isSame(dayOfWeek, "day")
    //   : undefined;

    return (
      <Accordion
        disabled={slotProposalsFirstPart.length > 0 || isFetchingSlots ? false : true}
        expanded={openAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={openAccordionHandler}
        >
          <Typography
            // className={classes.heading}
            sx={{
              fontSize: "pxToRem(15)",
              fontWeight: "fontWeightBold",
            }}
          >
            {dayOfWeek.format("LL")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container spacing={1}>
            {/* checking if there is data for display or skeleton */}
            {slotProposalsFirstPart.length > 0
              ? slotProposalsFirstPart.map(({ startTime, employeeCombinations }, index) => {
                  // getting right time display format
                  const [hours, minutes] = moment(startTime).format("HH:mm").split(":");
                  const selectedTime = values.slotProposal?.startTime === startTime;

                  return (
                    <Grid item key={index}>
                      <Button
                        style={{
                          color: selectedTime ? "#fff" : "inherit",
                          backgroundColor: selectedTime ? "green" : "inherit",
                        }}
                        onClick={() => {
                          onTimeClickHandler({ startTime, employeeCombinations });
                        }}
                        variant="contained"
                        // className={classes.buttonStyle}
                        sx={{
                          padding: 2,
                          backgroundColor: grey[100],
                          marginBottom: 0.5,
                        }}
                      >
                        {`${hours}:${minutes}`}
                      </Button>
                    </Grid>
                  );
                })
              : skeletonBackbone.map((_singleSkeleton, index) => (
                  <Grid item key={index}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={75}
                      height={55}
                      // className={classes.skeleton}
                      style={{
                        marginBottom: "4px",
                      }}
                    />
                  </Grid>
                ))}
            {/** Conditional logic for rendering "show more" button and the rest of the slots  */}
            {displayShowMoreButton && (
              <Grid item container>
                <Button
                  onClick={showMoreSlotsHandler}
                  variant="contained"
                  color="primary"
                  fullWidth
                  // className={classes.showMoreBttn}
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  {`${t("appointment.showMore")}`}
                </Button>
              </Grid>
            )}
            {/** Conditional logic for rendering data from the second array if the time already selected is from second array or bttn show more is clicked.  */}
            {isSelectedTimeInSecondArray || showMoreSlots
              ? slotProposalsSecondPart.map(({ startTime, employeeCombinations }, index) => {
                  // Formatting time for display
                  const [hours, minutes] = moment(startTime).format("HH:mm").split(":");
                  const selectedTime = values.slotProposal?.startTime === startTime;

                  return (
                    <Grid item key={index}>
                      <Button
                        ref={selectedTime ? setButtonRef : null}
                        onClick={() => {
                          onTimeClickHandler({ startTime, employeeCombinations });
                        }}
                        variant="contained"
                        sx={{
                          padding: 2,
                          marginBottom: 0.5,
                          color: selectedTime ? "#fff" : "inherit",
                          backgroundColor: selectedTime ? "green" : "inherit",
                        }}
                      >
                        {`${hours}:${minutes}`}
                      </Button>
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
