import { Grid, MenuItem, TextField } from "@mui/material";
import React from "react";
import { AvailableEmployee } from "../../modules/treatments/types";
import { useTranslation } from "react-i18next";

// const useStyle = makeStyles((theme) => ({
//   textField: {
//     margin: theme.spacing(1),
//     marginLeft: 0,
//     minWidth: 200,
//     backgroundColor: "#fff",
//   },
//   textFieldMobile: {
//     margin: theme.spacing(1),
//     marginLeft: 0,
//     minWidth: 100,
//     backgroundColor: "#fff",
//   },
//   root: {
//     fontSize: 20,
//   },
//   rootMobile: {
//     fontSize: 15,
//   },
// }));

interface EmployeeSelectProps {
  /** Flag for mobile view styles */
  isMobile?: boolean;
  /** Eployee object from filtering employees by available publicId */
  availableEmployees: Readonly<AvailableEmployee[]>;
  /** Employee change handler storing value in the Formik without validation */
  handleEmployeeChange: (
    event: React.ChangeEvent<{
      value: string;
    }>
  ) => void;
  /** Selected employee stored in Formik */
  selectedEmployee: AvailableEmployee | null | undefined | string;
}

/**
 * Used for employee selection in DateTimePicker and DateTimePickerMobile components
 */
export const EmployeeSelect = ({
  isMobile,
  selectedEmployee,
  availableEmployees,
  handleEmployeeChange,
}: EmployeeSelectProps) => {
  const { t } = useTranslation();

  return (
    <Grid item container justifyContent="flex-start">
      <TextField
        disabled={availableEmployees.length === 1}
        value={
          availableEmployees.length === 1 ? availableEmployees[0] : selectedEmployee === "All" ? null : selectedEmployee
        }
        variant="outlined"
        id="employee-select"
        select
        label={t("doctorSelect.label")}
        onChange={handleEmployeeChange}
        sx={{
          margin: "8px",
          backgroundColor: "#fff",
          minWidth: isMobile ? "150px" : "200px",
        }}
        SelectProps={{
          sx: {
            fontSize: isMobile ? "15px" : "20px",
          },
        }}
        helperText={availableEmployees.length === 1 && t("doctorSelect.defaultSelection")}
      >
        <MenuItem
          value="NONE"
          sx={{
            fontSize: isMobile ? "15px" : "20px",
          }}
        >
          {t("doctorSelect.noSelection")}
        </MenuItem>
        {availableEmployees?.map((employee, index) => (
          <MenuItem
            value={employee as any}
            key={index}
            sx={{
              fontSize: isMobile ? "15px" : "20px",
            }}
          >
            {employee.name}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};
