import { Box, Typography } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";

// const useStyle = makeStyles((theme) => ({
//   componentContainer: { marginTop: theme.spacing(2) },
// }));

/**
 * Component for warning about missing organization unit identifier.
 * Shown if organization identifier is missing in url params.
 */
export const MissingOrgUnitIdentifier = () => {
  const { t } = useTranslation();
  // const classes = useStyle();

  return (
    <Box mt={2}>
      <Box>
        <Typography variant="h6" gutterBottom>
          {t("missingOUI.title")}
        </Typography>
      </Box>
      <Box>
        <Typography>{t("missingOUI.message")}</Typography>
      </Box>
    </Box>
  );
};
