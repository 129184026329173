import React from "react";
import { Grid, Button, Typography, Link, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormValues } from "../modules/type";
// import { useFormikContext } from "formik";

// const useStyle = makeStyles((theme) => ({
//   buttonWrap: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(2),
//   },
// }));

interface TitleNavigationHeaderProps {
  /** Server error about appointment */
  confirmationError?: boolean;
  /** Fromik error */
  error?: string;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Indicates whether we're reserving a slot as opposed to booking an appointmen */
  isSlotReservation?: boolean;
  /** Indicates we're on treatment selection step */
  isTreatmentSelect?: boolean;
  /** Tracker of current booking step */
  lastStep?: boolean;
  /** Handler for going to next page */
  nextStepHandler?: () => void;
  /** UUID of the orgUnit for the link route */
  orgUnitUUID?: string;
  /** Selected slot availability */
  slotNoLongerAvailable?: boolean;
  /** Description of the current booking step  */
  stepInfoText?: string;
  /** Error from server after submission */
  errorMessage?: string;
  phoneVerification?: boolean;
  serverError?: boolean;
}

/**
 * Component for navigation between booking process steps
 * Mounted on every step of the booking process. Contains previous/next step buttons and title & description of the current step
 */
export const TitleNavigationHeader = ({
  confirmationError,
  error,
  goToPreviousPage,
  isSlotReservation,
  isTreatmentSelect,
  lastStep,
  nextStepHandler,
  orgUnitUUID,
  slotNoLongerAvailable,
  stepInfoText,
  errorMessage,
  serverError,
}: TitleNavigationHeaderProps) => {
  // const classes = useStyle();
  const { t } = useTranslation();
  // const { handleSubmit } = useFormikContext();

  const nextStepClickHandler = () => {
    // if (isTreatmentSelect) {
    //   return;
    // }
    if (nextStepHandler) {
      nextStepHandler();
    }
  };

  const { setFieldValue, validateForm, values, errors, setErrors } = useFormikContext<FormValues>();

  return (
    <Box pt={2}>
      <Box>
        <Typography gutterBottom={true} variant="h5">
          {isSlotReservation ? t("navigation.slotTitle") : t("navigation.appointmentTitle")}
        </Typography>
      </Box>
      <Grid item container justifyContent="space-between" my={2}>
        {isTreatmentSelect ? (
          <Link component={RouterLink} to={`/${orgUnitUUID}`} underline="none" color="inherit">
            <Button color="inherit" startIcon={<ArrowBack />}>
              {t("navigation.navBack")}
            </Button>
          </Link>
        ) : (
          <Button
            color="inherit"
            onClick={goToPreviousPage}
            startIcon={<ArrowBack />}
            disabled={lastStep && errorMessage ? true : false}
          >
            {t("navigation.navBack")}
          </Button>
        )}

        <Button
          color="inherit"
          type="submit"
          endIcon={<ArrowForward />}
          onClick={nextStepClickHandler}
          // next button is disabled if there is an error from validation, slot is unavailable or we are on the last step
          disabled={error || serverError ? true : lastStep ? true : slotNoLongerAvailable ? true : false}
        >
          {t("navigation.navNext")}
        </Button>
      </Grid>
      {stepInfoText && (
        <Box>
          <Typography color={confirmationError ? "secondary" : "inherit"}>{stepInfoText}</Typography>
        </Box>
      )}
    </Box>
  );
};
