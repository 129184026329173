import { WaitingQueueSlotState } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { combineReducers } from "redux";
import * as actions from "./actions";

type WaitingQueueSlotActions = ActionType<typeof actions>;

const initialState: WaitingQueueSlotState = {
  isFetching: false,
  lastFetch: null,
  waitingQueueSlot: null,
  slotIsValid: false,
  error: null,
};

// export const waitingQueueSlot = createReducer(initialState.waitingQueueSlot).handleAction(
//   fetchQueueSlot.success,
//   (_state, action) => action.payload
// );

/**
 * Server response about status and details for requested time slot.
 */
export const waitingQueueSlot = (state = initialState.waitingQueueSlot, action: WaitingQueueSlotActions) => {
  switch (action.type) {
    case getType(actions.fetchQueueSlot.success):
      return action.payload;
    default:
      return state;
  }
};

/**
 * Server error for submission of slot reservation.
 */
export const error = (state = initialState.error, action: WaitingQueueSlotActions) => {
  switch (action.type) {
    case getType(actions.reserveSlot.request):
      return null;
    case getType(actions.reserveSlot.failure):
      return action.payload;
    default:
      return state;
  }
};

/**
 * Indicates fetching of requested time slot details in  2.
 */
export const isFetching = (state = initialState.isFetching, action: WaitingQueueSlotActions) => {
  switch (action.type) {
    case getType(actions.fetchQueueSlot.request):
      return true;
    case getType(actions.fetchQueueSlot.failure):
    case getType(actions.fetchQueueSlot.success):
      return false;
    default:
      return state;
  }
};

/**
 * Creates timestamp on successful fetch of requested time slot details in step 2.
 */
export const lastFetch = (state = initialState.lastFetch, action: WaitingQueueSlotActions) => {
  switch (action.type) {
    case getType(actions.fetchQueueSlot.success):
      return new Date().getTime();
    default:
      return state;
  }
};

export const slotIsValid = (state = initialState.slotIsValid, action: WaitingQueueSlotActions) => {
  switch (action.type) {
    case getType(actions.slotValidityIsValid):
      return true;
    default:
      return state;
  }
};

// export const error = createReducer(initialState.error)
//   .handleAction(reserveSlot.request, () => null)
//   .handleAction(reserveSlot.failure, (_state, action) => action.payload);

// export const isFetching = createReducer(initialState.isFetching)
//   .handleAction(fetchQueueSlot.request, () => true)
//   .handleAction([fetchQueueSlot.success, fetchQueueSlot.failure], () => false);

// export const lastFetch = createReducer(initialState.lastFetch).handleAction(fetchQueueSlot.success, () =>
//   new Date().getTime()
// );

// export const slotIsValid = createReducer(initialState.slotIsValid).handleAction(slotValidityIsValid, () => true);

export default combineReducers<WaitingQueueSlotState, WaitingQueueSlotActions>({
  error,
  isFetching,
  lastFetch,
  slotIsValid,
  waitingQueueSlot,
});
