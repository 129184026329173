import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./actions";
import { AppointmentSlotState } from "./types";

export type AppointmentSlotActions = ActionType<typeof actions>;

/**
 * State describing appointment after submisson its submission
 */
const initialState: AppointmentSlotState = {
  appointmentSlot: null,
  errorMessage: null,
  isFetching: false,
  isValid: false,
};

/**
 * Server response about appointment submission
 */
export const appointmentSlot = (state = initialState.appointmentSlot, action: AppointmentSlotActions) => {
  switch (action.type) {
    case getType(actions.getAppointmentSlot.success):
      return action.payload;
    default:
      return state;
  }
};

// export const appointmentSlot = createReducer(initialState.appointmentSlot).handleAction(
//   actions.getAppointmentSlot.success,
//   (_state, action) => action.payload
// );

/**
 * Server error for submission of appointment
 */
export const errorMessage = (state = initialState.errorMessage, action: AppointmentSlotActions) => {
  switch (action.type) {
    case getType(actions.getAppointmentSlot.failure):
      return action.payload;
    case getType(actions.getAppointmentSlot.request):
    case getType(actions.getAppointmentSlot.success):
      return null;

    default:
      return state;
  }
};

// export const errorMessage = createReducer(initialState.errorMessage)
//   .handleAction(actions.getAppointmentSlot.failure, (_state, action) => action.payload)
//   .handleAction([actions.getAppointmentSlot.request, actions.getAppointmentSlot.success], () => null);

/**
 * Indicates submission of appointment
 */
export const isFetching = (state = initialState.isFetching, action: AppointmentSlotActions) => {
  switch (action.type) {
    case getType(actions.getAppointmentSlot.request):
      return true;
    case getType(actions.getAppointmentSlot.success):
    case getType(actions.getAppointmentSlot.failure):
      return false;
    default:
      return state;
  }
};

/**
 * Indicates that the appointment has been confirmed by user.
 */
export const isValid = (state = initialState.isValid, action: AppointmentSlotActions) => {
  switch (action.type) {
    case getType(actions.appointmentValidityIsValid):
      return true;
    default:
      return state;
  }
};

// export const isFetching = createReducer(initialState.isFetching)
//   .handleAction(actions.getAppointmentSlot.request, () => true)
//   .handleAction([actions.getAppointmentSlot.success, actions.getAppointmentSlot.failure], () => false);

export { errorMessage as appointmentErrorMessageReducer, isFetching as appointmentIsFetchingReducer };

export default combineReducers<AppointmentSlotState, AppointmentSlotActions>({
  appointmentSlot,
  errorMessage,
  isFetching,
  isValid,
});
