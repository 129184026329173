import { createAsyncAction, createAction } from "typesafe-actions";
import { AppointmentReserveResponse, AppointmentRequest } from "./types";
import { History } from "history";

/**
 * Dispatched on last step of appointment booking from handleSubmission handler.
 * Meta object provides saga with needed data.
 */
export const getAppointmentSlot = createAsyncAction(
  "APPOINTMENT_SLOT_REQUEST",
  "APPOINTMENT_SLOT_SUCCESS",
  "APPOINTMENT_SLOT_FAILURE"
)<
  [
    AppointmentRequest,
    {
      history: History;
      waitTimeConfirmedDate: number | null;
      setSubmitting: (isSubmitting: boolean) => void;
      pathName: string;
    }
  ],
  AppointmentReserveResponse,
  string
>();

/**
 * Dispatched from AppointmentReservationFinished.
 * Triggers periodical check of whether booking has been confirmed by clicking on the text message sent to user
 */
export const startCheckingAppointmentValidity = createAction("APPOINTMENT_CHECKVALIDITY_START")<string>();
/**
 *
 */
export const stopCheckingAppointmentValidity = createAction("APPOINTMENT_CHECKVALIDITY_STOP")<undefined>();
/**
 * Dispatched from checkAppointmentValidity saga, on appointment confirmation.
 */
export const appointmentValidityIsValid = createAction("APPOINTMENT_VALIDITY_ISVALID")<undefined>();
