import { Grid, Typography, Chip, makeStyles } from "@mui/material";
import React from "react";
import { TimeSelectionDayColumn } from "./TimeSelectionDayColumn";
import { FormikErrors } from "formik";
import { Moment } from "moment";
import { AvailableEmployee } from "../../../modules/treatments/types";
import { SlotProposal } from "../../../modules/timeSlots/types";
import { useTranslation } from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//   columnsWrap: {
//     paddingTop: theme.spacing(1),
//     paddingBottom: theme.spacing(1),
//   },
//   section: {
//     overflowY: "auto",
//     display: "flex",
//     flexDirection: "column",
//   },
//   spacer: {
//     minWidth: 40,
//   },
//   warningStyle: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
// }));

interface DateTimePickerSectionProps {
  isFetchingSlots: boolean;
  /** Callback calculating with of scrollbar  */
  calculateScrollbarWidth: (elem: HTMLDivElement | null) => void;
  /** Formik error */
  error: FormikErrors<string>;
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Double array of all weeks in range and respective days */
  headerDatesFromMaxWeeksInFuture: Moment[][];
  /** Indicator for last week of the range */
  isLastWeekOfSelection: boolean;
  /** Updates startOfSelectionWeek triggering data fetch for selected range  */
  nextWeekHandler: () => void;
  /** Stores selected time in formik, takes user to next step */
  onTimeClickHandler: (slotProposal: SlotProposal) => void;
  /** Updates startOfSelectionWeek triggering data fetch for selected range  */
  prevWeekHandler: () => void;
  /** Scroll bar width used for alignment of content */
  scrollbarWidth: number;
  /** Selected employee id from Formik */
  selectedEmployee: AvailableEmployee | null | undefined | string;
  /** Start time for alreay selected slot */
  selectedStartTime: string | undefined;
  /** Ref set to bttn of selected time */
  setButtonRef: (elem: HTMLButtonElement | null) => void;
  /** Callback updating state for slot availability in DateTimePicker*/
  slotProposal: {
    startTime: string;
    employeeCombinations: string[] | null;
    originalEmployeeCombinations: string[][] | null;
  } | null;
  /** Selected slot no longer available indicator */
  slotNoLongerAvailable: boolean;
  /** Represents current week in week range.
   * Controls SwipableViews diplay.
   */
  swipeIndex: number;
  /** Indicates week in selected range has no data to display*/
  weekHasNoSlots: boolean;
}

/**
 * Section with columns with available times or message in case the selected week has no slots available.
 * A week that is displayed comes for the props, headerDatesFromMaxWeeksInFuture[][] and swipeIndex give array or currently selected weekdays.
 */

export const DateTimePickerSection = ({
  calculateScrollbarWidth,
  error,
  goToNextPage,
  headerDatesFromMaxWeeksInFuture,
  isLastWeekOfSelection,
  nextWeekHandler,
  onTimeClickHandler,
  prevWeekHandler,
  scrollbarWidth,
  selectedEmployee,
  selectedStartTime,
  setButtonRef,
  slotNoLongerAvailable,
  slotProposal,
  swipeIndex,
  weekHasNoSlots,
  isFetchingSlots,
}: DateTimePickerSectionProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();

  // In case content is scrollable, keeps content aligned with header.
  const widthWithScrollBar = 40 - scrollbarWidth;

  return (
    <section
      // className={classes.section}
      style={{
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        ref={calculateScrollbarWidth}
        item
        container
        justifyContent="center"
        direction="row"
        wrap="nowrap"
        gap={2}
        // className={classes.columnsWrap}
        py={1}
      >
        <Grid
          item
          //  className={classes.spacer}
          sx={{
            minWidth: "40px",
          }}
        />
        {/* In case of no data for current week, displaying message with option for moving to the first week with data  */}
        {weekHasNoSlots ? (
          <Grid item container direction="column" alignItems="center" justifyContent="center">
            <Typography
              color="primary"
              variant="h6"
              //  className={classes.warningStyle}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t("warning.noDataForRange")}
            </Typography>

            {!isLastWeekOfSelection && (
              <Chip
                label={t("warning.searchBtn")}
                color="primary"
                onClick={isLastWeekOfSelection ? prevWeekHandler : nextWeekHandler}
              />
            )}
          </Grid>
        ) : (
          headerDatesFromMaxWeeksInFuture[swipeIndex].map((dayOfWeek, index: number) => (
            <TimeSelectionDayColumn
              isFetchingSlots={isFetchingSlots}
              slotProposal={slotProposal}
              selectedEmployee={selectedEmployee}
              selectedStartTime={selectedStartTime ?? ""}
              dayOfWeek={dayOfWeek}
              key={index}
              goToNextPage={goToNextPage}
              onTimeClickHandler={onTimeClickHandler}
              setButtonRef={setButtonRef}
            />
          ))
        )}
        <Grid item style={{ minWidth: widthWithScrollBar }} />
      </Grid>
    </section>
  );
};
