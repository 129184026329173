import moment from "moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getInstitutionDetails,
  getInstitutionDetailsIsFetching,
  getInstitutionDetailsError,
} from "../../modules/institution/selectors";
import { SlotProposal } from "./types";
import { INSTITUTION_IDENTIFIER } from "../../modules/services/api";
import { getLastFetch } from "../../modules/treatments/selectors";
import { fetchInstitutionDetailsAndTreatments } from "../../modules/institution/actions";
import { add, getMinutes, isBefore, set } from "date-fns";
import { getSlotProposals } from "./selectors";
import { AvailableEmployee } from "../../modules/timeSlots/types";

/**
 * Create an array of dates between startDate and endDate every stepSize minutes
 * @param startDate
 * @param endDate
 * @param stepSize
 */
export const createTimeIntervals = (startDate: Date, endDate: Date, stepSize: number) => {
  const dates: Date[] = [];
  if (60 % stepSize !== 0) {
    return;
  }
  dates.push(
    set(new Date(startDate), {
      minutes: getMinutes(startDate) + (stepSize - (getMinutes(startDate) % stepSize)),
      seconds: 0,
      milliseconds: 0,
    })
  );
  let lastDate = dates[dates.length - 1];
  while (isBefore(lastDate, endDate)) {
    const newDate = add(new Date(lastDate), { minutes: stepSize });
    dates.push(newDate);
    lastDate = newDate;
  }
  return dates;
};

/**
 * Gets range of weeks, first and last, based of maxWeeksInFuture for desktop view
 * @returns Array of Moment objects that are beginning and end of range
 */
export const getWeekStepperRange = (max_weeks_in_future: number) => {
  return [
    moment().startOf("isoWeek").toISOString(),
    moment().startOf("isoWeek").add(max_weeks_in_future, "week").toISOString(),
  ];
};

/**
 * Gets FromDate value for creating time frame for fetching available appointment times.
 * @param momentMonday is value of the first day the data is fetched from
 * @returns day of week in "YYYY-MM-DD" format
 */
export const getFromDateStringFormat = (momentMonday: string) => {
  let fromDateMomentFormat = moment(momentMonday);
  return `${fromDateMomentFormat.format("YYYY-MM-DD")}`;
};

/**
 * Gets ToDate value for creating time frame for fetching available appointment times.
 * @param momentMonday is value of the first day the data is fetched from
 * @returns day in "YYYY-MM-DD" format
 */
export const getToDateStringFormat = (momentMonday: string) => {
  let toDateMomentFormat = moment(momentMonday).endOf("isoWeek");
  return `${toDateMomentFormat.format("YYYY-MM-DD")}`;
};

/**
 * Sorts string array of proposal times
 * @param slotProposals array of fetched start times
 * @returns array of sorted start times from earliest till latest
 */
export function sortByStartTime<T, K extends keyof T>(elements: T[], key?: K) {
  return elements.sort((elemA, elemB) => {
    if (moment(key ? elemA[key] : elemA).isBefore(moment(key ? elemB[key] : elemB))) {
      return -1;
    }
    if (moment(key ? elemA[key] : elemA).isAfter(moment(key ? elemB[key] : elemB))) {
      return 1;
    } else {
      return 0;
    }
  });
}

/**
 * Checks if response object has any slot proposals, either because there are no what so ever, or if the employee is selected
 * @param slotProposals Object from response
 * @param selectedEmployee value passed as meta on action fetching slots dispatch
 * @returns Boolean depending if there are any slot proposals or not in the response, taking into account selected employee
 */
export const isSlotProposalEmpty = (
  slotProposals: { [date: string]: SlotProposal[] } | null,

  selectedEmployee: string | null | AvailableEmployee
) => {
  // check if there is any data in response
  let isSlotProposalWithoutData =
    slotProposals === null
      ? true
      : Object.keys(slotProposals).length > 0 &&
        Object.values(slotProposals).some((slotProposal) => slotProposal.length > 0)
      ? false
      : true;

  // if there is no data in the response
  if (isSlotProposalWithoutData) {
    return true;
  }
  // there is data in response but no employee is selected
  else if (selectedEmployee === null || selectedEmployee === "NONE") {
    return false;
  }
  // tthere is data in response and employee is selected available as AvailableEmployee object
  else if (!isSlotProposalWithoutData && slotProposals !== null && typeof selectedEmployee !== "string") {
    // returned ! value of what is filtered for, if employee exisits in response false is returned
    return !Object.values(slotProposals)
      .flat()
      .some(({ employeeCombinations }) =>
        employeeCombinations.some((employeeCombination) => employeeCombination.includes(selectedEmployee.publicId))
      );
  } else {
    return false;
  }
};

/**
 * Creates object with time range for fetching data on mobile devices
 * @param mobileViewWeeks number of weeks that is visible on mobile display
 * @returns Object used as requst param for fetching slot proposals in defined time range
 */
export const getMobileTimeFrame = (mobileViewWeeks: number, treatmentTypePublicId: string) => {
  let momentStartRange = moment().add(1, "day").toISOString();

  const fromDateStringFormat = moment(momentStartRange).format("YYYY-MM-DD");
  const toDateStringFormat = `${moment(momentStartRange)
    .add(mobileViewWeeks + 1, "week")
    .format("YYYY")}-${moment(momentStartRange)
    .add(mobileViewWeeks + 1, "week")
    .format("MM-DD")}`;

  return {
    fromDate: fromDateStringFormat,
    toDate: toDateStringFormat,
    treatmentTypePublicId,
  };
};

/**
 * Creates array of displayed days
 * @param mobileViewWeeks number of weeks that is visible on mobile display
 * @returns array of moment objects for days of week that are displayed on mobile device
 */
export const getDaysShowingAppointments = (mobileViewWeeks: number) => {
  let rangeOfDays: moment.Moment[] = [];
  let momentStartRange = moment().add(1, "day").toISOString();

  for (let weekNmb = 0; weekNmb <= mobileViewWeeks; weekNmb++) {
    for (let i = 0; i <= 6; i++) {
      const day = moment(momentStartRange).add(weekNmb, "week").add(i, "day");
      rangeOfDays.push(day);
    }
  }
  return rangeOfDays;
};

/**
 * Creates an array of weeks where every week is an array of dates.
 * Data is used for corret header display and data fetching.
 * Range is calculated based on the value of maxWeeksInFuture.
 * @param maxWeeksInFuture Number of weeeks in the range.
 */
export const getHeaderDatesFromMaxWeeksInFuture = (maxWeeksInFuture: number) => {
  let isTodaySunday = moment().isoWeekday() === 7;
  let rangeOfWeeks = [];
  //  First day in the range representing the start of current ISO week.
  let momentMonday = moment().startOf("isoWeek").toISOString();

  // On Sundays starts array of weeks from the next week when booking is possible
  for (let weekNmb = isTodaySunday ? 1 : 0; weekNmb <= maxWeeksInFuture; weekNmb++) {
    let week = [];
    for (let i = 0; i <= 6; i++) {
      const day = moment(momentMonday).add(weekNmb, "week").add(i, "day");
      week.push(day);
    }
    rangeOfWeeks.push(week);
  }
  return rangeOfWeeks;
};

/**
 * If there are enought data, splits slots into two array for initial view and show more view inside accordions
 * @param slotProposals Array of slot proposal times
 * @returns Two arrays for dispay of slots on mobile devices
 */
export const getSlotSplitSortedByTime = (slotProposals: SlotProposal[]) => {
  const slotProposalsSortedByTime = sortByStartTime(slotProposals, "startTime");

  let slotProposalsFirstPart: SlotProposal[] = [];
  let slotProposalsSecondPart: SlotProposal[] = [];

  if (slotProposals.length > 8) {
    slotProposalsSortedByTime.slice(0, 8).map((slotProposal) => slotProposalsFirstPart.push(slotProposal));

    slotProposalsSortedByTime.slice(8).map((slotProposal) => slotProposalsSecondPart.push(slotProposal));
  } else {
    slotProposalsSortedByTime.map((slotProposal) => slotProposalsFirstPart.push(slotProposal));
  }
  return [slotProposalsFirstPart, slotProposalsSecondPart];
};

/**
 * Hook used to fetch institution details and treatments
 */
export const useFetchInstitutionDetailsAndAllTreatments = (
  orgUnitUUID: string | undefined
  // fetchTreatmentsRequest: PayloadActionCreator<string, string>
) => {
  const dispatch = useDispatch();
  const institutionDetails = useSelector(getInstitutionDetails);
  const institutionDetailsIsFetching = useSelector(getInstitutionDetailsIsFetching);
  const institutionDetailsError = useSelector(getInstitutionDetailsError);
  const lastFetch = useSelector(getLastFetch);

  useEffect(() => {
    // Checking that data has not been fetched already/required params are available
    if (
      lastFetch === null &&
      INSTITUTION_IDENTIFIER &&
      !institutionDetails &&
      !institutionDetailsIsFetching &&
      !institutionDetailsError
    ) {
      if (orgUnitUUID !== undefined) {
        dispatch(fetchInstitutionDetailsAndTreatments.request(orgUnitUUID));
      }
    }
  }, [institutionDetails, institutionDetailsIsFetching, orgUnitUUID, institutionDetailsError]);
};
