import { createSelector } from "reselect";
import { RootState } from "../../store/root-reducer";

const getState = (state: RootState) => state.appointmentDetails;
/** Server response after appointment submission. */
export const getAppointmentResponseCode = (state: RootState) => getState(state).appointmentSlot?.responseCode;
/** Error triggered by appointment submission. */
export const getAppointmentErrorMessage = (state: RootState) => getState(state).errorMessage;

export const getAppointmentIsFetching = (state: RootState) => getState(state).isFetching;
/** Server confirmed appointment start time. */
export const getAppointmentResponseStartTime = (state: RootState) => getState(state).appointmentSlot?.startTime;

/** User confirmed appointment after submission. */
export const getAppointmentIsValid = (state: RootState) => getState(state).isValid;

export const getAppointmentConfirmationData = createSelector(
  getAppointmentResponseCode,
  getAppointmentResponseStartTime,
  getAppointmentErrorMessage,
  (appointmentTimeResponseCode, appointmentResponseStartTime, appointmentError) => {
    return { appointmentTimeResponseCode, appointmentResponseStartTime, appointmentError };
  }
);
