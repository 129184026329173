import React from "react";
import { Card, CardContent, CardActions, Typography, Grid, CircularProgress, useMediaQuery, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ConfirmationNumber, Event } from "@mui/icons-material";
import { useFetchInstitutionDetailsAndAllTreatments } from "../modules/timeSlots/utils";
import { ErrorDialogWithReload } from "../components/WarningErrorMessages";
import { MissingOrgUnitIdentifier } from "./MissingOrgUnitIdentifier";
import { getTreatmentsError, getSlotAppointmentForkData } from "../modules/treatments/selectors";
import { TreatmentErrorCode } from "../modules/treatments/types";
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import { resetSwipeIndex } from "../modules/timeSlots/actions";

// const useStyle = makeStyles((theme) => ({
//   actionArea: {
//     display: "flex",
//     justifyContent: "space-between",
//   },
//   linkContainer: {
//     color: "#000",
//     textDecoration: "none",
//   },
//   linkDisabled: {
//     opacity: 0.4,
//     cursor: "default",
//   },
//   mobileBottomPadding: {
//     paddingBottom: 70,
//   },
//   componentContainer: {
//     flex: 1,
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(4),
//   },
//   cardStyle: {
//     marginTop: theme.spacing(2),
//   },
//   content: {
//     minHeight: 250,
//   },
//   font: {
//     fontSize: "5rem",
//   },
// }));

/**
 * Component for initial selection of slot reservation or appointment booking.
 * This is the first page the customer sees when coming to the site
 */
export const SlotAppointmentFork = () => {
  // const classes = useStyle();

  // Get orgUnitId from URL params
  const { orgUnitUUID } = useParams<{ orgUnitUUID: string }>();

  const hasMinWidth = useMediaQuery("(max-width:600px)");

  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Fetch data, institution details and treatments
  useFetchInstitutionDetailsAndAllTreatments(orgUnitUUID);

  const {
    appointmentTreatmentsAvailable,
    allOpenTreatmentsAvailableForRemoteCheckIn,
    treatmentsLastFetch,
    isFetchingTreatments,
    institutionPhoneNumber,
  } = useSelector(getSlotAppointmentForkData);

  // Determine if there are appointments/slot available for booking and, if not, disabling respective link
  const areAppointmentTreatmentsAvailable = appointmentTreatmentsAvailable.length > 0;
  const areRemoteCheckInTreatmentsAvailable = allOpenTreatmentsAvailableForRemoteCheckIn.length > 0;

  const parsedInstitutionPhoneNumber =
    parsePhoneNumberFromString(institutionPhoneNumber ?? "", "AT")?.formatInternational() ?? "";

  // Errors from CheckInStatus code after successful submission of the slot
  const location = useLocation<{
    noSlotAvailableError?: boolean;
    closedInstitutionError?: boolean;
    slotSubmissionError?: boolean;
  }>();
  // Unavailable slot error on slot submission
  const noSlotAvailableOnSubmission = location.state?.noSlotAvailableError;
  // Closed institution error on slot submission
  const closedInstitutionOnSlotSubmission = location.state?.closedInstitutionError;
  // Other submisson errors
  const submissionErrors = location.state?.slotSubmissionError;
  // Message for submission errors
  const onSlotSubmissonErrorText = noSlotAvailableOnSubmission
    ? t("errors.noSlotAvailableOnSubmission")
    : closedInstitutionOnSlotSubmission
    ? t("errors.closedInstitutionOnSubmission")
    : submissionErrors
    ? t("errors.submissionErrorWithPhone", { phone: parsedInstitutionPhoneNumber })
    : "";

  // Check for 400/404 error
  const treatmentsError = useSelector(getTreatmentsError);
  const isTreatments400_404Error =
    treatmentsError === TreatmentErrorCode.NOT_FOUND || treatmentsError === TreatmentErrorCode.BAD_REQUEST;

  // Error message display for wrong URL
  if (isTreatments400_404Error) {
    return <MissingOrgUnitIdentifier />;
  }

  // Error message with reload option for all errors but 400/404
  if (treatmentsError) {
    return <ErrorDialogWithReload dialogOpen={treatmentsError !== null} />;
  }

  return (
    <Grid
      container
      direction="column"
      // className={classes.componentContainer}
      mt={2}
      mb={4}
      sx={{
        flex: 1,
      }}
    >
      <Box>
        <Box py={2}>
          <Typography variant="h5" gutterBottom>
            {t("fork.title")}
          </Typography>
        </Box>
        <Box>
          {/**
           * Error message displayed on re-direction upon unsuccessful slot submission
           */}
          {(noSlotAvailableOnSubmission || closedInstitutionOnSlotSubmission || submissionErrors) && (
            <Typography color="error" variant="h6" gutterBottom>
              {onSlotSubmissonErrorText}
            </Typography>
          )}
          {/** Display message depending on whether reservation is possible or not */}
          {areAppointmentTreatmentsAvailable || areRemoteCheckInTreatmentsAvailable || treatmentsLastFetch === null ? (
            <Typography gutterBottom={true} color="textSecondary">
              <Trans i18nKey="fork.reservationPossible">
                Wollen Sie einen Termin <strong>(ab morgen)</strong> reservieren oder <strong>HEUTE</strong> kurzfristig
                gesehen werden?",
              </Trans>
            </Typography>
          ) : (
            <Typography color="textSecondary" gutterBottom>
              {t("fork.reservationNotPossible")}
            </Typography>
          )}
        </Box>
      </Box>
      <Card
        // className={classes.cardStyle}
        sx={{
          marginTop: 2,
        }}
      >
        <CardContent>
          {/** Loading spinner */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            // className={classes.content}
            sx={{
              minHeight: "250px",
            }}
          >
            {isFetchingTreatments ? (
              <CircularProgress />
            ) : (
              <>
                {/** Appointment booking link Grid */}
                <Grid
                  item
                  sm={6}
                  // className={clsx({
                  //   [classes.mobileBottomPadding]: hasMinWidth,
                  // })}
                  pb={hasMinWidth ? "70px" : undefined}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => {
                      dispatch(resetSwipeIndex());
                    }}
                    /** In case of no treatments, Link is replaced with div */
                    component={areAppointmentTreatmentsAvailable ? Link : "div"}
                    to={`/${orgUnitUUID}/book-appointment`}
                    // className={clsx({
                    //   [classes.linkContainer]: true,
                    //   [classes.linkDisabled]: !areAppointmentTreatmentsAvailable,
                    // })}
                    sx={{
                      color: "#000",
                      textDecoration: "none",
                      opacity: areAppointmentTreatmentsAvailable ? undefined : 0.4,
                      cursor: areAppointmentTreatmentsAvailable ? undefined : "default",
                    }}
                  >
                    <Grid
                      item
                      // className={classes.font}
                      sx={{
                        fontSize: "5rem",
                      }}
                    >
                      <Event fontSize="inherit" />
                    </Grid>
                    <Grid item>
                      {areAppointmentTreatmentsAvailable ? (
                        <Typography variant="button" align="center">
                          {t("fork.appointmentAvailable")}
                        </Typography>
                      ) : (
                        <Typography variant="button" align="center">
                          {t("fork.appointmentNotAvailable")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/** Slot reservation link Grid */}
                <Grid item sm={6}>
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    /** In case of no treatments, Link is replaced with div */
                    component={areRemoteCheckInTreatmentsAvailable ? Link : "div"}
                    to={areRemoteCheckInTreatmentsAvailable ? `/${orgUnitUUID}/reserve-slot` : "#"}
                    // className={clsx({
                    //   [classes.linkContainer]: true,
                    //   [classes.linkDisabled]: !areRemoteCheckInTreatmentsAvailable,
                    // })}
                    sx={{
                      color: "#000",
                      textDecoration: "none",
                      opacity: areRemoteCheckInTreatmentsAvailable ? undefined : 0.4,
                      cursor: areRemoteCheckInTreatmentsAvailable ? undefined : "default",
                    }}
                  >
                    <Grid
                      item
                      // className={classes.font}
                      sx={{
                        fontSize: "5rem",
                      }}
                    >
                      <ConfirmationNumber fontSize="inherit" />
                    </Grid>
                    <Grid item container direction="column" alignItems="center">
                      {/** Correct message display depending if there are available slots */}
                      {areRemoteCheckInTreatmentsAvailable ? (
                        <Typography variant="button" align="center">
                          {t("fork.slotAvailable")}
                        </Typography>
                      ) : (
                        <Typography variant="button" align="center">
                          {t("fork.slotUnavailable")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>

        <CardActions
          // className={classes.actionArea}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        ></CardActions>
      </Card>
    </Grid>
  );
};
