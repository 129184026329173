import { Grid, GridProps, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import React from "react";

interface OverlineLabelValueProps extends GridProps {
  /** Formik value on display */
  displayValue: string | undefined;
  /** Name of displayed value */
  label: string;
  /** Phone display value for icon render*/
  phoneValue?: boolean;
}

/**
 * Component in confirmation step for display of name & value from Formik state
 */
export const OverlineLabelValue = ({ label, displayValue, phoneValue, xs, ...gridProps }: OverlineLabelValueProps) => {
  return (
    <Grid item container direction="column" xs={xs}>
      <Grid item>
        <Typography variant="overline">{label}</Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Typography display="inline">{displayValue}</Typography>
        {phoneValue && <Check sx={{ color: "green", marginLeft: "4px" }} />}
      </Grid>
    </Grid>
  );
};
