import { Button, Grid, Box, Typography, Paper } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormikErrors, useFormikContext, FormikValues, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { FormValues } from "../../components/FormWizard";
import { EmployeeSelect } from "./EmployeeSelect";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { AvailableEmployee } from "../../modules/timeSlots/types";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useDispatch } from "react-redux";
import { setStartOfSelectionWeek, setSwipeIndex, setEndOfTimeframeWithoutSlots } from "../../modules/timeSlots/actions";
import moment from "moment";

// const useStyles = makeStyles((theme) => ({
//   paperStyle: {
//     padding: theme.spacing(2),
//   },
//   doctorSelectTitle: {
//     paddingTop: theme.spacing(2),
//   },
// }));
interface EmployeeSelectionStepProps {
  page: number | undefined;
  lastPage: number | undefined;
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Flag for mobile view styles */
  isDesktopDevice: boolean;
  /** Validation function */
  validationFn?: (
    values: { selectedEmployee: string },
    formikHelpers: FormikHelpers<FormValues>
  ) => FormikErrors<{ selectedEmployee: string }>;
}

/**
 * Component validation fn, passed as a prop to Formik in FromWizard component.
 * Currying for passing in i18n translation function
 */
export const validateEmployeeSelectionStep = (t: TFunction) => (values: FormikValues) => {
  // Validation error
  if (values.selectedEmployee === "") {
    return {
      selectedEmployee: t("validation.noEmployeeSelected"),
    };
  }
  // Validation passed
  return {};
};

/**
 * Selection of the doctor and arrival estimate time in slot reservation process.
 */
export const EmployeeSelectionStep = ({
  goToNextPage,
  goToPreviousPage,
  isDesktopDevice,
  page,
  lastPage,
}: EmployeeSelectionStepProps) => {
  const { setFieldValue, touched, errors, validateForm, values } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const classes = useStyles();

  const error = errors.selectedEmployee;

  // Getting available employees from selected treatment out of formik state
  const availableEmployees = values.treatment?.availableEmployees ?? [];

  /**
   * employee change handler storing value in the Formik without validation
   * @param event by default has value of "NONE"
   */
  const handleEmployeeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setStartOfSelectionWeek(moment().startOf("isoWeek").toISOString()));
    dispatch(setSwipeIndex(0));
    /**
     * reseting the value to inital state, important if previously selected employee had no slots available, enables automatic swiping throught time frame on re selection
     */
    dispatch(setEndOfTimeframeWithoutSlots(false));
    // If selected employee doesn't have already selected slot, slot is deleted from Formik
    const selectedEmployee = event.target.value as AvailableEmployee | string;

    // Array of employee combinations in case selected employee is included in employee combinations of selected slot proposal
    const matchingEmployeeCombinations = values.slotProposal?.originalEmployeeCombinations?.find(
      (employeeCombination) =>
        typeof selectedEmployee !== "string" && employeeCombination.includes(selectedEmployee.publicId)
    );

    // Re-selecting all employees
    if (selectedEmployee === "NONE") {
      setFieldValue(
        "slotProposal",
        {
          startTime: values.slotProposal?.startTime,
          employeeCombinations: null,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        false
      );
    }
    // Re-selected employee without previoulsy selected time slot, slot not included in slot's employee combination
    else if (selectedEmployee !== "NONE" && !matchingEmployeeCombinations && values.slotProposal?.startTime !== "") {
      setFieldValue(
        "slotProposal",
        {
          startTime: "",
          employeeCombinations: null,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        true
      );
    }
    // Selected employee included in slot's employee combination
    else if (matchingEmployeeCombinations) {
      setFieldValue(
        "slotProposal",
        {
          startTime: values.slotProposal?.startTime,
          employeeCombinations: matchingEmployeeCombinations,
          originalEmployeeCombinations: values.slotProposal?.originalEmployeeCombinations,
        },
        false
      );
    }

    setFieldValue("selectedEmployee", selectedEmployee);

    if (goToNextPage) {
      goToNextPage();
    }
  };

  // indication employee selection step should be skipped, only one available employee
  const skipIt =
    !values.treatment?.employeeSelectable || (availableEmployees !== null && availableEmployees.length < 1);

  // on mounting of step checks for preslection of employee and possible skipping of step
  useEffect(() => {
    if (skipIt && goToNextPage) {
      goToNextPage();
    }
    // on inital load scrolls to top in case user has scrolled down the list of treatments on prev step
    window.scrollTo(0, 0);
    //if only one employee available, user moved to next or prev.step
    if (skipIt && goToNextPage && page !== undefined && lastPage !== undefined && page > lastPage) {
      goToNextPage();
    }
    if (skipIt && goToPreviousPage && page !== undefined && lastPage !== undefined && page < lastPage) {
      goToPreviousPage();
    }
    // if only one employee available, it's preselected in formik state by default
    if (availableEmployees?.length === 1) {
      setFieldValue("selectedEmployee", availableEmployees[0], false);
    }
    // checking if conditions are met for skipping the step (there is only one employee that is selected by default)
    if (page === undefined || lastPage === undefined || values?.treatment?.employeeSelectable) {
      return;
    }
    // user is coming from the previous page as employee selection page values is greater than previous page value
    if (goToNextPage && page > lastPage) {
      goToNextPage();
    }
    // user is coming from the page after employee selection as employee selection page value is less than previous page value
    if (goToPreviousPage && lastPage > page) {
      goToPreviousPage();
    }
  }, []);

  const stepInfoText = t("appointment.employeeSelectTitle");

  return (
    <>
      <Box pt={0}>
        <TitleNavigationHeader
          goToPreviousPage={goToPreviousPage}
          isSlotReservation={true}
          stepInfoText={stepInfoText}
          error={error}
        />
        <Box pb={2} pt={2}>
          <Typography display="inline">{` ${t("patientData.treatment")}: `}</Typography>
          <Typography color="primary" display="inline">{` ${values.treatment?.name}`}</Typography>
        </Box>
      </Box>

      {/** Display of validation/Formik error */}
      <Typography variant="caption" color="error" align="center" pb={2}>
        {error ? error : " "}
      </Typography>

      <Box p={2} component={Paper}>
        <Box pt={2}>
          <Typography variant="h6">{t("doctorSelect.title")}</Typography>
        </Box>
        <Box>
          <EmployeeSelect
            selectedEmployee={values.selectedEmployee}
            availableEmployees={availableEmployees}
            handleEmployeeChange={handleEmployeeChange}
            isMobile={!isDesktopDevice}
          />
        </Box>
        <Grid item container justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary" disabled={error !== undefined}>
            OK
          </Button>
        </Grid>
      </Box>
    </>
  );
};
