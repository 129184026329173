import React from "react";
import { Grid, Button, Alert, AlertTitle, Box, Typography } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//   button: {
//     marginTop: theme.spacing(2),
//     width: "4em",
//   },
//   alert: {
//     padding: 20,
//   },
//   endIcon: { margin: "0px", alignSelf: "center", color: "#fff", fontSize: "1.5em" },
//   root: { alignSelf: "center" },
// }));

/**
 * Display error if there was an error fetching treatments
 */
export const TreatmentsError = () => {
  const { t } = useTranslation();
  // const classes = useStyles();

  return (
    <Box sx={{ height: "100%" }}>
      <Alert
        severity="error"
        sx={{
          padding: 1,
        }}
      >
        <AlertTitle>{t("errors.treatmentsErrorTitle")}</AlertTitle>
        <Typography pt={2}>{t("errors.treatmentsError")}</Typography>
        <Button
          onClick={() => window.location.reload()}
          variant="contained"
          color="primary"
          // className={classes.button}
          sx={{
            marginTop: 5,
            width: "4em",
            "& .MuiButton-root": {
              alignSelf: "center",
            },
            "& .MuiButton-endIcon": {
              margin: "0px",
              alignSelf: "center",
              color: "#fff",
              fontSize: "1.5em",
            },
          }}
          // classes={{ endIcon: classes.endIcon, root: classes.root }}
          endIcon={<RefreshIcon />}
        />
      </Alert>
    </Box>
  );
};
