import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./actions";
import { AvailableAppointmentTimesState } from "./types";
import moment from "moment";

export type AvailableAppointmentTimesActions = ActionType<typeof actions>;

const initialState: AvailableAppointmentTimesState = {
  // Data with range and slot proposal info
  availableAppointmentTimesInfo: {
    fromDate: "",
    slotProposals: null,
    toDate: "",
    treatmentTypePublicId: "",
    dayAndTimeFilters: null,
  },
  // Data for correct range selection
  selectedTimeDate: "",
  nextWeekClick: true,
  startOfSelectionWeek:
    moment().isoWeekday() === 7 ? moment().add(1, "day").toISOString() : moment().startOf("isoWeek").toISOString(),
  swipeIndex: 0,
  errorMessage: null,
  isFetching: false,
  mobileViewWeeks: 0,
  endOfTimeframeWithoutSlots: false,
};

/**
 * Slice of state about range span and slot proposals info for each day of range.
 */
export const availableAppointmentTimesInfo = (
  state = initialState.availableAppointmentTimesInfo,
  action: AvailableAppointmentTimesActions
) => {
  switch (action.type) {
    case getType(actions.fetchAvailableAppointmentTimes.success):
      if (state === null) {
        return action.payload;
      }
      return {
        ...state,
        treatmentTypePublicId: action.payload.treatmentTypePublicId,
        fromDate: state.fromDate,
        toDate: action.payload.toDate,
        slotProposals: {
          ...state.slotProposals,
          ...action.payload.slotProposals,
        },
      };
    default:
      return state;
  }
};

// export const availableAppointmentTimesInfo = createReducer(initialState.availableAppointmentTimesInfo)
//   .handleAction(actions.fetchAvailableAppointmentTimes.success, (_state, action) => action.payload)
//   .handleAction(actions.clearSlots, (_state) => {
//     if (_state === null) {
//       return null;
//     } else {
//       return { ..._state, slotProposals: null };
//     }
//   });

/**
 * Upadate about currently selected date/time value.
 * Might not have any use.
 */
export const selectedTimeDate = (state = initialState.selectedTimeDate, action: AvailableAppointmentTimesActions) => {
  switch (action.type) {
    case getType(actions.setWeekNumberOfSelection):
      return action.payload;
    default:
      return state;
  }
};

// export const selectedTimeDate = createReducer(initialState.selectedTimeDate).handleAction(
//   actions.setWeekNumberOfSelection,
//   (_state, action) => action.payload
// );

/**
 * Update about beginning date of currently displayed ISO week, so that it can be used to create new next/prev time range for data fetch.
 */
export const startOfSelectionWeek = (
  state = initialState.startOfSelectionWeek,
  action: AvailableAppointmentTimesActions
) => {
  switch (action.type) {
    case getType(actions.setStartOfSelectionWeek):
      return action.payload;
    default:
      return state;
  }
};

// export const startOfSelectionWeek = createReducer(initialState.startOfSelectionWeek)
//   .handleAction(actions.addWeek, (_state, action) => action.payload)
//   .handleAction(actions.subtractWeek, (_state, action) => action.payload)
//   .handleAction(actions.setWeek, (_state, action) => action.payload);

/**
 * Update on prev/next week in range click that determines behavior of data fetch in saga in case current range has no data
 */
export const nextWeekClick = (state = initialState.nextWeekClick, action: AvailableAppointmentTimesActions) => {
  switch (action.type) {
    case getType(actions.nextWeekClick):
      return action.payload;
    default:
      return state;
  }
};

// export const nextWeekClick = createReducer(initialState.nextWeekClick).handleAction(
//   actions.nextWeekClick,
//   (_state, action) => action.payload
// );

/**
 * Changed with next/prevWeekHandlers in DateTimePicker.
 */
export const swipeIndex = (state = initialState.swipeIndex, action: AvailableAppointmentTimesActions) => {
  switch (action.type) {
    case getType(actions.addSwipeIndex):
      return state + 1;
    case getType(actions.subtractSwipeIndex):
      if (state > 0) return state - 1;
      return state;
    case getType(actions.setSwipeIndex):
      return action.payload;
    case getType(actions.resetSwipeIndex):
      return 0;
    default:
      return state;
  }
};

// export const swipeIndex = createReducer(initialState.swipeIndex)
//   .handleAction(actions.addSwipeIndex, (_state, actions) => _state + 1)
//   .handleAction(actions.subtractSwipeIndex, (_state, actions) => {
//     if (_state > 0) {
//       return _state - 1;
//     } else return _state;
//   });

// export const mobileViewWeeks = createReducer(initialState.mobileViewWeeks).handleAction(
//   actions.addMobileViewWeek,
//   (_state, actions) => _state + 1
// );

/**
 * Changed with onShowNextWeekHandler in DateTimePickerMobile.
 */
export const mobileViewWeeks = (state = initialState.mobileViewWeeks, action: AvailableAppointmentTimesActions) => {
  switch (action.type) {
    case getType(actions.addMobileViewWeek):
      return state + 1;
    default:
      return state;
  }
};

/**
 * Error on appointment proposals fetch
 */
export const errorMessage = (state = initialState.errorMessage, action: AvailableAppointmentTimesActions) => {
  switch (action.type) {
    case getType(actions.fetchAvailableAppointmentTimes.failure):
      return action.payload;
    case getType(actions.fetchAvailableAppointmentTimes.request):
    case getType(actions.fetchAvailableAppointmentTimes.success):
      return null;
    default:
      return state;
  }
};

// export const errorMessage = createReducer(initialState.errorMessage)
//   .handleAction(actions.fetchAvailableAppointmentTimes.failure, (_state, actions) => actions.payload)
//   .handleAction(
//     [actions.fetchAvailableAppointmentTimes.request, actions.fetchAvailableAppointmentTimes.success],
//     () => null
//   );

/**
 * Indicator or appointment proposals fetch
 */
export const isFetching = (state = initialState.isFetching, action: AvailableAppointmentTimesActions) => {
  switch (action.type) {
    case getType(actions.fetchAvailableAppointmentTimes.request):
      return true;
    case getType(actions.fetchAvailableAppointmentTimes.success):
    case getType(actions.fetchAvailableAppointmentTimes.failure):
      return false;
    default:
      return state;
  }
};

/**
 * Indicator for reaching end of the time frame without slots, important flag for correct navigation inside of timeframe one the end has been automatically reached
 */

export const endOfTimeframeWithoutSlots = (
  state = initialState.endOfTimeframeWithoutSlots,
  action: AvailableAppointmentTimesActions
) => {
  switch (action.type) {
    case getType(actions.setEndOfTimeframeWithoutSlots):
      return action.payload;
    default:
      return state;
  }
};

// export const isFetching = createReducer(initialState.isFetching)
//   .handleAction(actions.fetchAvailableAppointmentTimes.request, () => true)
//   .handleAction(
//     [actions.fetchAvailableAppointmentTimes.success, actions.fetchAvailableAppointmentTimes.failure],
//     () => false
//   );

export {
  errorMessage as institutionErrorMessageReducer,
  isFetching as institutionIsFetchAvailableAppointmentTimesingReducer,
};

export default combineReducers<AvailableAppointmentTimesState, AvailableAppointmentTimesActions>({
  availableAppointmentTimesInfo,
  selectedTimeDate,
  startOfSelectionWeek,
  nextWeekClick,
  swipeIndex,
  errorMessage,
  isFetching,
  mobileViewWeeks,
  endOfTimeframeWithoutSlots,
});
