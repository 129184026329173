import React from "react";
import { TextField } from "@mui/material";
import { FieldProps, getIn, ErrorMessage } from "formik";
// import { FormValues } from "./FormWizard";

//@ts-ignore
import { IMaskInput } from "react-imask";
interface IProps extends FieldProps {
  fieldInputRef: React.MutableRefObject<HTMLInputElement | null>;
  maskSpecificProperties: { mask: string; sxFontSize?: string; sxWidth?: string; autofocus: boolean };
}

// IMaskInput passed to input element via InputProps
const MaskedInputCustom = React.forwardRef(
  (
    props: {
      fieldname: string;
      mask: string;
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    },
    ref
  ) => {
    const { fieldname, mask, setFieldValue, ...restProps } = props;

    return (
      <IMaskInput
        {...restProps}
        mask={mask}
        unmask="typed"
        onAccept={(value: string) => setFieldValue(fieldname, value)}
        inputRef={ref}
      />
    );
  }
);

// component passed to formik Field
export const MaskedFormikTextField = ({ field, form: { errors, touched, setFieldValue }, ...props }: IProps) => {
  const fieldErrors = getIn(errors, field.name);
  const fieldTouched = getIn(touched, field.name);
  const fieldName = field.name;
  const { fieldInputRef, maskSpecificProperties, ...restProps } = props;

  return (
    <TextField
      {...field}
      {...restProps}
      autoFocus={maskSpecificProperties.autofocus}
      // accepts random props passing them to input element
      inputProps={{
        mask: maskSpecificProperties.mask,
        fieldname: fieldName,
        setFieldValue,
      }}
      InputProps={{
        inputRef: fieldInputRef,
        autoComplete: "off",
        inputComponent: MaskedInputCustom as any,
        sx: { fontSize: maskSpecificProperties.sxFontSize },
      }}
      multiline={field.name === "clientAccountDetails.comment"}
      error={fieldTouched && fieldErrors !== undefined}
      sx={{
        width: maskSpecificProperties.sxWidth,
      }}
      helperText={<ErrorMessage name={field.name} />}
    />
  );
};
