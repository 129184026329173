import { InstitutionDetailsAndTreatments } from "./types";
import { createAsyncAction } from "typesafe-actions";

/**
 * Dispatched on load of SlotAppointmentFork and TreatmentSelect with useFetchInstitutionDetailsAndAllTreatments hook.
 * Fetches all treatments for specific institution, that, based on their properties are available for appointment booking and/or slot reservation.
 * Uses orgUnitUUID from URL params in payload to fetch all treatments for institution.
 */

export const fetchInstitutionDetailsAndTreatments = createAsyncAction(
  "INSTITUTIONDETAILSANDTREATMENTS_FETCH_REQUEST",
  "INSTITUTIONDETAILSANDTREATMENTS_FETCH_SUCCESS",
  "INSTITUTIONDETAILSANDTREATMENTS_FETCH_FAILURE"
)<string, InstitutionDetailsAndTreatments, string>();
