import { createAsyncAction, createAction } from "typesafe-actions";
import {
  WaitingQueueSlot,
  WaitingQueueSlotFetchOptions,
  WaitingQueueReserveSlotOptions,
  WaitingQueueReserveSlotResponse,
} from "./types";
import { History } from "history";

/**
 * Dispatched from step 2 of handleSubmission in SlotReservation.
 * Fetches next available waiting queue slot given earliestArrivalTime and selected treatmetn.
 */
export const fetchQueueSlot = createAsyncAction(
  "QUEUESLOT_FETCH_REQUEST",
  "QUEUESLOT_FETCH_SUCCESS",
  "QUEUESLOT_FETCH_FAILURE"
)<
  [
    WaitingQueueSlotFetchOptions,
    {
      history: History;
      pathName: string;
    }
  ],
  WaitingQueueSlot,
  string
>();

/**
 * Dispatched from last step of booking process of handleSubmission in SlotReservation.
 * Reserves queue slot with all form data and receives checkInStatusCode on successful submission used for confirmation process.
 * Meta object provides saga with data otherwise unavailable inside of it.
 */
export const reserveSlot = createAsyncAction(
  "QUEUESLOT_RESERVE_REQUEST",
  "QUEUESLOT_RESERVE_SUCCESS",
  "QUEUESLOT_RESERVE_FAILURE"
)<
  [
    WaitingQueueReserveSlotOptions,
    {
      history: History;
      waitTimeConfirmedDate: number | null;
      setSubmitting: (isSubmitting: boolean) => void;
      pathName: string;
    }
  ],
  WaitingQueueReserveSlotResponse,
  string
>();

/**
 * Dispatched from SlotReservationFinished component.
 * Starts process of 'listening' for user confirmation of successfully submitted slot requst on mobile device.
 */
export const startCheckingSlotValidity = createAction("QUEUESLOT_CHECKVALIDITY_START")<string>();
/**
 *
 */
export const stopCheckingSlotValidity = createAction("QUEUESLOT_CECKVALIDITY_STOP")<undefined>();
/**
 * Dispatched from checkSlotValidity saga, on slot confirmation
 */
export const slotValidityIsValid = createAction("QUEUESLOT_VALIDITY_ISVALID")<undefined>();
