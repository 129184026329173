import { createStore, applyMiddleware } from "redux";
import rootReducer from "./root-reducer";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootSaga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();

const initialState = {};

const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
