import { createAction, createAsyncAction } from "typesafe-actions";
import { PhoneVerificationRequest, PhoneVerificationSubmissionRequest } from "./types";
import { History } from "history";

export const requestPhoneVerificationCode = createAsyncAction(
  "PHONE_VERIFICATION_REQUEST_CODE_REQUEST",
  "PHONE_VERIFICATION_REQUEST_CODE_SUCCESS",
  "PHONE_VERIFICATION_REQUEST_CODE_FAILURE"
)<
  [
    PhoneVerificationRequest,
    {
      setSubmitting: (isSubmitting: boolean) => void;
    }
  ],
  string,
  string
>();

export const resetVerificationCodeUUID = createAction("RESET_VERIFICATION_CODE_UUID")();

export const submitVerificationCode = createAsyncAction(
  "PHONE_VERIFICATION_SUBMIT_CODE_REQUEST",
  "PHONE_VERIFICATION_SUBMIT_CODE_SUCCESS",
  "PHONE_VERIFICATION_SUBMIT_CODE_FAILURE"
)<
  [
    PhoneVerificationSubmissionRequest,
    {
      goToPreviousPage?: () => void;
      goToNextPage?: () => void;
      setSubmitting: (isSubmitting: boolean) => void;
      history: History;
      pathName: string;
      isSlotReservation: boolean;
    }
  ],
  string,
  string
>();
