import { AxiosInstance } from "axios";
import { baseURL } from "../services/api";
import { AppointmentRequest } from "./types";
import { WaitingQueueSlotFetchOptions, WaitingQueueReserveSlotOptions } from "../waitingQueueSlots/types";

export const appointmentAPI = (api: AxiosInstance) => ({
  appointmentDetails: (appointmentRequest: AppointmentRequest) =>
    api.post(`${baseURL}/appointment/with-new-client`, appointmentRequest),
});

export const appointmentSlotAPI = (api: AxiosInstance) => ({
  checkStatus: (publicId: string) => api.get(`/is-appointment-valid/${publicId}`),
  fetch: ({ treatmentPublicId, minutesToEarliestArrivalTime }: WaitingQueueSlotFetchOptions) =>
    api.get(`/treatment-types/${treatmentPublicId}/next-waiting-queue-slot`, {
      params: { minutesToEarliestArrivalTime },
    }),
  reserveSlot: ({ formData: { treatment, ...rest }, minutesToEarliestArrivalTime }: WaitingQueueReserveSlotOptions) =>
    api.post(`/treatment-types/${treatment?.publicId}/remote-join-waiting-queue`, rest, {
      params: { minutesToEarliestArrivalTime },
    }),
});
