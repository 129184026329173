/**
 * Server description of phone verification after submission
 */
export interface PhoneVerificationResponse {
  verificationCodeUUID: string;
}

/**
 * Object that is sent to the server for phone verification
 */
export interface PhoneVerificationRequest {
  phoneNumber: string;
  treatmentType: string | undefined;
}

export interface PhoneVerificationSubmissionRequest {
  otp: string;
  uuid: string;
}

export interface PhoneVerificationState {
  verificationCodeUUID: string | null;
  codeConfirmation: string | null;
  isFetching: {
    codeRequest: boolean;
    codeSubmission: boolean;
  };
  errorMessage: {
    codeRequestError: string | null;
    codeSubmissionError: string | null;
  };
}

export enum ConfirmationCode {
  CONFIRMED = "CONFIRMED",
  UN_CONFIRMED = "UN_CONFIRMED",
  BLOCKED = "BLOCKED",
}
