import { Typography, Skeleton, Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Treatment } from "../../modules/treatments/types";
import { TreatmentListItem } from "./TreatmentListItem";
import { FormikErrors, getIn, FormikValues, useField, useFormikContext, validateYupSchema } from "formik";
import { FormValues } from "../FormWizard";
import { setWeekNumberOfSelection, resetSwipeIndex, setStartOfSelectionWeek } from "../../modules/timeSlots/actions";
import { RootState } from "../../store/root-reducer";
import { getTreatmentSelectData } from "../../modules/treatments/selectors";
import { useFetchInstitutionDetailsAndAllTreatments } from "../../modules/timeSlots/utils";
import { TreatmentsError } from "./TreatmentError";
import { TitleNavigationHeader } from "../../components/TitleNavigationHeader";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment from "moment";

// const useStyle = makeStyles((theme) => ({
//   skeletonWrap: { width: "100%", marginBottom: 20 },
// }));

interface TreatmentSelectProps {
  /** UUID of the orgUnit for the treatments that should be displayed */
  orgUnitUUID: string | undefined;
  /** Selector function to get bookable treatments */
  getAllTreatments: (state: RootState) => Treatment[];
  /** Handler for going to next page */
  goToNextPage?: () => void;
  /** Handler for going to previous page */
  goToPreviousPage?: () => void;
  /** Function to check if a treatment should be disabled for booking */
  isTreatmentDisabled?: (treatment: Treatment) => boolean;
  /** Validation function to be used by Formik. Never gets used inside of the component */
  validationFn?: (values: FormikValues) => FormikErrors<Pick<FormValues, "treatment">>;
  /** Indicates whether we're reserving a slot as opposed to booking an appointmen */
  isSlotReservation?: boolean;
}

/**
 * Component validation fn, passed as a prop to Formik in FromWizard component.
 * Currying for passing in i18n translation function
 */
export const validateTreatmentSelection = (t: TFunction) => (values: FormikValues) => {
  // Validation error
  if (values.treatment === null) {
    return {
      treatment: t("validation.treatment"),
    };
  }
  // Validation passed
  return {};
};

/**
 * Shows list of available treatments
 */
export const TreatmentSelect = ({
  getAllTreatments,
  goToNextPage,
  isTreatmentDisabled,
  isSlotReservation,
  orgUnitUUID,
}: TreatmentSelectProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const classes = useStyle();

  const [field, meta, helpers] = useField({ name: "treatment" });
  const { setFieldValue, validateForm, errors, values } = useFormikContext<FormValues>();

  const { treatmentsError, treatmentsIsFetching } = useSelector(getTreatmentSelectData);

  // Use passed in selector to get available treatments
  const treatments = useSelector(getAllTreatments);

  // Formik error
  const error = getIn(errors, field.name);
  const skeletonBackbone = [1, 2, 3, 4, 5, 6, 7];

  // useFetchAllTreatments(orgUnitUUID);
  useFetchInstitutionDetailsAndAllTreatments(orgUnitUUID);

  const treatmentSelectHandler = (treatment: Treatment) => {
    // Resetting data for slot proposal if publicId is not matching - happens when selected treatment changes
    if (field.value?.publicId !== treatment.publicId) {
      setFieldValue("slotProposal", { startTime: "", employeeCombinations: null }, false);
    }
    dispatch(resetSwipeIndex());
    dispatch(setStartOfSelectionWeek(moment().startOf("isoWeek").toISOString()));
    // On treatment selection, value set in Formik.
    helpers.setValue(treatment);
    setFieldValue("selectedEmployee", "", false);
    // Setting data to initial state value, as it can have data from previous steps.
    if (values.slotProposal !== null) {
      dispatch(setWeekNumberOfSelection(""));
    }
    if (goToNextPage) {
      goToNextPage();
    }
  };

  const stepInfoText = t("appointment.treatmentSelectTitle");

  const nextStepHandler = () => {
    validateForm();
  };

  if (treatmentsError) {
    return <TreatmentsError />;
  }

  return (
    <>
      <Box pt={0} pb={2}>
        {/** Header with navigation elements */}
        <TitleNavigationHeader
          isTreatmentSelect={true}
          orgUnitUUID={orgUnitUUID}
          stepInfoText={stepInfoText}
          isSlotReservation={isSlotReservation}
          nextStepHandler={nextStepHandler}
          error={error}
        />
      </Box>
      {/** Display of validation/Formik error */}
      <Typography variant="caption" color="error" align="center">
        {error ? error : " "}
      </Typography>
      <Box>
        <>
          {/** Skeletons displayed during data fetching */}
          {treatmentsIsFetching &&
            skeletonBackbone.map((_skelet, index) => {
              return (
                <Box key={index} mb={"20px"} sx={{ width: "100%" }}>
                  <Skeleton height={60} width="100%" variant="rectangular" />
                </Box>
              );
            })}
        </>
        {/** Rendering list of fetched treatments */}
        {treatments.map((treatment, index) => (
          <TreatmentListItem
            isDisabled={isTreatmentDisabled ? isTreatmentDisabled(treatment) : false}
            treatment={treatment}
            key={index}
            selectTreatment={treatmentSelectHandler}
            selected={field.value !== null && field.value.publicId === treatment.publicId}
          />
        ))}
      </Box>
    </>
  );
};
